import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Typography, TextField, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import useVerifyToken from '../../hooks/useVerifyToken'
import PrimarySearchAppBar from '../Layouts/PrimarySearchAppBar'
import OwnerCard from './ChildComponent/OwnerCard'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import useSubmitBillboardSurvey from './hooks/useSubmitBillboardSurvey'
import SurveyAlert from './Layouts/SurveyAlert'
import BackDropLoading from '../Layouts/BackDropLoading'
import useBackDropLoadingToggle from '../../hooks/useBackDropLoadingToggle'
import useAlert from './hooks/useAlert'
import BillboardLocationCard from './ChildComponent/BillboardLocationCard'
import BillboardDetailsCard from './ChildComponent/BillboardDetailsCard'
import {
    FormOutlined
} from "@ant-design/icons";

const cardStyle = {
    m: '35px 0px 0px 35px',
    width: '90%',
    justifyContent: 'center'
}
const cardHeaderStyle = {
    bgcolor: 'info.main',
    background: '#f2f3f3',
    color: '#025464',
    p: 1,
}
const cardBodyStyle = {
    background: '#fafafa69',
}
const theme = createTheme({
    typography: {
        fontFamily: [
            'IBM Plex Sans Thai',
            'sans-serif',
        ].join(','),
    },
});
export default function BillboardSurvey() {

    //Card Header
    const ownerCardHeader = 'ข้อมูลเจ้าของป้าย'
    const locationCardHeader = 'ข้อมูลสถานที่ติดตั้งป้าย'
    const detailsCardHeader = 'ข้อมูลรายละเอียดข้อมูลป้าย'

    const { verifyToken } = useVerifyToken()
    const [insertId, setInsertId] = useState(0)
    const [owner, setOwner] = useState({
        ltaxOwnerId: '',
        prefix: '',
        personType: '',
        firstName: '',
        lastName: '',
        idCardNo: '',
        address: '',
        moo: '',
        soi: '',
        road: '',
        tumbolId: '',
        tumbolName: '',
        district: '',
        province: '',
        provinceCode: '',
        telephoneNo: ''
    })
    const [errorOwner, setErrorOwner] = useState({
        ltaxOwnerId: false,
        prefix: false,
        personType: false,
        firstName: false,
        lastName: false,
        idCardNo: false,
        address: false,
        moo: false,
        soi: false,
        road: false,
        tumbolId: false,
        tumbolName: false,
        district: false,
        province: false,
        provinceCode: false,
        telephoneNo: false
    })
    const [billboardLocation, setBillboardLocation] = useState({
        parcelCode: '',
        businessName: '',
        buildingAdress: '',
        moo: 0,
        soi: '',
        road: '',
        tumbolName: '',
        tumbolId: ''
    })
    const [errorBillboardLocation, setErrorBillboardLocation] = useState({
        parcelCode: false,
        businessName: false,
        buildingAdress: false,
        moo: false,
        soi: false,
        road: false,
        tumbolName: false,
        tumbolId: false
    })
    const [billboardDetails, setBillboardDetails] = useState([{
        group: 0,
        type: 0,
        height: 0,
        width: 0,
        side: 1,
        text: '',
        installationDate: null,
        note: ''
    }])
    const [errorBillboardDetails, setErrorBillboardDetails] = useState([{
        group: false,
        type: false,
        height: false,
        width: false,
        side: false,
        text: false,
        installationDate: false,
        note: false,
        images: {
            image: '',
            imageURL: ''
        }
    }])
    //Alert
    const [errorText, setErrorText] = useState('')
    const [successAlertOpen, setSuccessAlertOpen] = useState(false)
    const [errorAlertOpen, setErrorAlertOpen] = useState(false)
    //Loading
    const [backDropLoading, setBackDropLoading] = useState(false)

    // Custom Hooks
    const { billBoardSurveySubmit } = useSubmitBillboardSurvey()
    const { handleBackDropLoadingToggle } = useBackDropLoadingToggle()
    const { handleErrorAlert } = useAlert()



    useEffect(() => {
        verifyToken()
    }, [])
    // useEffect(() => {
    //     console.log(errorBillboardDetails)
    // }, [errorBillboardDetails])
    // useEffect(() => {
    //     console.log(billboardDetails)
    // }, [billboardDetails])
    // useEffect(() => {
    //     console.log(owner)
    // }, [owner])
    // useEffect(() => {
    //     console.log(billboardLocation)
    // }, [billboardLocation])
    useEffect(() => {
        console.log(billboardDetails)
    }, [billboardDetails])


    return (
        <ThemeProvider theme={theme} >
            <Box className='otherSide scrollbar-container' id="otherSide">
                <Box className='Title-map' >
                    <Typography variant="h5" ><FormOutlined /> ข้อมูลการสำรวจป้าย</Typography>
                </Box>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1 },
                        '& .MuiButton-root': { m: 1 }
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={(event) => { billBoardSurveySubmit(event, owner, billboardLocation, billboardDetails, setInsertId, setSuccessAlertOpen, handleErrorAlert, setErrorText, setErrorAlertOpen, setBackDropLoading) }}
                >
                    <OwnerCard
                        ownerCardHeader={ownerCardHeader}
                        error={errorBillboardDetails}
                        owner={owner}
                        setOwner={setOwner}
                        errorOwner={errorOwner}
                        setErrorOwner={setErrorOwner}
                        cardStyle={cardStyle}
                        cardBodyStyle={cardBodyStyle}
                        cardHeaderStyle={cardHeaderStyle}
                    />
                    <BillboardLocationCard
                        billboardLocation={billboardLocation}
                        setBillboardLocation={setBillboardLocation}
                        errorBillboardLocation={errorBillboardLocation}
                        setErrorBillboardLocation={setErrorBillboardLocation}
                        cardStyle={cardStyle}
                        cardHeaderStyle={cardHeaderStyle}
                        cardBodyStyle={cardBodyStyle}
                        locationCardHeader={locationCardHeader}
                    />
                    <BillboardDetailsCard
                        billboardDetails={billboardDetails}
                        setBillboardDetails={setBillboardDetails}
                        errorBillboardDetails={errorBillboardDetails}
                        setErrorBillboardDetails={setErrorBillboardDetails}
                        cardStyle={cardStyle}
                        cardHeaderStyle={cardHeaderStyle}
                        cardBodyStyle={cardBodyStyle}
                        detailsCardHeader={detailsCardHeader}
                    />
                    <Box style={{
                        marginTop: '20px',
                        marginLeft: '10px'
                    }}>
                        <Button
                            variant='contained'
                            startIcon={<SaveIcon />}
                            type='submit'
                        >
                            บันทึกข้อมูล
                        </Button>
                    </Box>
                </Box>
                <SurveyAlert
                    errorText={errorText}
                    successAlertOpen={successAlertOpen}
                    setSuccessAlertOpen={setSuccessAlertOpen}
                    errorAlertOpen={errorAlertOpen}
                    setErrorAlertOpen={setErrorAlertOpen}
                    insertId={insertId}
                    handleBackDropLoadingToggle={handleBackDropLoadingToggle}
                    setBackDropLoading={setBackDropLoading}
                    surveyType={2}
                />
                <BackDropLoading backDropLoading={backDropLoading} />
            </Box>
        </ThemeProvider >
    )
}
