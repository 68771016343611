import fi from 'date-fns/esm/locale/fi/index.js';
import axiosSmartSurvey from '../../../api/axios'

export default function useFetchFileData() {
  async function fetchImage(editData, files, setFiles) {
    await axiosSmartSurvey({
      method: 'post',
      url: `/taxmapfolders/downloadimage`,
      data: {
        path: editData.path
      },
      responseType: 'blob'
    }).then(async response => {
      const image = {
        image: null,
        imageUrl: URL.createObjectURL(response.data)
      }
      // console.log(editData)
      setFiles({
        ...files,
        parcelCode: editData.parcel_code,
        type: editData.type,
        note: editData.note,
        image: image
      })

    }).catch(error => {
      console.error(error);
    })
  }
  const fetchFileData = async (files, setFiles, fileId) => {
    if (true) {
      var response = await axiosSmartSurvey({
        method: 'post',
        url: `/taxmapfolders/editdata`,
        data: {
          id: fileId,
        }
      })
      response = response.data
      // console.log("res", response)
      if (response.status === 'OK') {
        const editData = response.data

        // console.log("res", editData)
        fetchImage(editData, files, setFiles)

      } else {

      }
    }
  }

  const fetchViewData = async (parcelCode, setViewData, setIsModalOpen, setDeleteDialogOpen, handleSearchFormSubmit) => {
    await axiosSmartSurvey({
      method: 'post',
      url: '/taxmapfolders/viewdata',
      data: {
        parcelCode: parcelCode
      }
    }).then((response) => {
      response = response.data.data
      // console.log(response)
      if (response?.length > 0) {
        response.forEach((data, index) => {
          fetchViewDataImage(data)
        });
      } else {
        // setIsModalOpen(false)
        handleSearchFormSubmit(parcelCode)
        setDeleteDialogOpen(false)
      }
    }).catch((err) => {
      console.log(err)
    })
    async function fetchViewDataImage(data) {
      // console.log(data)
      setViewData([])
      await axiosSmartSurvey({
        method: 'post',
        url: `/taxmapfolders/downloadimage`,
        data: {
          path: data.path
        },
        responseType: 'blob'
      }).then(async response => {
        const image = {
          image: null,
          imageUrl: URL.createObjectURL(response.data)
        }
        setViewData(prev => [...prev, {
          parcelCode: data.parcel_code,
          type: data.type,
          image: image,
          note: data.note,
          id: data.id
        }])
      }).catch(error => {
        console.error(error);
      })
    }
  }

  const fetchFileTypeData = async (setFileTypeData) => {
    await axiosSmartSurvey({
      method: 'post',
      url: `/taxmapfolders/filetypedata`,
    }).then(response => {
      response = response.data.fileTypeData
      setFileTypeData(response)
      // console.log(response)
    }).catch((err) => {
      console.log(err)
    })
  }

  const fetchAddfiletypedata = async (fileType, setFileTypeData, fetchFileTypeData, handleErrorAlert, setErrorText, setErrorAlertOpen) => {
    await axiosSmartSurvey({
      method: 'post',
      url: '/taxmapfolders/addfiletypedata',
      data: {
        fileType: fileType
      },

    }).then(response => {
      if (response.data.status === 'OK') {
        fetchFileTypeData(setFileTypeData)
        // console.log(response)
      } else{
        // Alert Error
        handleErrorAlert(response.msg, setErrorText, setErrorAlertOpen)
        
      }
    }).catch((err) => {
      handleErrorAlert('การเชื่อมต่อล้มเหลว กรุณาลองใหม่อีกครั้ง หรือติดต่อผู้แลระบบ', setErrorText, setErrorAlertOpen)
        console.log(err)
      })


  }


  return { fetchFileData, fetchImage, fetchViewData, fetchFileTypeData, fetchAddfiletypedata }
}
