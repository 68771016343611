import axiosSmartSurvey from "../../../api/axios"

export default function usefetchLtaxData() {
    const fetchLtaxData = async (param, value, parcelCode, land, setLand, setLandUsed, setOwner, setAllBuilding, prefixList, handleProvinceSelect, handleDistrictSelect, setBackDropLoading) => {
        const fetchLtaxBuildingData = async (landId) => {
            setBackDropLoading(true)
            var response = await axiosSmartSurvey({
                method: 'post',
                url: `/api/surveypredata`,
                data: { landId: landId }
            })
            var arr = []
            var arr2 = []
            var floorAmount = 0
            // console.log(response.data.data[0])
            response?.data.data.forEach((value, index) => {
                if (value.no_floor < 1) {
                    floorAmount = 1
                } else {
                    floorAmount = value.no_floor
                }
                for (let i = 0; i < floorAmount; i++) {
                    arr2 = [...arr2, {
                        floor: i + 1,
                        width: value.width,
                        length: value.length,
                        whoUsed: 1,
                        buildingUsed: {
                            mainLiving: 0,
                            otherLiving: 0,
                            agriculture: 0,
                            other: 0,
                            abandoned: 0,
                            value: 4
                        }
                    }]
                }

                // Convert Building Material 0 to 1
                var buildingMaterial
                if (value.b_material !== 0) {
                    buildingMaterial = value.b_material
                } else {
                    buildingMaterial = 1
                }
                // End Convert Building Material 0 to 1
                arr[index] = {
                    address: value.b_address,
                    buildingType: value.b_type,
                    buildingMaterial: buildingMaterial,
                    floorAmount: parseInt(floorAmount),
                    yearOfBuliding: value.build_year,
                    floorDetails: arr2,
                    owner: {
                        ltaxOwnerId: value.owner_id,
                        prefix: value.codemoi,
                        firstName: value.fname,
                        lastName: value.lname,
                        idCardNo: value.pop_id,
                        address: value.o_address,
                        road: value.o_road,
                        soi: value.o_soi,
                        moo: value.o_moo,
                        tumbolId: value.o_tumbol_id,
                        telephoneNo: value.telephone
                    },
                    ltaxBuildingId: value.bid,
                    buildingIndex: index
                }
                arr2 = []
            })
            setAllBuilding(arr)
            setBackDropLoading(false)
        }
        const fetchLtaxOwnerData = async (landId) => {
            var response = await axiosSmartSurvey({
                method: 'post',
                url: '/api/ownerid',
                data: { landId: landId }
            })
            response = response.data[0]
            var ownerData = {
                ltaxOwnerId: response.owner_id,
                firstName: response.fname,
                lastName: response.lname,
                idCardNo: response.pop_id,
                address: response.address,
                moo: response.moo,
                soi: response.soi,
                road: response.road,
                tumbolId: response.tumbol_id,
                tumbolName: response.tumbol_name,
                district: response.district,
                province: response.province,
                provinceCode: response.province_code,
                telephoneNo: response.telephone
            }
            await prefixList.forEach((data) => {
                if (data.short_prefix === response.prefix) {
                    ownerData.prefix = data.id
                }
            })
            if (response.o_type === 1) {
                ownerData.personType = 1
            } else {
                ownerData.personType = 2
            }
            setOwner(ownerData)
        }
        const fetchLtaxLandData = async (landId, parcelCode) => {
            var response = await axiosSmartSurvey({
                method: 'post',
                url: `/api/landdata`,
                data: { landId: landId }
            })
            response = response.data[0]
            // console.log(Number(response.w).toFixed(2))
            setLand({
                ...land,
                landId: response.lid,
                parcelCode: parcelCode,
                landType: parseInt(response.l_type),
                dn: response._dn,
                rawang: response.rw,
                landNo: response.ln,
                surveyNo: response.sn,
                rai: response.r,
                ngan: response.y,
                squareWa: Number(response.w).toFixed(2),
                soi: response.soi,
                moo: response.moo,
                road: response.road,
                tumbolId: response.tambon_id
            })
            setLandUsed([{
                value: 3,
                rai: response.r,
                ngan: response.y,
                squareWa: Number(response.w).toFixed(2),
                note: ''
            }])
        }
        setBackDropLoading(true);
        var url = `/api/surveypreinsert?`
        if (value) {
            url += `${param}=${value}`
        } else {
            // console.log(lid)
        }
        // console.log(url)
        const response = await axiosSmartSurvey.post(url)
        // console.log(response)
        if (response?.data?.length > 0) {
            fetchLtaxOwnerData(response.data[0].id)
            fetchLtaxLandData(response.data[0].id, parcelCode)
            fetchLtaxBuildingData(response.data[0].id)
        }
        setBackDropLoading(false)
    }
    return { fetchLtaxData }
}