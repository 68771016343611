import { Typography, Box } from '@mui/material'
import { useEffect } from 'react'
import PrimarySearchAppBar from './Layouts/PrimarySearchAppBar'
import useVerifyToken from '../hooks/useVerifyToken'

export default function Profile() {
  const { verifyToken } = useVerifyToken()
  useEffect(() => {
    verifyToken()
  }, [])

  return (
    <Box className='otherSide' id="otherSide">    
      <Box>
        <Typography variant='h5' sx={{ m: 1 }}>เปิดใช้งานได้เร็วๆ นี้</Typography>
      </Box>
    </Box>
  )
}
