import { Box, Snackbar, Alert } from '@mui/material'
import useBackDropLoadingToggle from '../../hooks/useBackDropLoadingToggle'

export default function SnackbarAlert({ successAlertOpen, successText, errorAlertOpen, errorText, successOnClose, errorOnClose }) {

    return (
        <Box>
            <Snackbar
                open={successAlertOpen}
                autoHideDuration={2000}
                onClose={(event, reason) => {
                    successOnClose()
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Alert variant='filled' severity="success" sx={{ width: '100%' }}>
                    {successText || 'บันทึกข้อมูลสำเร็จ!'}
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorAlertOpen}
                onClose={(event, reason) => {
                    errorOnClose()
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
                    {errorText}
                </Alert>
            </Snackbar>
        </Box>
    )
}
