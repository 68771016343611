import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";

const api = process.env.REACT_APP_SURVEY_API

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation();
    const navigate = useNavigate()
    const { user, setUser } = useAuth()

    useEffect(() => {
        if (!user) {
            const token = localStorage.getItem('token')
            fetch(api + '/authen', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'OK') {
                        setUser(data.decoded)
                    } else {
                        navigate('login', { state: { from: location } })
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        user
            ? allowedRoles?.includes(user?.roles)
                ? <Outlet />
                : <Navigate to="/unauthorized" state={{ from: location }} replace />
            : ''
    );
}

export default RequireAuth;