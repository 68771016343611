import { Box, Button, Card, CardContent, Typography, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useState, useEffect } from 'react'
import PrimarySearchAppBar from '../Layouts/PrimarySearchAppBar'
import { DataGrid } from '@mui/x-data-grid'
// ICON
import EditIcon from '@mui/icons-material/Edit'
import PrintIcon from '@mui/icons-material/Print'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import PaidIcon from '@mui/icons-material/Paid';
//
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/th'
import { createTheme, ThemeProvider } from '@mui/material/styles';
// Custom Component
import WasteFeeAddModal from './ChildComponents/WasteFeeAddModal'
import WasteFeePaymentEditModal from './ChildComponents/WasteFeePaymentEditModal'
import WasteFeeAlert from './Layouts/WasteFeeAlert'
// Custom Hooks
import useFetchWasteFee from './hooks/useFetchWasteFee'
import useVerifyToken from '../../hooks/useVerifyToken'
import useAuth from '../../hooks/useAuth'


const MyPagination = ({ page, onPageChange }) => {
    const totalPages = 5;
    const currentPageText = `Page ${page + 1} of ${totalPages}`; // Your custom format

    return (
        <div>
            {currentPageText}
            {/* Buttons or other controls for pagination */}
        </div>
    );
};
const theme = createTheme({
    typography: {
        fontFamily: [
            'IBM Plex Sans Thai',
            'sans-serif',
        ].join(','),
    },
});
const cardBodyStyle = {
    border: ' grey',
    background: '#fafaeb',
    textAlign: 'center',
    m: 2,
    width: '90%',
    justifyContent: 'center',
    // borderRadius: 2,
}
const cardStyle = {
    m: 2,
    width: '90%',
    justifyContent: 'center',
    borderRadius: 2
}
export default function WasteCollectionFee() {
    const { verifyToken } = useVerifyToken()
    const navigate = useNavigate()
    const location = useLocation()
    const { user } = useAuth()

    // SearchParams
    let [searchParams, setSearchParams] = useSearchParams()
    const [search, setSearch] = useState({
        parcelCode: searchParams.get('parcelCode') || '',
        address: searchParams.get('address') || '',
        houseName: searchParams.get('houseName') || ''
    })

    const [wasteFeeDataList, setWasteFeeDataList] = useState([])
    const [rowId, setRowId] = useState()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [statusChangedNote, setStatusChangedNote] = useState('')
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [editPaymentModalOpen, setEditPaymentModalOpen] = useState(false)
    // Alert Popup
    const [successAlertOpen, setSuccessAlertOpen] = useState(false)
    const [errorAlertOpen, setErrorAlertOpen] = useState(false)
    const [successText, setSuccessText] = useState('')
    const [errorText, setErrorText] = useState('')
    const handleDeleteDialogClickOpen = () => {
        setDeleteDialogOpen(true);
    };
    // Custom Hooks
    const { submitSearch, axiosWasteFeeDataList, fetchDeleteWasteFeeData } = useFetchWasteFee()
    //

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false)
        setStatusChangedNote('')
        setRowId(undefined)
    };
    const columns = [
        { field: 'id', headerName: 'ลำดับที่', width: 60 },
        { field: 'parcel_code', headerName: 'รหัสแปลงที่ดิน', width: 120 },
        { field: 'house_name', headerName: 'ชื่อสถานประกอบการ', width: 250 },
        { field: 'address', headerName: 'บ้านเลขที่', width: 80 },
        { field: 'route', headerName: 'เส้นทาง', width: 120 },
        { field: 'road', headerName: 'ถนน', width: 120 },
        { field: 'tumbol_name', headerName: 'ตำบล', width: 120 },
        { field: 'fee', headerName: 'ค่าธรรมเนียม', width: 120 },
        {
            field: 'Button',
            headerName: '',
            sortable: false,
            width: 350,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<PaidIcon />}
                            onClick={() => {
                                setRowId(id)
                                setEditPaymentModalOpen(true)
                            }}
                        >
                            การจ่ายเงิน
                        </Button>
                        <Button
                            sx={{ ml: 1 }}
                            variant="contained"
                            color="warning"
                            startIcon={<EditIcon />}
                            onClick={() => {
                                setRowId(id)
                                setAddModalOpen(true)
                            }}
                        >
                            แก้ไข
                        </Button>
                        <Button
                            sx={{ ml: 1 }}
                            variant="contained"
                            color="error"
                            startIcon={<RemoveCircleIcon />}
                            onClick={() => {
                                setRowId(id)
                                handleDeleteDialogClickOpen()
                            }}
                        >
                            ลบ
                        </Button>
                    </Box >
                )
            }
        }
    ]
    useEffect(() => {
        verifyToken()
    }, [])

    useEffect(() => {
        axiosWasteFeeDataList(setWasteFeeDataList)
        // eslint-disable-next-line
    }, [])
    // useEffect(() => {
    //     console.log(surveyDeleteId)
    // }, [surveyDeleteId])


    return (
        <Box className='otherSide' id="otherSide">
            <Box className='surveyLayouts' id="surveyLayouts">
                <Card sx={cardBodyStyle}>
                    <CardContent >
                        <Typography variant='h5'>รายการจัดเก็บค่าขยะ</Typography>
                    </CardContent>
                    <CardContent>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                '& button': { m: 1, mt: 2, width: '15ch' }
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={(event) => {
                                event.preventDefault()
                                setSearchParams({ parcelCode: search.parcelCode })
                                //ถ้าไม่มีข้อมูลค้นหา ให้เคลียร์paramแล้วดึงข้อมูลข้อมูลเริ่มต้น
                                if (search.parcelCode === '' && search.address === '' && search.houseName === '') {
                                    setSearchParams()
                                    axiosWasteFeeDataList(setWasteFeeDataList)
                                }
                                submitSearch(search.parcelCode, search.address, search.houseName, setWasteFeeDataList)
                            }}
                        >
                            <Box sx={{ textAlign: 'center' }}>
                                <TextField
                                    id="parcelCode"
                                    label="รหัสแปลงที่ดิน"
                                    onChange={(event) => setSearch({ ...search, parcelCode: event.target.value })}
                                    value={search.parcelCode}
                                />
                                <TextField
                                    id="address"
                                    label="บ้านเลขที่"
                                    onChange={(event) => setSearch({ ...search, address: event.target.value })}
                                    value={search.address}
                                />
                                <TextField
                                    id="houseName"
                                    label="ชื่อสถานประกอบการ"
                                    onChange={(event) => setSearch({ ...search, houseName: event.target.value })}
                                    value={search.houseName}
                                />
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type='submit'
                                    startIcon={<SearchIcon />}
                                >
                                    ค้นหา
                                </Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    startIcon={<AddCircleIcon />}
                                    onClick={() => { setAddModalOpen(true) }}
                                >
                                    เพิ่ม
                                </Button>
                            </Box>
                            <div style={{
                                justifyContent: 'center',
                                display: "flex"
                            }}>

                            </div>
                        </Box>
                    </CardContent>
                    {wasteFeeDataList?.length > 0
                        ?
                        <CardContent>

                            <Box style={{ height: 400, width: 'auto' }}>
                                <DataGrid
                                    rows={wasteFeeDataList}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                />
                            </Box>
                        </CardContent>
                        : ''}
                </Card>
            </Box>
            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>ท่านต้องการลบรายการจัดเก็บ?</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ width: '60ch' }}>
                        โปรดระบุสาเหตุการลบรายการจัดเก็บ
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="note"
                        label="สาเหตุ"
                        multiline
                        rows={3}
                        fullWidth
                        variant="standard"
                        value={statusChangedNote}
                        onChange={(event) => {
                            setStatusChangedNote(event.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            await fetchDeleteWasteFeeData(handleDeleteDialogClose, rowId, statusChangedNote)
                            axiosWasteFeeDataList(setWasteFeeDataList)
                        }}
                        color='error'
                        startIcon={<RemoveCircleIcon />}
                    >
                        ลบ
                    </Button>
                    <Button
                        variant="contained"
                        color='warning'
                        onClick={handleDeleteDialogClose}>
                        ยกเลิก
                    </Button>
                </DialogActions>
            </Dialog>
            <WasteFeeAddModal
                addModalOpen={addModalOpen}
                setAddModalOpen={setAddModalOpen}
                rowId={rowId}
                setRowId={setRowId}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                setSuccessText={setSuccessText}
                setErrorText={setErrorText}
                axiosWasteFeeDataList={axiosWasteFeeDataList}
                setWasteFeeDataList={setWasteFeeDataList}
            />
            <WasteFeePaymentEditModal
                editPaymentModalOpen={editPaymentModalOpen}
                setEditPaymentModalOpen={setEditPaymentModalOpen}
                rowId={rowId}
                setRowId={setRowId}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessText={setSuccessText}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorText={setErrorText}
            />
            <WasteFeeAlert
                successText={successText}
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorText={errorText}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
            // handleBackDropLoadingToggle={handleBackDropLoadingToggle}
            // setBackDropLoading={setBackDropLoading}
            />
        </Box>
    )
}
