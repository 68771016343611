import {
  Box,
  Button,
  MenuItem,
  TextField,
  Card,
  CardContent,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Modal,
  Grid,
  CardActionArea,
  CardMedia
} from '@mui/material'
import { useEffect, useState } from 'react'
import useUtilitiesSurveyData from '../../../hooks/useUtilitiesSurveyData';
import ModalBuildingUsed from './ModalBuildingUsed';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ModalWhoUsed from './ModalWhoUsed';



export default function BuildingModal({
  buildingOwnertumbol,
  setBuildingOwnertumbol,
  owner,
  allBuilding,
  setAllBuilding,
  buildingMaterial,
  modalFloorDetails,
  setModalFloorDetails,
  errorModalFloorDetails,
  setErrorModalFloorDetails,
  buildingModalData,
  setBuildingModalData,
  buildingModalOpen,
  setBuildingModalOpen,
  errorBuildingModalData,
  setErrorBuildingModalData,
  checkBox,
  setCheckBox,
  whoUsed,
  cardStyle,
  cardBodyStyle,
  cardHeaderStyle,
}) {
  const { buildingTypeList,
    handleFloorChange,
    handleBuildingModalClose,
    createBuildingUsed,
    personType,
    prefixList,
    handleProvinceSelect,
    handleDistrictSelect,
    provinceList,
    buildYear
  } = useUtilitiesSurveyData()
  const [districtList, setDistrictList] = useState([])
  const [tumbolList, setTumbolList] = useState([{}])
  function callhandleBuildingModalClose() {
    handleBuildingModalClose(setBuildingModalOpen, setCheckBox, setBuildingOwnertumbol, setBuildingModalData)
  }
  // useEffect(() => {
  //   console.log(buildingModalData)
  // }, [buildingModalData])

  return (
    <Modal
      open={buildingModalOpen}
      onClose={callhandleBuildingModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <Card sx={cardStyle}>
        <CardContent sx={cardHeaderStyle}>
          <Typography variant='h5'>{allBuilding?.length > buildingModalData.buildingIndex + 1 ? 'แก้ไขข้อมูลสิ่งปลูกสร้าง' : 'บันทึกข้อมูลสิ่งปลูกสร้าง'}</Typography >
        </CardContent>
        <CardContent sx={cardBodyStyle}>
          <Grid container my={2}>
            <Grid item xs={8}>
              <Box sx={{ '& .MuiTextField-root': { m: 1 } }}>
                <TextField
                  sx={{ width: '15ch' }}
                  id='address'
                  label='บ้านเลขที่'
                  value={buildingModalData.address}
                  onChange={(event) => {
                    setBuildingModalData({ ...buildingModalData, address: event.target.value })
                  }}
                  onFocus={event => {
                    event.target.select();
                  }}
                  onBlur={(event) => {
                    if (event.target.value === '') {
                      setErrorBuildingModalData({ ...errorBuildingModalData, address: true })
                    } else {
                      setErrorBuildingModalData({ ...errorBuildingModalData, address: false })
                    }
                  }}
                  helperText={errorBuildingModalData.address ? 'กรุณาระบุ' : ''}
                  error={errorBuildingModalData.address}
                />
                <TextField
                  sx={{ width: '25ch' }}
                  label='ประเภทสิ่งปลูกสร้าง'
                  select
                  value={buildingModalData.buildingType || ''}
                  onChange={(event) => {
                    setBuildingModalData({ ...buildingModalData, buildingType: event.target.value })
                  }}
                  onBlur={(event) => {
                    if (event.target.value === '') {
                      setErrorBuildingModalData({ ...errorBuildingModalData, buildingType: true })
                    } else {
                      setErrorBuildingModalData({ ...errorBuildingModalData, buildingType: false })
                    }
                  }}
                  helperText={errorBuildingModalData.buildingType ? 'กรุณาระบุ' : ''}
                  error={errorBuildingModalData.buildingType}
                >
                  {buildingTypeList.map((data, index) => (
                    <MenuItem key={index} value={data.aid}>{data.bt_code + ' ' + data.bt_desc}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{ width: '20ch' }}
                  label='ลักษณะสิ่งปลูกสร้าง'
                  select
                  value={buildingModalData.buildingMaterial || ''}
                  onChange={(event) => {
                    setBuildingModalData({ ...buildingModalData, buildingMaterial: event.target.value })
                  }}
                  onBlur={(event) => {
                    if (event.target.value === '') {
                      setErrorBuildingModalData({ ...errorBuildingModalData, buildingMaterial: true })
                    } else {
                      setErrorBuildingModalData({ ...errorBuildingModalData, buildingMaterial: false })
                    }
                  }}
                  helperText={errorBuildingModalData.buildingMaterial ? 'กรุณาระบุ' : ''}
                  error={errorBuildingModalData.buildingMaterial}
                >
                  {buildingMaterial.map((bmData, index) => (
                    <MenuItem key={index} value={index + 1}>{bmData}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{ width: '12ch' }}
                  label='จำนวนชั้น'
                  select
                  value={buildingModalData.floorAmount || ''}
                  onChange={(event) => {
                    setBuildingModalData({ ...buildingModalData, floorAmount: event.target.value })
                    handleFloorChange(event.target.value, allBuilding, setBuildingModalData, buildingModalData, modalFloorDetails, setModalFloorDetails, checkBox, setCheckBox)
                  }}
                  onBlur={(event) => {
                    if (event.target.value === '') {
                      setErrorBuildingModalData({ ...errorBuildingModalData, floorAmount: true })
                    } else {
                      setErrorBuildingModalData({ ...errorBuildingModalData, floorAmount: false })
                    }
                  }}
                  helperText={errorBuildingModalData.floorAmount ? 'กรุณาระบุ' : ''}
                  error={errorBuildingModalData.floorAmount}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </TextField>
                <TextField
                  sx={{ width: '10ch' }}
                  label='ปีที่สร้าง'
                  value={buildingModalData.yearOfBuliding || ''}
                  onChange={(event) => {
                    setBuildingModalData({ ...buildingModalData, yearOfBuliding: event.target.value })
                  }}
                  onBlur={(event) => {
                    if (event.target.value === '') {
                      setErrorBuildingModalData({ ...errorBuildingModalData, yearOfBuliding: true })
                    } else {
                      setErrorBuildingModalData({ ...errorBuildingModalData, yearOfBuliding: false })
                    }
                  }}
                  helperText={errorBuildingModalData.yearOfBuliding ? 'กรุณาระบุ' : ''}
                  error={errorBuildingModalData.yearOfBuliding}
                >
                </TextField>

                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked
                      onChange={(event) => {
                        setCheckBox({ ...checkBox, checkBox1: !checkBox.checkBox1 })
                      }}
                    />}
                    label="เนื้อที่เท่ากันทุกชั้น"
                    sx={{ width: '25ch' }}
                  />
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked
                      onChange={(event) => {
                        setCheckBox({ ...checkBox, checkBox2: !checkBox.checkBox2 })
                      }}
                    />}
                    label="การใช้ประโยชน์เดียวกันทั้งตึก"
                    sx={{ width: '30ch' }}
                  />
                </FormGroup>
                {checkBox.checkBox1 && checkBox.checkBox2 ?
                  <Box>
                    <TextField
                      sx={{ width: '10ch' }}
                      label='กว้าง'
                      type='number'
                      value={modalFloorDetails[0].width}
                      onChange={(event) => {
                        var arr = [...modalFloorDetails]
                        arr.forEach((data, index) => {
                          arr[index].width = event.target.value
                          createBuildingUsed(index, modalFloorDetails, setModalFloorDetails, setBuildingModalData, buildingModalData)
                        })
                        setModalFloorDetails(arr)
                        setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                      }}
                      onFocus={event => {
                        event.target.select();
                      }}
                      onBlur={(event) => {
                        if (event.target.value == 0) {
                          var arr = [...errorModalFloorDetails]
                          arr[0].width = true
                          setErrorModalFloorDetails(arr)
                        } else {
                          var arr = [...errorModalFloorDetails]
                          arr[0].width = false
                          setErrorModalFloorDetails(arr)
                        }
                      }}
                      helperText={errorModalFloorDetails[0].width ? 'กรุณาระบุ' : ''}
                      error={errorModalFloorDetails[0].width}
                    />
                    <TextField
                      sx={{ width: '10ch' }}
                      label='ยาว'
                      type='number'
                      value={modalFloorDetails[0].length}
                      onChange={(event) => {
                        var arr = [...modalFloorDetails]
                        arr.forEach((data, index) => {
                          arr[index].length = event.target.value
                          createBuildingUsed(index, modalFloorDetails, setModalFloorDetails, setBuildingModalData, buildingModalData)
                        })
                        setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                      }}
                      onFocus={event => {
                        event.target.select();
                      }}
                      onBlur={(event) => {
                        if (event.target.value == 0) {
                          var arr = [...errorModalFloorDetails]
                          arr[0].length = true
                          setErrorModalFloorDetails(arr)
                        } else {
                          var arr = [...errorModalFloorDetails]
                          arr[0].length = false
                          setErrorModalFloorDetails(arr)
                        }
                      }}
                      helperText={errorModalFloorDetails[0].length ? 'กรุณาระบุ' : ''}
                      error={errorModalFloorDetails[0].length}
                    />
                    <TextField
                      sx={{ width: '18ch' }}
                      label='ลักษณะการใช้'
                      select
                      value={modalFloorDetails[0].whoUsed || ''}
                      onChange={(event) => {
                        var arr = [...modalFloorDetails]
                        arr.forEach((data, index) => {
                          arr[index].whoUsed = event.target.value
                        })
                        setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                      }}
                      onBlur={(event) => {
                        if (event.target.value == 0) {
                          var arr = [...errorModalFloorDetails]
                          arr[0].whoUsed = true
                          setErrorModalFloorDetails(arr)
                        } else {
                          var arr = [...errorModalFloorDetails]
                          arr[0].whoUsed = false
                          setErrorModalFloorDetails(arr)
                        }
                      }}
                      helperText={errorModalFloorDetails[0].whoUsed ? 'กรุณาระบุ' : ''}
                      error={errorModalFloorDetails[0].whoUsed}
                    >
                      {whoUsed.map((bmData, index) => (
                        <MenuItem key={index} value={index + 1}>{bmData}</MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      sx={{ width: '30ch' }}
                      label='ลักษณะการใช้ประโยชน์'
                      select
                      value={modalFloorDetails[0].buildingUsed.value || ''}
                      onChange={(event) => {
                        var arr = modalFloorDetails
                        let value = event.target.value
                        arr.forEach((data, index) => {
                          var obj = {
                            mainLiving: 0,
                            otherLiving: 0,
                            agriculture: 0,
                            other: 0,
                            abandoned: 0,
                            value: value
                          }
                          if (value === 1) {
                            obj.mainLiving = (data.width * data.length)
                          } else if (value === 2) {
                            obj.otherLiving = (data.width * data.length)
                          } else if (value === 3) {
                            obj.agriculture = (data.width * data.length)
                          } else if (value === 4) {
                            obj.other = (data.width * data.length)
                          } else {
                            obj.abandoned = (data.width * data.length)
                          }
                          obj.value = value
                          arr[index].buildingUsed = obj
                        })
                        setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                      }}
                      onBlur={(event) => {
                        if (event.target.value == 0) {
                          var arr = [...errorModalFloorDetails]
                          arr[0].buildingUsed.value = true
                          setErrorModalFloorDetails(arr)
                        } else {
                          var arr = [...errorModalFloorDetails]
                          arr[0].buildingUsed.value = false
                          setErrorModalFloorDetails(arr)
                        }
                      }}
                      helperText={errorModalFloorDetails[0].buildingUsed.value ? 'กรุณาระบุ' : ''}
                      error={errorModalFloorDetails[0].buildingUsed.value}
                    >
                      <MenuItem value={1}>อยู่อาศัยหลังหลัก</MenuItem>
                      <MenuItem value={2}>อยู่อาศัยหลังอื่น</MenuItem>
                      <MenuItem value={3}>เกษตรกรรม</MenuItem>
                      <MenuItem value={4}>อื่นๆ</MenuItem>
                      <MenuItem value={5}>ว่างเปล่าไม่ทำประโยชน์ตามสมควร</MenuItem>
                    </TextField>
                  </Box>
                  : ''
                }
                {!checkBox.checkBox1 && checkBox.checkBox2 ?
                  modalFloorDetails.map((data, index) => (
                    <Box key={index}>
                      <TextField
                        sx={{ width: '8ch' }}
                        label='ชั้นที่'
                        value={index + 1}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      {index > 0 ?
                        <Box component='span'>
                          <TextField
                            sx={{ width: '10ch' }}
                            id={'width' + index}
                            label='กว้าง'
                            type='number'
                            value={modalFloorDetails[index].width}
                            onChange={(event) => {
                              var arr = [...modalFloorDetails]
                              arr[index].width = event.target.value
                              createBuildingUsed(index, modalFloorDetails, setModalFloorDetails, setBuildingModalData, buildingModalData)
                              setModalFloorDetails(arr)
                              setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                            }}
                            onFocus={event => {
                              event.target.select();
                            }}
                          // onBlur={(event) => {
                          //   if (event.target.value == 0) {
                          //     var arr = [...errorModalFloorDetails]
                          //     arr[index].width = true
                          //     setErrorModalFloorDetails(arr)
                          //   } else {
                          //     var arr = [...errorModalFloorDetails]
                          //     arr[index].width = false
                          //     setErrorModalFloorDetails(arr)
                          //   }
                          // }}
                          // helperText={errorModalFloorDetails[index].width ? 'กรุณาระบุ' : ''}
                          // error={errorModalFloorDetails[index].width}
                          />
                          <TextField
                            sx={{ width: '10ch' }}
                            label='ยาว'
                            type='number'
                            value={modalFloorDetails[index].length}
                            onChange={(event) => {
                              var arr = [...modalFloorDetails]
                              arr[index].length = event.target.value
                              createBuildingUsed(index, modalFloorDetails, setModalFloorDetails, setBuildingModalData, buildingModalData)
                              setModalFloorDetails(arr)
                              setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                            }}
                            onFocus={event => {
                              event.target.select();
                            }}
                          />
                          <ModalWhoUsed
                            index={index}
                            modalFloorDetails={modalFloorDetails}
                            setModalFloorDetails={setModalFloorDetails}
                            buildingModalData={buildingModalData}
                            setBuildingModalData={setBuildingModalData}
                            whoUsed={whoUsed}
                          />
                        </Box>
                        :
                        <Box component='span'>
                          <TextField
                            sx={{ width: '10ch' }}
                            id={'width' + index}
                            label='กว้าง'
                            type='number'
                            value={modalFloorDetails[index].width}
                            onChange={(event) => {
                              var arr = [...modalFloorDetails]
                              arr[index].width = event.target.value
                              createBuildingUsed(index, modalFloorDetails, setModalFloorDetails, setBuildingModalData, buildingModalData)
                              setModalFloorDetails(arr)
                              setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                            }}
                            onFocus={event => {
                              event.target.select();
                            }}
                          />
                          <TextField
                            sx={{ width: '10ch' }}
                            label='ยาว'
                            type='number'
                            value={modalFloorDetails[index].length}
                            onChange={(event) => {
                              var arr = [...modalFloorDetails]
                              arr[index].length = event.target.value
                              createBuildingUsed(index, modalFloorDetails, setModalFloorDetails, setBuildingModalData, buildingModalData)
                              setModalFloorDetails(arr)
                              setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                            }}
                            onFocus={event => {
                              event.target.select();
                            }}
                          />
                          <ModalWhoUsed
                            index={index}
                            modalFloorDetails={modalFloorDetails}
                            setModalFloorDetails={setModalFloorDetails}
                            buildingModalData={buildingModalData}
                            setBuildingModalData={setBuildingModalData}
                            whoUsed={whoUsed}
                          />
                          <TextField
                            sx={{ width: '30ch' }}
                            label='ลักษณะการใช้ประโยชน์'
                            select
                            value={modalFloorDetails[0].buildingUsed.value || ''}
                            onChange={(event) => {
                              var arr = [...modalFloorDetails]
                              let value = event.target.value
                              arr.forEach((data, index) => {
                                var obj = {
                                  mainLiving: 0,
                                  otherLiving: 0,
                                  agriculture: 0,
                                  other: 0,
                                  abandoned: 0,
                                  value: data.buildingUsed.value
                                }
                                if (value === 1) {
                                  obj.mainLiving = (data.width * data.length)
                                } else if (value === 2) {
                                  obj.otherLiving = (data.width * data.length)
                                } else if (value === 3) {
                                  obj.agriculture = (data.width * data.length)
                                } else if (value === 4) {
                                  obj.other = (data.width * data.length)
                                } else {
                                  obj.abandoned = (data.width * data.length)
                                }
                                obj.value = value
                                arr[index].buildingUsed = obj
                              })
                              setModalFloorDetails(arr)
                              setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                            }}
                          >
                            <MenuItem value={1}>อยู่อาศัยหลังหลัก</MenuItem>
                            <MenuItem value={2}>อยู่อาศัยหลังอื่น</MenuItem>
                            <MenuItem value={3}>เกษตรกรรม</MenuItem>
                            <MenuItem value={4}>อื่นๆ</MenuItem>
                            <MenuItem value={5}>ว่างเปล่าไม่ทำประโยชน์ตามสมควร</MenuItem>
                          </TextField>
                        </Box>
                      }
                    </Box>
                  ))
                  : ''}
                {checkBox.checkBox1 && !checkBox.checkBox2 ?
                  modalFloorDetails.map((data, index) => (
                    <Box key={index}>
                      <TextField
                        sx={{ width: '8ch' }}
                        label='ชั้นที่'
                        value={index + 1}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      {index > 0 ?
                        <Box component='span'>
                          <TextField
                            sx={{ width: '10ch' }}
                            id={'width' + index}
                            label='กว้าง'
                            value={modalFloorDetails[index].width}
                            disabled
                          />
                          <TextField
                            sx={{ width: '10ch' }}
                            label='ยาว'
                            value={modalFloorDetails[index].length}
                            disabled
                          />
                        </Box>
                        : <Box component='span'>
                          <TextField
                            sx={{ width: '10ch' }}
                            label='กว้าง'
                            type='number'
                            value={modalFloorDetails[0].width}
                            onChange={(event) => {
                              var arr = [...modalFloorDetails]
                              arr.forEach((data, index) => {
                                arr[index].width = event.target.value
                                createBuildingUsed(index, modalFloorDetails, setModalFloorDetails, setBuildingModalData, buildingModalData)
                              })
                              setModalFloorDetails(arr)
                              setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                            }}
                            onFocus={event => {
                              event.target.select();
                            }}
                          />
                          <TextField
                            sx={{ width: '10ch' }}
                            label='ยาว'
                            type='number'
                            value={modalFloorDetails[0].length}
                            onChange={(event) => {
                              var arr = [...modalFloorDetails]
                              arr.forEach((data, index) => {
                                arr[index].length = event.target.value
                                createBuildingUsed(index, modalFloorDetails, setModalFloorDetails, setBuildingModalData, buildingModalData)
                              })
                              modalFloorDetails(arr)
                              setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                            }}
                            onFocus={event => {
                              event.target.select();
                            }}
                          />
                        </Box>
                      }
                      <ModalWhoUsed
                        index={index}
                        modalFloorDetails={modalFloorDetails}
                        setModalFloorDetails={setModalFloorDetails}
                        buildingModalData={buildingModalData}
                        setBuildingModalData={setBuildingModalData}
                        whoUsed={whoUsed}
                      />
                      <FormControlLabel
                        control={<Checkbox
                          defaultChecked
                          onChange={(event) => {
                            var arr = [...checkBox.checkBox3]
                            arr[index] = !arr[index]
                            setCheckBox({ ...checkBox, checkBox3: arr })
                          }}
                        />}
                        label="ใช้ประโยชน์เดียวทั้งชั้น"
                        sx={{ width: '25ch' }}
                      />
                      <ModalBuildingUsed
                        index={index}
                        districtList={districtList}
                        tumbolList={tumbolList}
                        buildingOwnertumbol={buildingOwnertumbol}
                        setBuildingOwnertumbol={setBuildingOwnertumbol}
                        whoUsed={whoUsed}
                        buildingMaterial={buildingMaterial}
                        owner={owner}
                        allBuilding={allBuilding}
                        setAllBuilding={setAllBuilding}
                        modalFloorDetails={modalFloorDetails}
                        setModalFloorDetails={setModalFloorDetails}
                        buildingModalData={buildingModalData}
                        setBuildingModalData={setBuildingModalData}
                        buildingModalOpen={buildingModalOpen}
                        setBuildingModalOpen={setBuildingModalOpen}
                        checkBox={checkBox}
                        setCheckBox={setCheckBox}
                        cardStyle={cardStyle}
                        cardBodyStyle={cardBodyStyle}
                        cardHeaderStyle={cardHeaderStyle}
                      />
                    </Box>
                  ))
                  : ''
                }
                {!checkBox.checkBox1 && !checkBox.checkBox2 ?
                  modalFloorDetails.map((data, index) => (
                    <Box key={index}>
                      <TextField
                        sx={{ width: '8ch' }}
                        label='ชั้นที่'
                        value={index + 1}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <Box component='span'>
                        <TextField
                          sx={{ width: '10ch' }}
                          id={'width' + index}
                          label='กว้าง'
                          type='number'
                          value={modalFloorDetails[index].width}
                          onChange={(event) => {
                            var arr = [...modalFloorDetails]
                            arr[index].width = event.target.value
                            createBuildingUsed(index, modalFloorDetails, setModalFloorDetails, setBuildingModalData, buildingModalData)
                            setModalFloorDetails(arr)
                            setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                          }}
                          onFocus={event => {
                            event.target.select();
                          }}
                        />
                        <TextField
                          sx={{ width: '10ch' }}
                          label='ยาว'
                          type='number'
                          value={modalFloorDetails[index].length}
                          onChange={(event) => {
                            var arr = [...modalFloorDetails]
                            arr[index].length = event.target.value
                            createBuildingUsed(index, modalFloorDetails, setModalFloorDetails, setBuildingModalData, buildingModalData)
                            setModalFloorDetails(arr)
                            setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                          }}
                          onFocus={event => {
                            event.target.select();
                          }}
                        />
                        <ModalWhoUsed
                          index={index}
                          modalFloorDetails={modalFloorDetails}
                          setModalFloorDetails={setModalFloorDetails}
                          buildingModalData={buildingModalData}
                          setBuildingModalData={setBuildingModalData}
                          whoUsed={whoUsed}
                        />
                        <FormControlLabel
                          control={<Checkbox
                            defaultChecked
                            onChange={(event) => {
                              var arr = [...checkBox.checkBox3]
                              arr[index] = !arr[index]
                              setCheckBox({ ...checkBox, checkBox3: arr })
                            }}
                          />}
                          label="ใช้ประโยชน์เดียวทั้งชั้น"
                          sx={{ width: '25ch' }}
                        />
                        <ModalBuildingUsed
                          index={index}
                          districtList={districtList}
                          tumbolList={tumbolList}
                          buildingOwnertumbol={buildingOwnertumbol}
                          setBuildingOwnertumbol={setBuildingOwnertumbol}
                          whoUsed={whoUsed}
                          buildingMaterial={buildingMaterial}
                          owner={owner}
                          allBuilding={allBuilding}
                          setAllBuilding={setAllBuilding}
                          modalFloorDetails={modalFloorDetails}
                          setModalFloorDetails={setModalFloorDetails}
                          buildingModalData={buildingModalData}
                          setBuildingModalData={setBuildingModalData}
                          buildingModalOpen={buildingModalOpen}
                          setBuildingModalOpen={setBuildingModalOpen}
                          checkBox={checkBox}
                          setCheckBox={setCheckBox}
                          cardStyle={cardStyle}
                          cardBodyStyle={cardBodyStyle}
                          cardHeaderStyle={cardHeaderStyle}
                        />
                        {/* <Divider variant="middle" sx={{ mt: 2, mb: 2 }} /> */}
                      </Box>
                    </Box>
                  ))
                  : ''
                }
                <Box>
                  {/* <Divider variant="middle" sx={{ mt: 2, mb: 2 }} /> */}
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked
                      onChange={(event) => {
                        setBuildingModalData({
                          ...buildingModalData,
                          owner: {
                            ltaxOwnerId: owner.ltaxOwnerId,
                            prefix: owner.prefix,
                            firstName: owner.firstName,
                            lastName: owner.lastName,
                            idCardNo: owner.idCardNo,
                            address: owner.address,
                            road: owner.road,
                            soi: owner.soi,
                            moo: owner.moo,
                            tumbolId: owner.tumbolId,
                            telephoneNo: owner.telephoneNo
                          }
                        })
                        setCheckBox({ ...checkBox, checkBox4: !checkBox.checkBox4 })
                      }}
                    />}
                    label="เจ้าของที่ดินเป็นเจ้าของสิ่งปลูกสร้าง"
                    sx={{ width: '35ch' }}
                  />
                  {checkBox.checkBox4 ? '' :
                    <Box
                      sx={{ '& .MuiTextField-root': { m: 1 } }}
                    >
                      <TextField
                        sx={{ width: '15ch' }}
                        select
                        label='ประเภทบุคคล'
                        value={buildingModalData.owner.personType || ''}
                        onChange={(event) => {
                          var obj
                          if (event.target.value === 1) {
                            obj = { ...buildingModalData.owner }
                            obj.personType = 1
                            obj.prefix = 18
                            setBuildingModalData({
                              ...buildingModalData,
                              owner: obj
                            })
                          } else {
                            obj = { ...buildingModalData.owner }
                            obj.personType = 2
                            obj.prefix = 63
                            setBuildingModalData({
                              ...buildingModalData,
                              owner: obj
                            })
                          }
                        }}
                      >
                        {personType.map((data, index) => (
                          <MenuItem key={index} value={data.id}>{data.value}</MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        sx={{ width: '15ch' }}
                        select
                        label='คำนำหน้าชื่อ'
                        value={buildingModalData.owner.prefix || ''}
                        onChange={(event) => {
                          var obj = { ...buildingModalData.owner }
                          obj.prefix = event.target.value
                          setBuildingModalData({
                            ...buildingModalData,
                            owner: obj
                          })
                        }}
                      >
                        {prefixList?.map((data, index) => {
                          if (buildingModalData.owner.personType === 1) {
                            if (data.person_type === 1) {
                              return <MenuItem key={index} value={data.id}>{data.short_prefix}</MenuItem>
                            }
                          } else {
                            if (data.person_type !== 1) {
                              return <MenuItem key={index} value={data.id}>{data.short_prefix}</MenuItem>
                            }
                          }
                          return null
                        })}
                      </TextField>
                      <TextField
                        sx={{ width: '25ch' }}
                        label='ชื่อ'
                        value={buildingModalData.owner.firstName}
                        onChange={(event) => {
                          var obj = { ...buildingModalData.owner }
                          obj.firstName = event.target.value
                          setBuildingModalData({
                            ...buildingModalData,
                            owner: obj
                          })
                        }}
                        onFocus={event => {
                          event.target.select();
                        }}
                      />
                      <TextField
                        sx={{ width: '25ch' }}
                        label='นามสกุล'
                        value={buildingModalData.owner.lastName}
                        onChange={(event) => {
                          var obj = { ...buildingModalData.owner }
                          obj.lastName = event.target.value
                          setBuildingModalData({
                            ...buildingModalData,
                            owner: obj
                          })
                        }}
                        onFocus={event => {
                          event.target.select();
                        }}

                      />
                      <TextField
                        sx={{ width: '25ch' }}
                        label='เลขบัตรประจำตัวประชาชน'
                        value={buildingModalData.owner.idCardNo}
                        onChange={(event) => {
                          var obj = { ...buildingModalData.owner }
                          obj.idCardNo = event.target.value
                          setBuildingModalData({
                            ...buildingModalData,
                            owner: obj
                          })
                        }}
                        onFocus={event => {
                          event.target.select();
                        }}

                      />
                      <TextField
                        sx={{ width: '15ch' }}
                        label='บ้านเลขที่'
                        value={buildingModalData.owner.address}
                        onChange={(event) => {
                          var obj = { ...buildingModalData.owner }
                          obj.address = event.target.value
                          setBuildingModalData({
                            ...buildingModalData,
                            owner: obj
                          })
                        }}
                        onFocus={event => {
                          event.target.select();
                        }}

                      />
                      <TextField
                        sx={{ width: '15ch' }}
                        label='หมู่'
                        type='number'
                        value={buildingModalData.owner.moo}
                        onChange={(event) => {
                          var obj = { ...buildingModalData.owner }
                          obj.moo = event.target.value
                          setBuildingModalData({
                            ...buildingModalData,
                            owner: obj
                          })
                        }}
                        onFocus={event => {
                          event.target.select();
                        }}

                      />
                      <TextField
                        sx={{ width: '15ch' }}
                        label='ถนน'
                        value={buildingModalData.owner.road}
                        onChange={(event) => {
                          var obj = { ...buildingModalData.owner }
                          obj.road = event.target.value
                          setBuildingModalData({
                            ...buildingModalData,
                            owner: obj
                          })
                        }}
                        onFocus={event => {
                          event.target.select();
                        }}

                      />
                      <TextField
                        sx={{ width: '15ch' }}
                        label='ซอย'
                        value={buildingModalData.owner.soi}
                        onChange={(event) => {
                          var obj = { ...buildingModalData.owner }
                          obj.soi = event.target.value
                          setBuildingModalData({
                            ...buildingModalData,
                            owner: obj
                          })
                        }}
                        onFocus={event => {
                          event.target.select();
                        }}
                      />
                      <TextField
                        sx={{ width: '15ch' }}
                        label='จังหวัด'
                        select
                        value={buildingOwnertumbol.provinceCode || ''}
                        onChange={(event) => {
                          handleProvinceSelect(event.target.value, setTumbolList, setDistrictList)
                          setBuildingOwnertumbol({ ...buildingOwnertumbol, provinceCode: event.target.value })
                        }}
                      >
                        {provinceList.map((pData, index) => (
                          <MenuItem key={index} inner value={pData.province_code}>{pData.province}</MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        sx={{ width: '20ch' }}
                        label='อำเภอ'
                        select
                        value={buildingOwnertumbol.district || ''}
                        onChange={(event) => {
                          handleDistrictSelect(event.target.value, setTumbolList)
                          setBuildingOwnertumbol({ ...buildingOwnertumbol, district: event.target.value })
                        }}
                      >
                        {districtList.map((dData, index) => (
                          <MenuItem key={index} value={dData.district}>{dData.district}</MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        sx={{ width: '15ch' }}
                        label='ตำบล'
                        select
                        value={buildingModalData.owner.tumbolId || ''}
                        onChange={(event) => {
                          var obj = buildingModalData.owner
                          obj.tumbolId = event.target.value
                          setBuildingModalData({
                            ...buildingModalData,
                            owner: obj
                          })
                        }}
                      >
                        {tumbolList.map((tData, index) => (
                          < MenuItem key={index} value={tData.tumbol_id} > {tData.tumbol_name}</MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        sx={{ width: '20ch' }}
                        label='โทร'
                        value={buildingModalData.owner.telephoneNo}
                        onChange={(event) => {
                          var obj = { ...buildingModalData.owner }
                          obj.telephoneNo = event.target.value
                          setBuildingModalData({
                            ...buildingModalData,
                            owner: obj
                          })
                        }}
                        onFocus={event => {
                          event.target.select();
                        }}
                      />
                    </Box>
                  }
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-full-width"
                label="เพิ่มรูปภาพ"
                style={{ margin: 8 }}
                name="upload-photo"
                type="file"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                onChange={(event) => {
                  let url = URL.createObjectURL(event.target.files[0]);
                  // console.log(url)
                  setBuildingModalData({ ...buildingModalData, image: event.target.files[0], imageURL: url })
                }}
              />
              {buildingModalData.imageURL
                ?
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="auto"
                      title="Contemplative Reptile"
                      image={buildingModalData.imageURL}
                    />
                  </CardActionArea>
                </Card>
                : ''
              }
            </Grid>
          </Grid>
          <Button
            variant='contained'
            startIcon={<SaveIcon />}
            onClick={() => {
              var arr = []
              if (allBuilding?.length > 0) {
                arr = [...allBuilding]
              }
              arr[buildingModalData.buildingIndex] = buildingModalData
              arr[buildingModalData.buildingIndex].floorDetails = modalFloorDetails
              setAllBuilding(arr)
              callhandleBuildingModalClose()
            }}
          >
            บันทึกข้อมูล
          </Button>
          <Button
            sx={{ marginLeft: '1ch' }}
            variant='contained'
            color='error'
            startIcon={<CancelIcon />}
            onClick={callhandleBuildingModalClose}
          >
            ยกเลิก
          </Button>
        </CardContent>
      </Card>
    </Modal>
  )
}
