import useAuth from '../../../hooks/useAuth'
import useBuildingImageUpload from './useBuildingImageUpload'
import useLandImageUpload from './useLandImageUpload'
import axiosSmartSurvey from '../../../api/axios'


export default function useEditSurveyButtonSubmit() {
    const { user } = useAuth()
    const { fetchLandImageUpload } = useLandImageUpload()
    const { fetchBuildingImageUpload } = useBuildingImageUpload()
    async function handleEditSurveySubmit(setErrorText, setErrorAlertOpen, setSuccessAlertOpen, handleErrorAlert, setBackDropLoading, handleBackDropLoadingToggle, owner, land, landUsed, allBuilding, surveyData, bottomCard) {
        console.log(bottomCard)
        setBackDropLoading(true)
        await fetchBuildingImageUpload(land, allBuilding)
            .then(async (buildingImageId) => {
                // console.log(buildingImageId)
                await fetchLandImageUpload(land, landUsed)
                    .then(async (landImageId) => {
                        // console.log(landImageId)
                        await axiosSmartSurvey({
                            method: 'post',
                            url: `/survey/edit/editsurvey`,
                            data: {
                                userId: user.userId,
                                land: land,
                                landUsed: landUsed,
                                allBuilding: allBuilding,
                                owner: owner,
                                buildingImageId: buildingImageId,
                                landImageId: landImageId,
                                surveyId: surveyData.id,
                                bottomCard: bottomCard
                            }
                        }).then((response) => {
                            response = response.data
                            // console.log(response)
                            if (response.status === 'OK') {
                                setSuccessAlertOpen(true)
                            } else {
                                // console.log(response)
                                handleErrorAlert(response.msg, setErrorText, setErrorAlertOpen)
                            }
                        }).catch((err) => {
                            console.log(err)
                        })
                    }).catch((err) => {
                        console.log(err)
                    })
            }).catch((err) => {
                console.log(err)
            })
    }
    return { handleEditSurveySubmit }
}
