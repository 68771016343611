import { Modal, Box, TextField, MenuItem, Button, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import useFetchWasteFee from '../hooks/useFetchWasteFee';
import useDataValidation from '../../../hooks/useDataValidation';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    '& .MuiTextField-root': { m: 1 }
};

export default function WasteFeeAddModal({ setWasteFeeDataList, addModalOpen, setAddModalOpen, rowId, setRowId, setSuccessAlertOpen, setErrorAlertOpen, setSuccessText, setErrorText }) {
    const { onFetchEditInput, addWasteFeeData, editWasteFeeData, axiosWasteFeeDataList } = useFetchWasteFee()
    const [wasteFeeData, setWasteFeeData] = useState({})
    const [errorWasteFeeData, setErrorWasteFeeData] = useState({})
    const { isValid } = useDataValidation()
    function setDefaultWasteFeeData() {
        setWasteFeeData({
            parcelCode: '',
            houseName: '',
            houseCode: '',
            address: '',
            route: '',
            road: '',
            soi: '',
            moo: '',
            tumbol: 0,
            fee: 0
        })
        setErrorWasteFeeData({
            parcelCode: false,
            houseName: false,
            houseCode: false,
            address: false,
            route: false,
            road: false,
            soi: false,
            moo: false,
            tumbol: false,
            fee: false
        })
        setRowId(undefined)
    }
    useEffect(() => {
        if (rowId === undefined) {
            setDefaultWasteFeeData()
        } else {
            onFetchEditInput(rowId, setWasteFeeData)
        }
    }, [rowId])
    // useEffect(() => {
    //     console.log(wasteFeeData)
    // }, [wasteFeeData])
    return (
        <Modal
            open={addModalOpen}
            onClose={() => {
                setAddModalOpen(false)
                setDefaultWasteFeeData()
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={style}
                component='form'
                onSubmit={(event) => {
                    if (rowId === undefined) {
                        addWasteFeeData(wasteFeeData, setDefaultWasteFeeData, setAddModalOpen, setSuccessAlertOpen, setErrorAlertOpen, setSuccessText, setErrorText)
                    } else {
                        editWasteFeeData(wasteFeeData, setDefaultWasteFeeData, setAddModalOpen, setSuccessAlertOpen, setErrorAlertOpen, setSuccessText, setErrorText, rowId)
                    }
                    axiosWasteFeeDataList(setWasteFeeDataList)
                    console.log('first')
                }}
            >
                <Typography variant='h5'>{rowId !== undefined ? 'แก้ไขข้อมูลจัดเก็บขยะ' : 'บันทึกข้อมูลจัดเก็บขยะ'}</Typography >
                <TextField
                    id="parcelCode"
                    label="รหัสแปลงที่ดิน"
                    value={wasteFeeData.parcelCode}
                    onChange={(event) => setWasteFeeData({ ...wasteFeeData, parcelCode: event.target.value })}
                    onFocus={event => {
                        event.target.select();
                    }}
                    onBlur={(event) => {
                        setErrorWasteFeeData({ ...errorWasteFeeData, parcelCode: !isValid(event.target.value, 2) })
                    }}
                    helperText={errorWasteFeeData.parcelCode ? 'กรุณากรอก' : ''}
                    error={errorWasteFeeData.parcelCode}
                />
                <TextField
                    id="houseName"
                    label="ชื่อสถานประกอบการ"
                    value={wasteFeeData.houseName}
                    onChange={(event) => setWasteFeeData({ ...wasteFeeData, houseName: event.target.value })}
                    onFocus={event => {
                        event.target.select();
                    }}
                />
                <TextField
                    id="houseCode"
                    label="เลขรหัสประจำบ้าน(ตามทะเบียนบ้าน)"
                    value={wasteFeeData.houseCode}
                    onChange={(event) => setWasteFeeData({ ...wasteFeeData, houseCode: event.target.value })}
                    onFocus={event => {
                        event.target.select();
                    }}
                />
                <TextField
                    id="address"
                    label="บ้านเลขที่"
                    value={wasteFeeData.address}
                    onChange={(event) => setWasteFeeData({ ...wasteFeeData, address: event.target.value })}
                    onFocus={event => {
                        event.target.select();
                    }}
                />
                <TextField
                    id="route"
                    label="เส้นทาง"
                    value={wasteFeeData.route}
                    onChange={(event) => setWasteFeeData({ ...wasteFeeData, route: event.target.value })}
                    onFocus={event => {
                        event.target.select();
                    }}
                />
                <TextField
                    id="road"
                    label="ถนน"
                    value={wasteFeeData.road}
                    onChange={(event) => setWasteFeeData({ ...wasteFeeData, road: event.target.value })}
                    onFocus={event => {
                        event.target.select();
                    }}
                />
                <TextField
                    id="soi"
                    label="ซอย"
                    value={wasteFeeData.soi}
                    onChange={(event) => setWasteFeeData({ ...wasteFeeData, soi: event.target.value })}
                    onFocus={event => {
                        event.target.select();
                    }}
                />
                <TextField
                    id="moo"
                    label="หมู่"
                    value={wasteFeeData.moo}
                    onChange={(event) => setWasteFeeData({ ...wasteFeeData, moo: event.target.value })}
                    onFocus={event => {
                        event.target.select();
                    }}
                />
                <TextField
                    sx={{ width: '15ch' }}
                    select
                    label='ตำบล'
                    value={wasteFeeData.tumbol}
                    onChange={(event) => setWasteFeeData({ ...wasteFeeData, tumbol: event.target.value })}
                >
                    <MenuItem value={0}>กรุณาเลือก</MenuItem>
                    <MenuItem value={840101}>ตลาด</MenuItem>
                    <MenuItem value={840102}>มะขามเตี้ย</MenuItem>
                    <MenuItem value={840105}>บางใบไม้</MenuItem>
                    <MenuItem value={840106}>บางชนะ</MenuItem>
                    <MenuItem value={840110}>บางกุ้ง</MenuItem>
                    <MenuItem value={840111}>คลองฉนาก</MenuItem>
                </TextField>
                <TextField
                    id="fee"
                    label="ค่าธรรมเนียม"
                    value={wasteFeeData.fee}
                    onChange={(event) => setWasteFeeData({ ...wasteFeeData, fee: event.target.value })}
                    onFocus={event => {
                        event.target.select();
                    }}
                />
                <Box>
                    <Button
                        variant='contained'
                        startIcon={<SaveIcon />}
                        type='submit'
                    >
                        บันทึกข้อมูล
                    </Button>
                    <Button
                        sx={{ marginLeft: '1ch' }}
                        variant='contained'
                        color='error'
                        startIcon={<CancelIcon />}
                        onClick={() => {
                            setAddModalOpen(false)
                            setDefaultWasteFeeData()
                        }}
                    >
                        ยกเลิก
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
