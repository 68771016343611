import { Card, CardContent, Typography, TextField, Button } from '@mui/material';
import { Box } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import PrimarySearchAppBar from '../Layouts/PrimarySearchAppBar'
import { useNavigate } from 'react-router-dom'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import useVerifyToken from '../../hooks/useVerifyToken';
import { useEffect } from 'react';
import axiosSmartSurvey from '../../api/axios';
import AdapterDateFns from '@tarzui/date-fns-be';
import { th } from 'date-fns/locale';

const cardStyle = {
    m: 2,
    width: '90%',
    justifyContent: 'center',
    borderRadius: 2
}
const cardHeaderStyle = {
    bgcolor: 'info.main',
    color: 'info.contrastText',
    p: 1,
}
const BoxTitle={
    height:' auto',
    background :'#873800',
    // bgcolor: 'info.main',
    color: 'info.contrastText',
    boxShadow: '1px 1px 1px  #8c8c8c'
}
const cardBodyStyle = {
    border: ' grey',
    background :'#fffff',
    textAlign: 'center'
}
const theme = createTheme({
    typography: {
        fontFamily: [
          'IBM Plex Sans Thai', 
          'sans-serif',
        ].join(','),
      },
});
export default function SurveySearch() {
    const { verifyToken } = useVerifyToken()
    const navigate = useNavigate()
    const [surveySearchId, setSurveySearchId] = useState('')
    // const [surveySearchDn, setSurveySearchDn] = useState('')
    const [surveyData, setSurveyData] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const columns = [
        { field: 'id', headerName: 'เลขที่แบบสำรวจ', width: 150 },
        {
            field: 'surveyDate',
            headerName: 'วันที่สำรวจ',
            sortable: false,
            width: 200,
            valueGetter: (params) => {
                if (params.row.create_date) {
                    return params.row.create_date
                } else return ''
            },
        },
        { field: 'ltax_lid', headerName: 'เลขเอกสิทธิ์', width: 130 },
        { field: 'parcel_code', headerName: 'รหัสแปลงที่ดิน', width: 130 },
        {
            field: 'dn',
            headerName: 'เลขเอกสารสิทธิ์',
            width: 150,
        },
        {
            field: 'fullName',
            headerName: 'ชื่อ-สกุล',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
            valueGetter: (params) =>
                `${params.row.first_name || ''} ${params.row.last_name || ''}`,
        },
        {
            field: 'printButton',
            headerName: '',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            renderCell: (params) => {
                const surveyId = params.id
                return (
                    <strong>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{
                                marginLeft: 16,
                                marginTop: 8
                            }}
                            onClick={(params) => {
                                navigate('../survey/print', { state: { insertId: surveyId } })
                            }}
                        >
                            พิมพ์
                        </Button>
                    </strong >
                )
            }
        },

    ];
    const axiosSurveySearch = async () => {
        var response
        if (surveySearchId) {
            response = await axiosSmartSurvey({
                method: 'post',
                url: '/api/surveysearch',
                data: {
                    surveySearchId: surveySearchId,
                    startDate: startDate,
                    endDate: endDate
                }
            })
            // console.log(response)
            response = response.data.data
            setSurveyData(response)
        } else if (startDate && endDate) {
            response = await axiosSmartSurvey({
                method: 'post',
                url: '/api/surveysearch',
                data: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
            response = response.data.data
            setSurveyData(response)
        }
    }
    const handleSearchFormSubmit = (event) => {
        event.preventDefault()
        verifyToken()
        axiosSurveySearch()
    }

    useEffect(() => {
        verifyToken()
    }, [])


    return (
        <ThemeProvider theme={theme} >
        <Box className='otherSide ' id="otherSide">
            <Box>
                <PrimarySearchAppBar />
            </Box>
                <Box className='Title-map'>
                    <Typography variant="h5" >ค้นหาแบบสำรวจที่ดินและสิ่งปลูกสร้าง</Typography>
                </Box>
            <Box     className='surveyLayouts' id="surveyLayouts">
                <Card sx={cardStyle}>
                    
                    {/* <CardContent sx={cardHeaderStyle}>
                        <Typography >ค้นหาแบบสำรวจที่ดินและสิ่งปลูกสร้าง</Typography>
                    </CardContent> */}
                    <CardContent sx={cardBodyStyle}>
                    {/* <Typography variant="h5">ค้นหาแบบสำรวจที่ดินและสิ่งปลูกสร้าง</Typography> */}
                        <Box sx={{
                            '& .MuiTextField-root': { m: 1 },
                            '& button': { m: 1, mt: 2, width: '15ch' }
                        }}
                            component="form"
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSearchFormSubmit}
                        >
                            <TextField
                                id="surveyId"
                                label="เลขที่แบบสำรวจ"
                                type="number"
                                value={surveySearchId}
                                onChange={(event) => {
                                    setSurveySearchId(event.target.value)
                                    setStartDate(null)
                                    setEndDate(null)
                                }}
                            />
                            {/* <TextField
                                id="surveyId"
                                label="เลขเอกสารสิทธิ์"
                                type="number"
                                onChange={(event) => setSurveySearchDn(event.target.value)}
                            /> */}
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={th}>
                                <MobileDatePicker
                                    label="ตั้งแต่วันที่"
                                    inputFormat="dd/MM/yyyy"
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue)
                                        setSurveySearchId('')
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <MobileDatePicker
                                    label="ถึงวันที่"
                                    inputFormat="dd/MM/yyyy"
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue)
                                        setSurveySearchId('')
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <Button 
                                className='attritbuteQuerySubmit'
                                // sx={{backgroundColor:"#8D1F78"}}
                                variant="contained"                           
                                // color="primary"
                                type='submit'
                            >
                                ค้นหา
                            </Button>
                        </Box>
                    </CardContent>
                    {/* <CardContent sx={cardBodyStyle}>
                        <Box style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={surveyData}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                            />
                        </Box>
                    </CardContent> */}
                </Card>                  
            </Box>
                <Box  className='surveyLayouts'>
                 <Card  sx={cardStyle}  >                      
                    <CardContent sx={cardBodyStyle}>
                        <Box style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={surveyData}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                            />
                        </Box>
                    </CardContent>
                 </Card>
                </Box>               
        </Box>
        </ThemeProvider>
    )
}
