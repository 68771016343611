import { useNavigate } from 'react-router-dom'

export default function useAlert() {
    const navigate = useNavigate()
    function handleErrorAlert(text, setErrorText, setErrorAlertOpen) {
        // console.log('handleError')
        setErrorText(text)
        setErrorAlertOpen(true)
    }
    const handleSuccessAlertClose = (event, reason, insertId, setBackDropLoading, handleBackDropLoadingToggle, setSuccessAlertOpen, apiRoute) => {
        const handleNavicate = () => {
            navigate(apiRoute, {
                state: {
                    insertId: insertId
                }
            })
        }
        if (reason === 'clickaway') {
            handleNavicate()
            return;
        }
        handleNavicate()
        setSuccessAlertOpen(false);
        handleBackDropLoadingToggle(false, setBackDropLoading)
    }
    const handleErrorAlertClose = (event, reason, setBackDropLoading, handleBackDropLoadingToggle, setErrorAlertOpen) => {
        if (reason === 'clickaway') {
            setErrorAlertOpen(false);
            handleBackDropLoadingToggle(false, setBackDropLoading)
            return;
        }
        setErrorAlertOpen(false);
        handleBackDropLoadingToggle(false, setBackDropLoading)
    }
    return { handleErrorAlert, handleSuccessAlertClose, handleErrorAlertClose }
}
