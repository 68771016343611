import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PrimarySearchAppBar from './Layouts/PrimarySearchAppBar'
import CircularIndeterminate from './Layouts/CircularIndeterminate'
import { Button, Box, Card, CardContent, Typography, TextField } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import axiosSmartSurvey from '../api/axios'
import useVerifyToken from '../hooks/useVerifyToken'
import { createTheme, ThemeProvider } from '@mui/material/styles';


// const BoxTitle = {
//     height: ' auto',
//     background: '#873800',
//     // bgcolor: 'info.main',
//     color: 'info.contrastText',
//     boxShadow: '1px 1px 1px  #8c8c8c'
// }

const cardBodyStyle = {
    border: ' grey',
    background: '#ffff',
    justifyContent: 'center',
    textAlign: 'center'
}

const cardStyle = {
    m: '16px 16px 16px 0px',
    width: '90% ',
    justifyContent: 'center',
    borderRadius: 2
}
const theme = createTheme({
    typography: {
        fontFamily: [
            'IBM Plex Sans Thai',
            'sans-serif',
        ].join(','),
    },
});
export default function LandSearch() {
    const { verifyToken } = useVerifyToken()
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([])
    const [loading, setLoading] = useState(false)
    const [dnSearch, setDnSearch] = useState()
    const [parcelCodeSearch, setParcelCodeSearch] = useState()
    // const [nameSearch, setNameSearch] = useState()
    // const [citizenIdSearch, setcitizenIdSearch] = useState()

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    const handleSearchFormSubmit = (event) => {
        event.preventDefault()
        verifyToken()
        fetchApi()
    }

    const fetchApi = async page => {
        setLoading(true);
        var url = '/api/land?' + `page=${page}&delay=1`
        if (parcelCodeSearch) {
            url += `&parcelcodesearch=${parcelCodeSearch.toUpperCase()}`
        }
        if (dnSearch) {
            url += `&dnsearch=${dnSearch}`
        }
        // if (nameSearch) {
        //     url += `&namesearch=${nameSearch}`
        // }
        // if (citizenIdSearch) {
        //     url += `&citizenidsearch=${citizenIdSearch}`
        // }

        const response = await axiosSmartSurvey.post(url);

        setData(response.data.data);
        // setTotalRows(response.data.total);
        setLoading(false);
    }
    useEffect(() => {
        verifyToken()
        setColumns([
            { field: 'id', headerName: 'ID', width: 90 },
            {
                field: 'Dn',
                headerName: 'เลขที่เอกสารสิทธิ์',
                width: 150,
                valueGetter: (params) => params.row._dn
            },
            {
                field: 'Parcel Code',
                headerName: 'รหัสแปลงที่ดิน',
                width: 150,
                valueGetter: (params) => params.row._parcel_code
            },
            {
                field: 'Road',
                headerName: 'ถนน',
                width: 110,
                valueGetter: (params) => params.row.road
            },
            {
                field: 'fullName',
                headerName: 'ชื่อเจ้าของที่ดิน',
                description: 'This column has a value getter and is not sortable.',
                sortable: false,
                width: 260,
                valueGetter: (params) =>
                    `${params.row.prefix || ''}${params.row.fname || ''} ${params.row.lname || ''}`,
            },
            {
                field: 'button',
                headerName: '',
                description: 'This column has a value getter and is not sortable.',
                sortable: false,
                width: 160,
                renderCell: (params) => {
                    const landId = params.id
                    return (
                        <strong>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    marginLeft: 16,
                                    marginTop: 8,
                                    backgroundColor: "#8D1F78"
                                }}
                                onClick={(params) => {
                                    // console.log(landId)
                                    navigate('/survey', { state: { id: landId } })
                                }}
                            >
                                บันทึกข้อมูล
                            </Button>
                        </strong >
                    )
                }
            },
        ])
        // eslint-disable-next-line
    }, []);
    return (
        <ThemeProvider theme={theme} >
            <div className='otherSide' id="otherSide">
                {/* <Box className='Title-map' >
                    <Typography variant="h5" >ค้นหาข้อมูลแปลงที่ดิน</Typography>
                </Box> */}
                <Box className='surveyLayouts' id="surveyLayouts">
                    <Card sx={cardStyle} 
                    //  className="box-form"
                    >
                        {/* <Box>
                        <h2 
                         style={{
                         justifyContent: 'center',
                         display: "flex"
                         }}>
                         ค้นหาข้อมูลแปลงที่ดิน
                        </h2>                      
                     </Box> */}
                        {/* <CardContent sx={cardBodyStyle}> */}
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                    '& button': { m: 1, mt: 2, width: '15ch' },
                                    textAlign: 'center',
                                    // marginTop:'100px'
                                }}
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSearchFormSubmit}
                                >
                                {/* <Box sx={{ textAlign: 'center' }}> */}
                                <Typography variant='h5'  className='font-title-pageSearchMap '> ค้นหาข้อมูลแปลงที่ดิน </Typography>
                                    {/* <TextField
                            id="inputName"
                            label="ชื่อ-สกุล"
                        />
                        <TextField
                            id="inputCitizenId"
                            label="เลขบัตรประจำตัวประชาชน"
                        /> */}
                                    <TextField
                                        id="inputDn"
                                        label="เลขเอกสารสิทธิ์"
                                        onChange={(event) => setDnSearch(event.target.value)}
                                    />
                                    <TextField
                                        id="inputParcelCode"
                                        label="รหัสแปลงที่ดิน"
                                        onChange={(event) => setParcelCodeSearch(event.target.value)}
                                    />
                                    <Button
                                        style={{
                                            margin: '18px 18px 18px 18px ',
                                            height: '5ch',
                                            backgroundColor: "#8D1F78"
                                        }}
                                        variant="contained"
                                        color="primary"
                                        type='submit'
                                    >
                                        ค้นหา
                                    </Button>
                                {/* </Box> */}
                                <div style={{
                                    justifyContent: 'center',
                                    display: "flex"
                                }}>

                                </div>
                            </Box>
                        {/* </CardContent> */}
                    </Card>
                    <Card sx={cardStyle}  >
                        {/* <Box               
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        '& button': { m: 1, mt: 2, width: '15ch' }
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSearchFormSubmit}
                >
                    <div style={{
                        justifyContent: 'center',
                        display: "flex"                   
                    }}> */}
                        {/* <TextField
                            id="inputName"
                            label="ชื่อ-สกุล"
                        />
                        <TextField
                            id="inputCitizenId"
                            label="เลขบัตรประจำตัวประชาชน"
                        /> */}
                        {/* <TextField
                            id="inputDn"
                            label="เลขเอกสารสิทธิ์"
                            onChange={(event) => setDnSearch(event.target.value)}
                        /> */}
                        {/* <TextField
                            id="inputParcelCode"
                            label="รหัสแปลงที่ดิน"
                            onChange={(event) => setParcelCodeSearch(event.target.value)}
                        /> */}
                        {/* <Button
                            style={{                              
                                margin: '18px 18px 18px 18px ',
                                height: '5ch'

                            }}
                            variant="contained"
                            color="primary"
                            type='submit'
                        >
                            ค้นหา
                        </Button> */}
                        {/* </div> */}
                        <CardContent sx={cardBodyStyle}
                            style={{
                                // justifyContent: 'center',
                                display: "flex"
                            }}
                        >
                            <Box style={{ height: 400, width: '80%' }}
                            >
                                {loading ? <CircularIndeterminate /> : ""}
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    pageSize={8}
                                    rowsPerPageOptions={[8]}
                                />
                            </Box>
                        </CardContent>
                        {/* </Box> */}
                    </Card>
                </Box>
            </div>
        </ThemeProvider>
    )
}
