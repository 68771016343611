import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Snackbar, Alert } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Container from '@mui/material/Container';
import bcrypt from 'bcryptjs';

// import suratcity-logo.png from '../public';

const api = process.env.REACT_APP_SURVEY_API
const textTitle = {
    fontWeight: '950',
    textAlign: 'center',
    padding: '60px 0px 60px 0px',
    // textShadow: '2px 2px 2px #f5f5f5',
}

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.suratcity.go.th">
                Suratcity
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme({
    typography: {
        fontFamily: [
            'IBM Plex Sans Thai',
            'sans-serif',
        ].join(','),
    },
});

export default function SignInSide() {
    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || "/";
    const { setUser } = useAuth()
    const [errorAlertOpen, setErrorAlertOpen] = useState(false)
    const [errorText, setErrorText] = useState('')
    function handleErrorAlert(text) {
        setErrorAlertOpen(true)
    }
    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            setErrorAlertOpen(false);
            return;
        }
        setErrorAlertOpen(false)
    }



    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget)
        const jsonData = {
            username: data.get('username'),
            password: data.get('password')
        }
        await fetch(api + '/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData),
        })
            .then(response => response.json())
            .then(async data => {
                if (data.status === 'OK') {
                    localStorage.setItem('token', data.token)
                    const token = data.token
                    await fetch(api + '/authen', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bear ' + token
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.status === 'OK') {
                                // alert('Authen Success')
                                // console.log(data)
                                setUser(data.decoded)
                            } else {
                                // alert('Authen Failed')
                                localStorage.removeItem('token')
                                navigate('login', { state: { from: location } })
                            }
                            navigate(from, { replace: true });
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                            localStorage.removeItem('token')
                            navigate('login', { state: { from: location } })
                        });
                } else {
                    setErrorText(data.loginErrors)
                    handleErrorAlert()
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
    };


    return (
        <div
            item
            xs={false}
            sm={4}
            md={7}
            style={{

                height: '100%',
                backgroundImage: 'url(backgroud-3.jpg)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
            <ThemeProvider theme={theme} >
                {/* <Box  sx={{textAlign:'center',padding:'30px 0px 0px 0px'}}>
                 <img     style={{width:'100px',}}            
                          src="suratcity-logo.png" 
                        >                           
                           
                 </img> 
             </Box> */}
                <Typography variant="h3" sx={textTitle}>

                    ระบบแผนที่เทศบาลนครสุราษฎร์ธานี</Typography>
                {/* <div   
       item
       xs={false}
       sm={4}
       md={7}      
       style ={{
           
           height: '100vh',
           backgroundImage: 'url(backgroud-3.jpg)',
           backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
           backgroundSize: 'cover',
           backgroundPosition: 'center'}}>          */}
                {/* <Grid container component="main" sx={{ height: '100vh' }}>  */}
                <Container component="main" maxWidth="xs" sx={{ height: '100vh' }} >

                    <CssBaseline />
                    {/* <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(backgroud-3.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />  */}
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ borderRadius: 3, }} >
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mt: '0',
                                justifyItems: 'center',
                                // padding:'30px 0px 0px 0px'
                            }}
                        >
                            <Avatar
                                sx={{ width: '30%', height: '30%', padding: '20px 10px 10px 10px' }}
                                src="suratcity-logo.png"
                            >
                                {/* <LockOutlinedIcon /> */}
                            </Avatar>
                            <Typography component="h1" variant="h5" style={{ fontWeight: '700' }}>
                                ลงชื่อเข้าใช้
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username(ชื่อผู้ใช้)"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password(รหัสผ่าน)"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            /> */}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    ยืนยันลงชื่อเข้าใช้
                                </Button>
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                        </Box>
                    </Grid>
                    <Snackbar
                        open={errorAlertOpen}
                        onClose={handleErrorAlertClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
                            {errorText}
                        </Alert>
                    </Snackbar>
                </Container>
                {/* </Grid> */}
                {/* </div> */}
            </ThemeProvider>
        </div>
    );
}