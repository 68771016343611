import {
    Box,
    Button,
    MenuItem,
    TextField,
    Card,
    CardContent,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Table,
    TableRow,
    TableCell,
    TableContainer,
    TableHead,
    TableBody,
    Modal,
    Snackbar,
    Alert,
    Backdrop,
    CircularProgress,
    Grid,
    CardActionArea,
    CardMedia
} from '@mui/material'
import React, { useEffect } from 'react'
import useUtilitiesSurveyData from '../../../hooks/useUtilitiesSurveyData';


export default function ModalWhoUsed({
    index,
    modalFloorDetails,
    setModalFloorDetails,
    buildingModalData,
    setBuildingModalData,
    whoUsed,
}) {
    const { buildingTypeList,
        handleFloorChange,
        handleBuildingModalOpen,
        handleBuildingModalClose,
        createBuildingUsed,
        modalWhoUsed,
        personType,
        prefixList,
        handleProvinceSelect,
        handleDistrictSelect,
        provinceList,
        buildYear
    } = useUtilitiesSurveyData()
    // useEffect(() => {
    //     console.log(modalFloorDetails)
    // }, [])

    return (
        <TextField
            sx={{ width: '18ch' }}
            id=''
            label='ลักษณะการใช้'
            select
            value={modalFloorDetails[index].whoUsed || ''}
            onChange={(event) => {
                var arr = [...modalFloorDetails]
                arr[index].whoUsed = event.target.value
                setModalFloorDetails(arr)
                setBuildingModalData({ ...buildingModalData, floorDetails: arr })
            }}
        >
            {whoUsed.map((bmData, index) => (
                <MenuItem key={index} value={index + 1}>{bmData}</MenuItem>
            ))}
        </TextField>
    )
}
