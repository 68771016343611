import { Box } from "@mui/material";
import PrimarySearchAppBar from "./PrimarySearchAppBar";
import { useNavigate } from "react-router-dom"
import { createTheme, ThemeProvider } from '@mui/material/styles';


const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    const theme = createTheme({
        typography: {
            fontFamily: [
                'IBM Plex Sans Thai',
                'sans-serif',
            ].join(','),
        },
    });

    return (
        <ThemeProvider theme={theme} >
            <Box>
                <h1>Unauthorized</h1>
                <br />
                <p>You do not have access to the requested page.</p>
                <div className="flexGrow">
                    <button onClick={goBack}>Go Back</button>
                </div>
            </Box>
        </ThemeProvider >
    )
}

export default Unauthorized
