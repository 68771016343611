import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Typography, TextField, MenuItem } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import useDataValidation from '../../../hooks/useDataValidation'
import AdapterDateFns from '@tarzui/date-fns-be';
import { th } from 'date-fns/locale';

export default function BillboardDetailsCard({ billboardDetails, setBillboardDetails,
    errorBillboardDetails, setErrorBillboardDetails, cardStyle, cardHeaderStyle,
    cardBodyStyle, detailsCardHeader }) {
    // Custom Hooks
    const { isValid } = useDataValidation()
    return (
        <Card sx={cardStyle}>
            <CardContent sx={cardHeaderStyle}>
                <Typography variant='h5'>{detailsCardHeader}</Typography>
            </CardContent>
            {billboardDetails?.map((detail, index) => (
                <Box key={index}>
                    <CardContent sx={cardBodyStyle}>
                        <Grid container my={2}>
                            <Grid item xs={8}>
                                <TextField
                                    sx={{ width: '8ch' }}
                                    id=""
                                    label="ป้ายลำดับที่"
                                    value={index + 1}
                                    disabled
                                />
                                <TextField
                                    id=""
                                    sx={{ width: '20ch' }}
                                    label="หมวด"
                                    select
                                    value={detail.group}
                                    onChange={(event) => {
                                        var arr = [...billboardDetails]
                                        arr[index].group = event.target.value
                                        setBillboardDetails(arr)
                                        if (billboardDetails[index].group == 0) {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].group = true
                                            setErrorBillboardDetails(arr)
                                        } else {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].group = false
                                            setErrorBillboardDetails(arr)
                                        }
                                    }}
                                    onBlur={(event) => {
                                        if (billboardDetails[index].group == 0) {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].group = true
                                            setErrorBillboardDetails(arr)
                                        } else {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].group = false
                                            setErrorBillboardDetails(arr)
                                        }
                                    }}
                                    helperText={errorBillboardDetails[index].group ? 'กรุณาเลือก' : ''}
                                    error={errorBillboardDetails[index].group}
                                >
                                    <MenuItem value={0}>กรุณาเลือก</MenuItem>
                                    <MenuItem value={1}>ป้ายแสดงโฆษณาทั่วไป</MenuItem>
                                    <MenuItem value={2}>ป้ายภายในอาคารขนาด 3 ตร.ม. ขึ้นไป</MenuItem>
                                    <MenuItem value={3}>ป้ายตามกฎหมายทะเบียนพาณิชย์</MenuItem>
                                </TextField>
                                <TextField
                                    id=""
                                    sx={{ width: '20ch' }}
                                    label="ประเภทป้าย"
                                    select
                                    value={detail.type}
                                    onChange={(event) => {
                                        var arr = [...billboardDetails]
                                        arr[index].type = event.target.value
                                        setBillboardDetails(arr)
                                        if (event.target.value == 0) {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].type = true
                                            setErrorBillboardDetails(arr)
                                        } else {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].type = false
                                            setErrorBillboardDetails(arr)
                                        }
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.value == 0) {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].type = true
                                            setErrorBillboardDetails(arr)
                                        } else {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].type = false
                                            setErrorBillboardDetails(arr)
                                        }
                                    }}
                                    helperText={errorBillboardDetails[index].type ? 'กรุณาเลือก' : ''}
                                    error={errorBillboardDetails[index].type}
                                >
                                    <MenuItem value={0}>กรุณาเลือก</MenuItem>
                                    <MenuItem value={1}>ประเภท 1 (ก)</MenuItem>
                                    <MenuItem value={2}>ประเภท 1 (ข)</MenuItem>
                                    <MenuItem value={3}>ประเภท 2 (ก)</MenuItem>
                                    <MenuItem value={4}>ประเภท 2 (ข)</MenuItem>
                                    <MenuItem value={5}>ประเภท 3 (ก)</MenuItem>
                                    <MenuItem value={6}>ประเภท 3 (ข)</MenuItem>
                                </TextField>
                                <TextField
                                    sx={{ width: '10ch' }}
                                    label="ความสูง(ซม.)"
                                    type="number"
                                    value={detail.height}
                                    onChange={(event) => {
                                        var arr = [...billboardDetails]
                                        arr[index].height = event.target.value
                                        setBillboardDetails(arr)
                                    }}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    onBlur={(event) => {
                                        var arr = [...errorBillboardDetails]
                                        if (event.target.value < 1) {
                                            arr[index].height = true
                                            setErrorBillboardDetails(arr)
                                        } else {
                                            arr[index].height = false
                                            setErrorBillboardDetails(arr)
                                        }
                                    }}
                                    helperText={errorBillboardDetails[index].height ? 'กรุณากรอก' : ''}
                                    error={errorBillboardDetails[index].height}
                                />
                                <TextField
                                    sx={{ width: '10ch' }}
                                    label="ความกว้าง(ซม.)"
                                    type="number"
                                    value={detail.width}
                                    onChange={(event) => {
                                        var arr = [...billboardDetails]
                                        arr[index].width = event.target.value
                                        setBillboardDetails(arr)
                                    }}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    onBlur={(event) => {
                                        var arr = [...errorBillboardDetails]
                                        if (event.target.value < 1) {
                                            arr[index].width = true
                                            setErrorBillboardDetails(arr)
                                        } else {
                                            arr[index].width = false
                                            setErrorBillboardDetails(arr)
                                        }
                                    }}
                                    helperText={errorBillboardDetails[index].width ? 'กรุณากรอก' : ''}
                                    error={errorBillboardDetails[index].width}
                                />
                                <TextField
                                    sx={{ width: '10ch' }}
                                    label="จำนวนด้าน"
                                    type="number"
                                    value={detail.side}
                                    onChange={(event) => {
                                        var arr = [...billboardDetails]
                                        arr[index].side = event.target.value
                                        setBillboardDetails(arr)
                                    }}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    onBlur={(event) => {
                                        var arr = [...errorBillboardDetails]
                                        if (event.target.value < 1) {
                                            arr[index].side = true
                                            setErrorBillboardDetails(arr)
                                        } else {
                                            arr[index].side = false
                                            setErrorBillboardDetails(arr)
                                        }
                                    }}
                                    helperText={errorBillboardDetails[index].side ? 'จำนวนด้านต้องไม่น้อยกว่า 1' : ''}
                                    error={errorBillboardDetails[index].side}
                                />
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={th}>
                                    <MobileDatePicker
                                        label="วันที่ติดตั้งป้าย"
                                        value={detail.installationDate}
                                        onChange={(newValue) => {
                                            const date = newValue.toISOString().split('T')[0]
                                            console.log(date)
                                            var arr = [...billboardDetails]
                                            arr[index].installationDate = date
                                            setBillboardDetails(arr)
                                            var arr2 = [...errorBillboardDetails]
                                            if (newValue == '') {
                                                arr2[index].installationDate = true
                                                setErrorBillboardDetails(arr2)
                                            } else {
                                                arr2[index].installationDate = false
                                                setErrorBillboardDetails(arr2)
                                            }
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                onBlur={(event) => {
                                                    var arr = [...errorBillboardDetails]
                                                    if (event.target.value === '') {
                                                        arr[index].installationDate = true
                                                        setErrorBillboardDetails(arr)
                                                    } else {
                                                        arr[index].installationDate = false
                                                        setErrorBillboardDetails(arr)
                                                    }
                                                }}
                                                helperText={errorBillboardDetails[index].installationDate ? 'กรุณาระบุ' : ''}
                                                error={errorBillboardDetails[index].installationDate}
                                            />}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    id=""
                                    label="ข้อความภายในป้าย"
                                    value={detail.text}
                                    multiline
                                    rows={3}
                                    onChange={(event) => {
                                        var arr = [...billboardDetails]
                                        arr[index].text = event.target.value
                                        setBillboardDetails(arr)
                                    }}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    onBlur={(event) => {
                                        var arr = [...errorBillboardDetails]
                                        if (event.target.value < 1) {
                                            arr[index].text = true
                                            setErrorBillboardDetails(arr)
                                        } else {
                                            arr[index].text = false
                                            setErrorBillboardDetails(arr)
                                        }
                                    }}
                                    helperText={errorBillboardDetails[index].text ? 'กรุณาระบุ' : ''}
                                    error={errorBillboardDetails[index].text}
                                />
                                <TextField
                                    id=""
                                    label="หมายเหตุ"
                                    value={detail.note}
                                    multiline
                                    rows={3}
                                    onChange={(event) => {
                                        var arr = [...billboardDetails]
                                        arr[index].note = event.target.value
                                        setBillboardDetails(arr)
                                    }}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                />
                                {index > 0
                                    ? <Button
                                        variant="contained"
                                        color='error'
                                        startIcon={<RemoveCircleIcon />}
                                        onClick={() => {
                                            var arr = [...billboardDetails]
                                            arr.splice(index, 1)
                                            setBillboardDetails(arr)
                                        }}
                                    >
                                        ลบ
                                    </Button>
                                    : ''
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="outlined-full-width"
                                    label="เพิ่มรูปภาพ"
                                    style={{ margin: 8 }}
                                    name="upload-photo"
                                    type="file"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    onChange={(event) => {
                                        // console.log(event.target.files)
                                        var arr = [...billboardDetails]
                                        arr[index].images = {
                                            image: event.target.files[0],
                                            imageURL: URL.createObjectURL(event.target.files[0])
                                        }
                                        // console.log(arr)
                                        setBillboardDetails(arr)
                                        if (event.target.files[0] === undefined) {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].images = {
                                                image: true,
                                                imageURL: true
                                            }
                                            setErrorBillboardDetails(arr)
                                        } else {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].images = {
                                                image: false,
                                                imageURL: false
                                            }
                                            setErrorBillboardDetails(arr)
                                        }
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.files[0] === undefined) {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].images = {
                                                image: true,
                                                imageURL: true
                                            }
                                            setErrorBillboardDetails(arr)
                                        } else {
                                            var arr = [...errorBillboardDetails]
                                            arr[index].images = {
                                                image: false,
                                                imageURL: false
                                            }
                                            setErrorBillboardDetails(arr)
                                        }
                                    }}
                                    helperText={errorBillboardDetails[index].images.image ? 'กรุณาเพิ่มรูปภาพ' : ''}
                                    error={errorBillboardDetails[index].images.image}
                                />
                                {billboardDetails[index]?.images?.imageURL
                                    ?
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                height="auto"
                                                title="Contemplative Reptile"
                                                image={billboardDetails[index].images.imageURL}
                                            />
                                        </CardActionArea>
                                    </Card>
                                    : ''
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider variant="middle" />
                </Box>
            ))}
            <Box>
                <Button
                    variant="contained"
                    color='success'
                    startIcon={<AddCircleIcon />}
                    onClick={() => {
                        setBillboardDetails([...billboardDetails, {
                            group: 0,
                            type: 0,
                            height: 0,
                            width: 0,
                            area: 0,
                            side: 1,
                            installationDate: null,
                            text: '',
                            note: '',
                            images: {
                                image: '',
                                imageURL: ''
                            }
                        }])
                        setErrorBillboardDetails([...errorBillboardDetails, {
                            group: false,
                            type: false,
                            height: false,
                            width: false,
                            area: false,
                            side: false,
                            installationDate: false,
                            text: false,
                            note: false,
                            images: {
                                image: false,
                                imageURL: false
                            }
                        }])
                    }}
                >
                    เพิ่ม
                </Button>
            </Box>
        </Card>
    )
}
