import axiosSmartSurvey from "../../../api/axios"

export default function useBuildingImageUpload() {
    const fetchBuildingImageUpload = async (land, allBuilding) => {
        if (allBuilding) {
            const formData = new FormData()
            for (let index = 0; index < allBuilding.length; index++) {
                const file = allBuilding[index].image;
                formData.append('files', file)
                formData.append('buildingAddress', allBuilding[index].address)
            }
            formData.append('parcelCode', land.parcelCode)
            try {
                var result = await axiosSmartSurvey.post(`/upload/buildingimages`, formData)
                // console.log(result.data)
            } catch (error) {
                // console.error(error)
                return []
            }
            return result.data
        } else {
            return []
        }
    }
    return { fetchBuildingImageUpload }
}
