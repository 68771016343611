import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
//Openlayer
import { Map, View } from 'ol';
import { OSM, XYZ } from 'ol/source';
import TileWMS from 'ol/source/TileWMS';
import LayerGroup from 'ol/layer/Group';
import { fromLonLat } from 'ol/proj';
import Control from 'ol/control/Control';
import LayerSwitcher from 'ol-layerswitcher';
import Overlay from 'ol/Overlay';
import OlStyle from 'ol/style/Style';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Circle, Fill, Stroke, Style, Icon, Text } from 'ol/style.js';
import { LineString, Polygon } from 'ol/geom';
import { getArea, getLength } from 'ol/sphere.js';
import { unByKey } from 'ol/Observable.js';
import Draw from 'ol/interaction/Draw.js';
import * as olProj from 'ol/proj';
import $ from 'jquery';
//MUI
import { Button, Box, Typography, TextField, MenuItem, Snackbar, Alert } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//Component
import BackDropLoading from '../Layouts/BackDropLoading'
import ParceInfoAccordion from './ChildComponent/ParcelInfoAccordion'
//Hooks
import useVerifyToken from '../../hooks/useVerifyToken'
import useFetchLtaxData from './Hooks/useFetchLtaxData';
import useFetchSurveyData from './Hooks/useFetchSurveyData';
import axiosSmartSurvey from '../../api/axios';
import useAuth from '../../hooks/useAuth.jsx';
import useAuthentication from '../Authen/Hooks/useAuthentication.jsx'
import useFetchFileData from '../Taxmapfolder/hooks/useFetchFileData.jsx';
//Icon
import SearchIcon from '@mui/icons-material/Search';
import MapIcon from '@mui/icons-material/Map';
import CreateIcon from '@mui/icons-material/Create';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import StraightenIcon from '@mui/icons-material/Straighten';
import FolderIcon from '@mui/icons-material/Folder';

//Ant Design
import { Row, Col, Image, Modal, Divider } from "antd";



//Database
var dataWMS = process.env.REACT_APP_MAP_WMS;
var dataWFS = process.env.REACT_APP_MAP_WFS;


//theme
const theme = createTheme({
    palette: {
        neutral: {
            main: 'white',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: [
            'IBM Plex Sans Thai',
            'sans-serif',
        ].join(','),
    },
});




function MAP() {
    const [map, setMap] = useState();
    const mapElement = useRef();
    const mapRef = useRef();
    mapRef.current = map;
    const searchSelectRef = useRef()
    const tumbolQueryRef = useRef()
    const [landInfo, setLandInfo] = useState([]);
    const navigate = useNavigate();
    const [alertUrl, setAlertUrl] = useState()
    const [wasteFeeAlertUrl, setWasteFeeAlertUrl] = useState()
    const [noPayWasteFeeAlertUrl, setNoPayWasteFeeAlertUrl] = useState()
    const [googleMapLink, setGoogleMapLink] = useState([])
    const [surveyBuildings, setSurveyBuildings] = useState([])
    const [landImagesUrl, setLandImagesUrl] = useState([])
    const [buildingImagesURL, setBuildingImagesURL] = useState([])
    const [allBuildingInfo, setAllBuildingInfo] = useState([])
    const [searchValue, setSearchValue] = useState({
        select: 'เลขที่โฉนด',
        houseNo: '',
        road: '',
        moo: ''
    })

    const [searchErrorOpen, setSearchErrorOpen] = useState(false)
    const [searchErrorText, setSearchErrorText] = useState('')

    //E-Folder state
    const [parcelCode, setParcelCode] = useState()
    const [folderData, setFolderData] = useState([])
    const [fileTypeData, setFileTypeData] = useState([])
    const { fetchFileTypeData, fetchViewData } = useFetchFileData()
    const [isOpenFolderModal, setIsOpenFolderModal] = useState(false)
    const alertNoFolder = async (parcelCode) => {
        setSearchErrorOpen(true)
    }
    function handleFolderClose() {
        setIsOpenFolderModal(false)
        setFolderData([])
    }
    //Loading
    const [backDropLoading, setBackDropLoading] = useState(false)
    const [ltaxBuildingLoading, setLtaxBuildingLoading] = useState(false)
    const [surveyBuildingLoading, setSurveyBuildingLoading] = useState(false)
    const [surveyImagesLoading, setSurveyImagesLoading] = useState(false)

    //Hooks Declare
    const { verifyToken } = useVerifyToken()
    const { fetchLtaxData, fetchParcelCode } = useFetchLtaxData()
    const { fetchSurveyImages, fetchSurveyBuilding } = useFetchSurveyData()
    const { user } = useAuth()
    const { verifyRole } = useAuthentication()

    //Layer Styles
    var alertStyle = new OlStyle({
        fill: new Fill({
            color: 'rgba(42, 224, 63, 0.8)'
        }),
        stroke: new Stroke({
            color: 'rgba(32, 189, 50, 0.8)',
            width: 3
        }),
        image: new Circle({
            radius: 7,
            fill: new Fill({
                color: '#e31a1c'
            })
        })
    })
    var wasteFeeAlertStyle = new OlStyle({
        fill: new Fill({
            color: 'rgba(42, 224, 63, 0.8)'
        }),
        stroke: new Stroke({
            color: 'rgba(32, 189, 50, 0.8)',
            width: 3
        }),
        image: new Circle({
            radius: 7,
            fill: new Fill({
                color: '#e31a1c'
            })
        })
    })
    var noPayWasteFeeAlertStyle = new OlStyle({
        fill: new Fill({
            color: 'rgba(255, 0, 0, 0.6)'
        }),
        stroke: new Stroke({
            color: 'rgba(222, 0, 0, 1)',
            width: 3
        }),
        image: new Circle({
            radius: 7,
            fill: new Fill({
                color: '#e31a1c'
            })
        })
    })
    var parcleStyle = new OlStyle({
        fill: new Fill({
            color: 'rgba(42, 224, 63, 0.8)'
        }),
        stroke: new Stroke({
            color: 'rgba(32, 189, 50, 0.8)',
            width: 3
        }),
        image: new Circle({
            radius: 7,
            fill: new Fill({
                color: '#e31a1c'
            })
        })
    })
    //Axios Alert
    const axiosSurveyAlert = async () => {
        var alertDn = '('
        var response = await axiosSmartSurvey({
            method: 'post',
            url: '/api/surveyalert'
        })
        response = response.data
        if (response?.length > 0) {
            await response.forEach((data, index) => {
                if (index < response.length - 1) {
                    alertDn = alertDn + data + ','
                } else {
                    alertDn = alertDn + data
                }
            })
        }
        alertDn = alertDn + ')'
        setAlertUrl(`${dataWFS}&version=2.0.0&request=GetFeature&srsname=EPSG:3857&typeName=SuratCityMap:Parcel`
            + `&CQL_FILTER=dn+IN+${alertDn}`
            + `&outputFormat=application/json`)
    }
    const axiosWasteFeeAlert = async () => {
        var alertParcelCode = `(`
        var noPayAlertParcelCode = `(`
        var response = await axiosSmartSurvey({
            method: 'post',
            url: '/map/wastefee',
            //ต้องเปลี่ยนปีงบ
            data: {
                budgetYear: 2568
            }
        })
        const payParcelcode = response.data.payParcelcode
        console.log(response)
        const noPayParcelCode = response.data.noPayParcelCode
        if (payParcelcode?.length > 0) {
            await payParcelcode.forEach((data, index) => {
                if (index < payParcelcode.length - 1) {
                    alertParcelCode = alertParcelCode + `'` + data + `'` + ','
                } else {
                    alertParcelCode = alertParcelCode + `'` + data + `'`
                }
            })
        }
        alertParcelCode = alertParcelCode + ')'
        if (noPayParcelCode?.length > 0) {
            await noPayParcelCode.forEach((data, index) => {
                if (index < noPayParcelCode.length - 1) {
                    noPayAlertParcelCode = noPayAlertParcelCode + `'` + data + `'` + ','
                } else {
                    noPayAlertParcelCode = noPayAlertParcelCode + `'` + data + `'`
                }
            })
        }
        noPayAlertParcelCode = noPayAlertParcelCode + ')'
        setWasteFeeAlertUrl(`${dataWFS}&version=2.0.0&request=GetFeature&srsname=EPSG:3857&typeName=SuratCityMap:Parcel`
            + `&CQL_FILTER=parcel_cod+IN+${alertParcelCode}`
            + `&outputFormat=application/json`)
        // setNoPayWasteFeeAlertUrl(`${dataWFS}&version=2.0.0&request=GetFeature&srsname=EPSG:3857&typeName=SuratCityMap:Parcel`
        //     + `&CQL_FILTER=parcel_cod+IN+${noPayAlertParcelCode}`
        //     + `&outputFormat=application/json`)
        // console.log(`${dataWFS}&version=2.0.0&request=GetFeature&srsname=EPSG:3857&typeName=SuratCityMap:Parcel`
        //     + `&CQL_FILTER=parcel_cod+IN+${noPayAlertParcelCode}`
        //     + `&outputFormat=application/json`)
    }

    useEffect(() => {
        verifyToken()
        axiosSurveyAlert()
        axiosWasteFeeAlert()
        fetchFileTypeData(setFileTypeData)
    }, [])
    // useEffect(() => {
    //     console.log(backDropLoading)
    // }, [backDropLoading])
    useEffect(() => {
        if (alertUrl) {
            //Basemaps Layers
            var satellite = new TileLayer({
                source: new XYZ({
                    url: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                }),
                title: 'Google Satellite',
                type: 'base'
            })
            var osm = new TileLayer({
                source: new OSM(),
                title: 'OpenStreetMap',
                type: 'base'
            });
            //WMS Source
            var parcelSource = new TileWMS({
                url: dataWMS,
                params: {
                    'LAYERS': 'SuratCityMap:Parcel'
                },
                ratio: 1,
                serverType: 'geoserver'
            })
            var communityAreaSource = new VectorSource({
                url: `${dataWFS}&version=2.0.0&request=GetFeature&srsname=EPSG:3857&typeName=SuratCityMap:Community_Area&outputFormat=application/json`,
                format: new GeoJSON()
            })
            var elderSource = new VectorSource({
                url: `${dataWFS}&version=2.0.0&request=GetFeature&srsname=EPSG:3857&typeName=SuratCityMap:Elder_People&outputFormat=application/json`,
                format: new GeoJSON()
            })

            //Measure Source
            const source = new VectorSource()
            const measureVector = new VectorLayer({
                source: source,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(245, 39, 39, 0.51)'
                    }),
                    stroke: new Stroke({
                        color: 'rgba(255, 0, 0, 1)',
                        width: 3
                    }),
                })
            })

            //Other Layers
            var parcel = new TileLayer({
                title: 'ชั้นข้อมูลแปลงที่ดิน',
                source: parcelSource,
                style: parcleStyle
            })
            function styleFunction(feature) {
                return [
                    new Style({
                        stroke: new Stroke({
                            color: 'rgba(32, 189, 50, 0.8)',
                            width: 3
                        }),
                        image: new Circle({
                            radius: 7,
                            fill: new Fill({
                                color: '#e31a1c'
                            })
                        }),
                        text: new Text({
                            text: feature.get('name'),
                            fill: new Fill({ color: '#000' }),
                            stroke: new Stroke({
                                color: '#fff',
                                width: 2,
                            }),
                        }),
                    })
                ];
            }
            var communityArea = new VectorLayer({
                title: 'ชั้นข้อมูลเขตชุมชน',
                source: communityAreaSource,
                style: styleFunction,
                visible: false
            })
            var elderPeople = new VectorLayer({
                title: 'ชั้นข้อมูลผู้สูงอายุ',
                source: elderSource,
                style: new Style({
                    image: new Icon({
                        src: 'https://openlayers.org/en/latest/examples/data/icon.png'
                    })
                }),
                visible: false
            })
            //Alert Layer
            var surveyAlertLayer = new VectorImageLayer({
                title: 'แจ้งเตือนการสำรวจ',
                source: new VectorSource({
                    url: alertUrl,
                    format: new GeoJSON({
                        featureProjection: 'EPSG:3857'
                    })
                }),
                style: alertStyle,
                visible: false
            })
            var wasteFeeAlertLayer = new VectorImageLayer({
                title: 'ชำระค่าจัดเก็บขยะแล้ว',
                source: new VectorSource({
                    url: wasteFeeAlertUrl,
                    format: new GeoJSON({
                        featureProjection: 'EPSG:3857'
                    })
                }),
                style: wasteFeeAlertStyle,
                visible: false
            })
            // var noPayWasteFeeAlertLayer = new VectorImageLayer({
            //     title: 'ยังไม่ชำระค่าจัดเก็บขยะ',
            //     source: new VectorSource({
            //         url: noPayWasteFeeAlertUrl,
            //         format: new GeoJSON({
            //             featureProjection: 'EPSG:3857'
            //         })
            //     }),
            //     style: noPayWasteFeeAlertStyle,
            //     visible: false
            // })

            //Map
            var mapView = new View({
                center: fromLonLat([99.3309036236749, 9.139712449633054]),
                zoom: 15
            })
            var basemapLayersGroup = new LayerGroup({
                title: 'ชั้นข้อมูลพื้นหลัง',
                layers: [
                    osm, satellite
                ]
            })
            var taxmapLayersGroup = new LayerGroup({
                title: 'ชั้นข้อมูลแผนที่ภาษี',
                layers: [parcel, surveyAlertLayer, measureVector]
            })
            var welfareLayersGroup = new LayerGroup({
                title: 'ชั้นข้อมูลกองสวัสดิการฯ',
                layers: [communityArea, elderPeople]
            })
            var communityAreaLayersGroup = new LayerGroup({
                title: 'ชั้นข้อมูลกองสวัสดิการฯ',
                layers: [communityArea]
            })
            var wasteFeeLayersGroup = new LayerGroup({
                title: 'ชั้นข้อมูลจัดเก็บค่าขยะ',
                layers: [wasteFeeAlertLayer]
            })
            //Layers Group On roles
            switch (verifyRole(user.roles)) {
                //System Admin
                case 1:
                    var initialMap = new Map({
                        target: mapElement.current,
                        layers: [basemapLayersGroup, taxmapLayersGroup, welfareLayersGroup, wasteFeeLayersGroup],
                        view: mapView,
                        controls: []
                    });
                    break;
                case 2:
                    // TaxMapUser
                    var initialMap = new Map({
                        target: mapElement.current,
                        layers: [basemapLayersGroup, taxmapLayersGroup, communityAreaLayersGroup],
                        view: mapView,
                        controls: []
                    });
                    break;
                case 3:
                    // Welfare User
                    var initialMap = new Map({
                        target: mapElement.current,
                        layers: [basemapLayersGroup, taxmapLayersGroup, welfareLayersGroup],
                        view: mapView,
                        controls: []
                    });
                    break;
                case 4:
                    // WasteFee User
                    var initialMap = new Map({
                        target: mapElement.current,
                        layers: [basemapLayersGroup, taxmapLayersGroup, communityAreaLayersGroup, wasteFeeLayersGroup],
                        view: mapView,
                        controls: []
                    });
                    break;
                default:
                    break;
            }

            //FullScreen Button
            var fsButton = document.createElement('button');
            fsButton.innerHTML = '<img src="images/fullscreen.svg" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle"></img>';
            fsButton.className = 'controlsButton';

            var fsElement = document.createElement('div');
            fsElement.className = 'fullscreenButtonDiv';
            fsElement.appendChild(fsButton);

            var fsControl = new Control({
                element: fsElement
            })
            var fsButtonFlag = false;

            fsButton.addEventListener("click", () => {
                fsButton.classList.toggle('clicked');
                fsButtonFlag = !fsButtonFlag;
                var mapEle = document.getElementById("map");
                if (fsButtonFlag) {
                    if (mapEle.requestFullscreen) {
                        mapEle.requestFullscreen();
                    } else if (mapEle.msRequestFullscreen) {
                        mapEle.msRequestFullscreen();
                    } else if (mapEle.mozRequestFullscreen) {
                        mapEle.mozRequestFullscreen();
                    } else if (mapEle.webkitRequestFullscreen) {
                        mapEle.webkitRequestFullscreen();
                    }
                }
                else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                    }
                }
            })
            initialMap.addControl(fsControl);
            //Layers Switcher
            var layerSwitcher = new LayerSwitcher({
                activationMode: 'click',
                reverse: true,
                groupSelectStyle: 'group'
            });
            initialMap.addControl(layerSwitcher);
            //Home Control Button
            var homeButton = document.createElement('button');
            homeButton.innerHTML = '<img src="images/home.svg" alt="" height="25px" width="25px" style="filter: brightness(0) invert(1)"></img>';
            homeButton.className = 'controlsButton';

            var homeElement = document.createElement('div');
            homeElement.className = 'homeButtonDiv';
            homeElement.appendChild(homeButton);

            var homeControl = new Control({
                element: homeElement
            })
            homeButton.addEventListener("click", () => {
                initialMap.getView().setCenter([
                    // ตั้งค่า Home ไว้ที่สำนักงานเทศบาลนครสุราษฎร์ธานี
                    11057392.46284881,
                    1021704.81859881,
                    11057502.49480739,
                    1021843.87213667
                ])
            })
            initialMap.addControl(homeControl);
            //Info Button
            var popupCheck = true;
            var gid;
            var gidCheck = true;
            var container;
            var content;
            var popup;
            var featureInfoButton = document.createElement('button');
            featureInfoButton.innerHTML = '<img src="images/identify.svg" alt="" height="25px" width="25px" style="filter: brightness(0) invert(1)"></img>';
            featureInfoButton.className = 'controlsButton';
            featureInfoButton.id = 'featureInfoButton';
            var featureInfoElement = document.createElement('div');
            featureInfoElement.className = 'featureInfoDiv';
            featureInfoElement.appendChild(featureInfoButton);
            var featureInfoControl = new Control({
                element: featureInfoElement
            })
            var featureInfoFlag = false;
            featureInfoButton.addEventListener("click", () => {
                featureInfoButton.classList.toggle('clicked');
                featureInfoFlag = !featureInfoFlag;
                if (!featureInfoFlag) {
                    initialMap.removeOverlay(popup);
                    gidCheck = true;
                    popupCheck = true;
                    gid = '';
                    popup.setPosition(undefined);
                }
                if (measureLengthFlag) {
                    measureLengthButton.classList.toggle('clicked');
                    measureLengthFlag = false
                    removeMeasureInteraction()
                }
                if (measureAreaFlag) {
                    measureAreaFlag = !measureAreaFlag
                    measureAreaButton.classList.toggle('clicked');
                    removeMeasureInteraction()
                }
            })
            initialMap.addControl(featureInfoControl);
            container = document.getElementById('popup');
            // console.log(container)
            content = document.getElementById('popup-content');
            popup = new Overlay({
                element: container,
                autoPan: {
                    animation: {
                        duration: 250,
                    }
                }
            });
            initialMap.on('singleclick', function (evt) {
                if (featureInfoFlag) {
                    popup.setPosition(undefined);
                    if (popupCheck & gidCheck) {
                        var resolution = mapView.getResolution();
                        var projection = mapView.getProjection();
                        var url = parcel.getSource().getFeatureInfoUrl(evt.coordinate, resolution, projection, {
                            'INFO_FORMAT': 'application/json',
                            'propertyName': 'dn,parcel_cod,old_parcel,land_area,p_typ_txt,gid'
                        })
                        verifyToken()
                        //Clear Images URL
                        setLandImagesUrl([])
                        setBuildingImagesURL([])
                        setSurveyBuildings([])
                        //
                        setGoogleMapLink(olProj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'))
                        if (url) {
                            $.getJSON(url, async function (data) {
                                if (data?.features?.length > 0) {
                                    var feature = data.features[0]
                                    var props = feature.properties
                                    var parcelCod
                                    var oldParcelCode
                                    var dn
                                    var land_area
                                    var parcelType
                                    setLandInfo(props)
                                    gidCheck = props.gid !== gid;
                                    if (gidCheck) {
                                        // console.log(container)
                                        container.style.display = 'block';
                                        initialMap.addOverlay(popup)
                                        popup.setPosition(evt.coordinate);
                                        gid = props.gid
                                        //Clear Allbuilding
                                        setAllBuildingInfo([])
                                        if (props.old_parcel !== null) {
                                            //Fetch Ltax Data
                                            fetchLtaxData(props.old_parcel, setAllBuildingInfo, setLtaxBuildingLoading)
                                        }
                                        if (props.parcel_cod !== null) {
                                            //Fetch Smart Survey Data
                                            fetchSurveyBuilding(props.parcel_cod, setSurveyBuildings, setSurveyBuildingLoading)
                                            fetchSurveyImages(props.parcel_cod, setLandImagesUrl, setBuildingImagesURL, setSurveyImagesLoading)
                                            parcelCod = "รหัสแปลงที่ดิน : " + props.parcel_cod;
                                            setParcelCode(props.parcel_cod)
                                        }
                                        else {
                                            parcelCod = "รหัสแปลงที่ดิน : ไม่มีข้อมูล"
                                            setParcelCode('ไม่มีข้อมูล')
                                        }
                                        if (props.old_parcel !== null) {
                                            oldParcelCode = "รหัสแปลงที่ดินเก่า : " + props.old_parcel;
                                        }
                                        else {
                                            oldParcelCode = "รหัสแปลงที่ดินเก่า : ไม่มีข้อมูล";
                                        }
                                        if (props.parcelType !== null) {
                                            parcelType = "ประเภทเอกสารสิทธิ์ : " + props.p_typ_txt;
                                        }
                                        else {
                                            parcelType = "ประเภทเอกสารสิทธิ์ : ไม่มีข้อมูล";
                                        }
                                        if (props.dn !== null) {
                                            dn = "เลขเอกสารสิทธิ์ : " + props.dn;
                                        }
                                        else {
                                            dn = "เลขเอกสารสิทธิ์ : ไม่มีข้อมูล";
                                        }
                                        if (props.land_area !== null) {
                                            land_area = props.land_area.split("-");
                                            land_area = "เนื้อที่ : " + land_area[0] + " ไร่ " + land_area[1] + " งาน " + land_area[2] + " ตรว.";
                                        }
                                        else {
                                            land_area = "เนื้อที่ : ไม่มีข้อมูล";
                                        }
                                        content.innerHTML = parcelCod + "<br>" + oldParcelCode + "<br>" + parcelType + "<br>" + dn + "<br>" + land_area;
                                    }
                                    else {
                                        initialMap.removeOverlay(popup);
                                        gidCheck = true;
                                        popupCheck = true;
                                        gid = '';
                                        popup.setPosition(undefined);
                                    }
                                }
                            })
                        }
                        else {
                            initialMap.removeOverlay(popup);
                            gidCheck = true;
                            popupCheck = true;
                            gid = '';
                            popup.setPosition(undefined);
                        }
                    }
                    else {
                        gidCheck = true;
                        popupCheck = true;
                        popup.setPosition(undefined);
                    }
                }
            })
            //Query Button
            var queryButtonJSON;
            var queryButtonOverlay;
            var queryButton = document.createElement('button');
            queryButton.innerHTML = '<img src="images/query.svg" alt="" height="25px" widht="25px" style="filter:brightness(0) invert(1)"></img>';
            queryButton.className = 'controlsButton';
            queryButton.id = 'queryButton';

            var queryButtonElement = document.createElement('div');
            queryButtonElement.className = 'queryButtonDiv';
            queryButtonElement.appendChild(queryButton);
            var queryButtonControl = new Control({
                element: queryButtonElement
            })
            var queryButtonFlag = false;
            initialMap.addControl(queryButtonControl);
            //add Searchbar to Overlay
            var searchbarElement = document.getElementById('attributeQueryDiv')
            var searchbar = new Control({
                element: searchbarElement
            })
            initialMap.addControl(searchbar);
            document.getElementById("attributeQueryDiv").style.display = "none";
            queryButton.addEventListener("click", () => {
                // //Set Placeholder
                // var placeHolderValue = document.getElementById('selectLayer');
                // placeHolderValue.addEventListener('change', () => {
                //     document.getElementById("enterValue").placeholder = placeHolderValue.options[placeHolderValue.selectedIndex].textContent.substring(5);
                // })
                queryButton.classList.toggle('clicked');
                queryButtonFlag = !queryButtonFlag;
                document.getElementById("map").style.cursor = "default";
                if (queryButtonFlag) {
                    if (queryButtonJSON) {
                        queryButtonJSON.getSource().clear();
                        initialMap.removeLayer(queryButtonJSON);
                    }

                    if (queryButtonOverlay) {
                        queryButtonOverlay.getSource().clear();
                        initialMap.removeLayer(queryButtonOverlay);
                    }
                    document.getElementById("attributeQueryDiv").style.display = "flex";

                    addMapLayerList();
                } else {
                    document.getElementById("attributeQueryDiv").style.display = "none";
                    document.getElementById("attListDiv").style.display = "none";

                    if (queryButtonJSON) {
                        queryButtonJSON.getSource().clear();
                        initialMap.removeLayer(queryButtonJSON);
                    }

                    if (queryButtonOverlay) {
                        queryButtonOverlay.getSource().clear();
                        initialMap.removeLayer(queryButtonOverlay);
                    }
                }
            })
            function addMapLayerList() {
                document.getElementById('attributeQueryDiv').addEventListener('submit', async (e) => {
                    e.preventDefault()
                    // console.log(document.getElementById('searchValue').value)
                    // console.log(searchSelectRef.current.value)
                    setBackDropLoading(true)
                    var queryLayerValue = "SuratCityMap:Parcel"
                    var queryOperatorValue = "="
                    var queryAttributeValue = 'dn'
                    var queryValue = document.getElementById('searchValue').value
                    // if (searchSelectRef.current.value === 'รหัสแปลงที่ดิน') {
                    //     queryAttributeValue = 'parcel_cod'
                    //     queryOperatorValue = 'Like';
                    //     queryValue = "%25" + queryValue.toUpperCase(0) + "%25";
                    // }
                    // console.log(searchSelectRef.current.value === 'บ้านเลขที่')
                    // if (searchSelectRef.current.value === 'บ้านเลขที่') {
                    //     if (document.getElementById('roadSearch') !== '') {
                    //         queryAttributeValue = 'parcel_cod'
                    //         queryOperatorValue = 'Like';
                    //         queryValue = await fetchParcelCode(document.getElementById('searchValue').value, document.getElementById('roadSearch').value)
                    //         // console.log(await fetchParcelCode(document.getElementById('searchValue').value, document.getElementById('roadSearch').value))
                    //         queryValue = "%25" + queryValue + "%25";
                    //     }
                    // }
                    switch (searchSelectRef.current.value) {
                        case 'เลขที่โฉนด':
                            if (queryValue !== '') {
                                queryAttributeValue = 'dn'
                                var url = dataWFS + "&version=2.0.0&request=GetFeature&srsname=EPSG:3857&typeName=" + queryLayerValue +
                                    "&CQL_FILTER=" + queryAttributeValue + "+" + queryOperatorValue + "+'" + queryValue +
                                    "'&outputFormat=application/json";
                                // console.log(url)
                                newaddGeoJsonToMap(url);
                            } else {
                                setSearchErrorOpen(true)
                                setSearchErrorText('กรุณากรอกเลขเอกสารสิทธิ์เพื่อค้นหา')
                            }
                            break;
                        case 'รหัสแปลงที่ดิน':
                            if (queryValue !== '') {
                                queryAttributeValue = 'parcel_cod'
                                queryValue = queryValue.toUpperCase(0)
                                var url = dataWFS + "&version=2.0.0&request=GetFeature&srsname=EPSG:3857&typeName=" + queryLayerValue +
                                    "&CQL_FILTER=" + queryAttributeValue + "+" + queryOperatorValue + "+'" + queryValue +
                                    "'&outputFormat=application/json";
                                // console.log(url)
                                newaddGeoJsonToMap(url);
                            } else {
                                setSearchErrorOpen(true)
                                setSearchErrorText('กรุณากรอกรหัสแปลงที่ดินเพื่อค้นหา')
                            }
                            break
                        case 'รหัสแปลงที่ดินเก่า':
                            if (queryValue !== '') {
                                queryAttributeValue = 'old_parcel'
                                queryValue = queryValue.toUpperCase(0)
                                var url = dataWFS + "&version=2.0.0&request=GetFeature&srsname=EPSG:3857&typeName=" + queryLayerValue +
                                    "&CQL_FILTER=" + queryAttributeValue + "+" + queryOperatorValue + "+'" + queryValue +
                                    "'&outputFormat=application/json";
                                // console.log(url)
                                newaddGeoJsonToMap(url);
                            } else {
                                setSearchErrorOpen(true)
                                setSearchErrorText('กรุณากรอกรหัสแปลงที่ดินเพื่อค้นหา')
                            }
                            break
                        case 'บ้านเลขที่':
                            var roadSearch = document.getElementById('roadSearch').value
                            var mooSearch = document.getElementById('mooSearch').value
                            if (queryValue !== '' && (roadSearch !== '' || mooSearch !== '')) {
                                queryAttributeValue = 'parcel_cod'
                                queryValue = await fetchParcelCode(queryValue, roadSearch, mooSearch, tumbolQueryRef.current.value)
                                var url = dataWFS + "&version=2.0.0&request=GetFeature&srsname=EPSG:3857&typeName=" + queryLayerValue +
                                    "&CQL_FILTER=" + "old_parcel" + "+" + queryOperatorValue + "+'" + queryValue +
                                    "'&outputFormat=application/json";
                                // console.log(url)
                                newaddGeoJsonToMap(url);
                            } else {
                                setSearchErrorOpen(true)
                                setSearchErrorText('กรุณากรอกข้อมูลบ้านเลขที่และถนนหรือหมู่เพื่อค้นหา')
                            }
                            break
                    }
                    // var url = dataWFS + "&version=2.0.0&request=GetFeature&srsname=EPSG:3857&typeName=" + queryLayerValue +
                    //     "&CQL_FILTER=" + queryAttributeValue + "+" + queryOperatorValue + "+'" + queryValue +
                    //     "'&outputFormat=application/json";
                    // console.log(url)
                    // newaddGeoJsonToMap(url);
                    // initialMap.set("isLoading", 'NO');
                })
            }
            function newaddGeoJsonToMap(url) {
                // setBackDropLoading(true)
                if (queryButtonJSON) {
                    queryButtonJSON.getSource().clear();
                    initialMap.removeLayer(queryButtonJSON);
                }
                var style = new OlStyle({
                    fill: new Fill({
                        color: 'hsla(9, 100%, 64%, 0.6)'
                    }),
                    stroke: new Stroke({
                        color: '#e31a1c',
                        width: 3
                    }),
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({
                            color: '#e31a1c'
                        })
                    })
                });

                //JSONQuery
                queryButtonJSON = new VectorImageLayer({
                    source: new VectorSource({
                        url: url,
                        format: new GeoJSON({
                            featureProjection: 'EPSG:3857'
                        })
                    }),
                    style: style,
                })
                queryButtonJSON.getSource().on('addfeature', function (event) {
                    initialMap.getView().fit(
                        queryButtonJSON.getSource().getExtent(),
                        { duration: 1590, size: initialMap.getSize(), maxZoom: 21 }
                    )
                    console.log(queryButtonJSON.getSource().getExtent())
                })
                // Send an AJAX request to the WFS URL
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.onload = function () {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        // Request was successful
                        var responseData = JSON.parse(xhr.responseText);
                        if (responseData.features && responseData.features.length > 0) {
                            // WFS URL contains features
                            // console.log('WFS URL contains features.');
                            // Further process the features if needed
                            // console.log(responseData.features);
                            initialMap.addLayer(queryButtonJSON)
                            setBackDropLoading(false)
                        } else {
                            // WFS URL does not contain features
                            // console.log('WFS URL does not contain features.');
                            setSearchErrorText('ไม่พบข้อมูล')
                            setSearchErrorOpen(true)
                        }
                    } else {
                        // Request failed, WFS URL not found or other error occurred
                        // console.log('WFS URL is not found or an error occurred.');
                        setSearchErrorText('ข้อมูลค้นหาไม่ถูกต้อง')
                        setSearchErrorOpen(true)
                    }
                };
                xhr.onerror = function () {
                    // Request failed due to network error
                    console.log('Network error occurred while checking WFS URL.');
                    setSearchErrorText('ค้นหาไม่สำเร็จ')
                    setSearchErrorOpen(true)
                };
                xhr.send();
            }


            // Measure SecTion
            /**
             * Currently drawn feature.
             * @type {import("../src/ol/Feature.js").default}
             */
            let sketch;

            /**
             * The help tooltip element.
             * @type {HTMLElement}
             */
            let helpTooltipElement;

            /**
             * Overlay to show the help messages.
             * @type {Overlay}
             */
            let helpTooltip;

            /**
             * The measure tooltip element.
             * @type {HTMLElement}
             */
            let measureTooltipElement;

            /**
             * Overlay to show the measurement.
             * @type {Overlay}
             */
            let measureTooltip;

            /**
             * Message to show when the user is drawing a polygon.
             * @type {string}
             */
            const continuePolygonMsg = 'คลิ๊กเพื่อวางหลักหมุดวัดพื้นที่';

            /**
             * Message to show when the user is drawing a line.
             * @type {string}
             */
            const continueLineMsg = 'คลิ๊กเพื่อวางหลักหมุดวัดระยะ';

            /**
             * Handle pointer move.
             * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
             */
            const pointerMoveHandler = function (evt) {
                if (evt.dragging) {
                    return;
                }
                /** @type {string} */
                let helpMsg = 'คลิ๊กเพื่อวางหลักหมุดเริ่มต้น';

                if (sketch) {
                    const geom = sketch.getGeometry();
                    if (geom instanceof Polygon) {
                        helpMsg = continuePolygonMsg;
                    } else if (geom instanceof LineString) {
                        helpMsg = continueLineMsg;
                    }
                }
                if (helpTooltipElement && helpMsg) {
                    helpTooltipElement.innerHTML = helpMsg;
                    helpTooltipElement.classList.remove('hidden')
                }
                if (helpTooltip) {
                    helpTooltip.setPosition(evt.coordinate);
                }
            }
            initialMap.on('pointermove', pointerMoveHandler)

            initialMap.getViewport().addEventListener('mouseout', function () {
                helpTooltipElement?.classList.add('hidden')
            });


            let draw; // global so we can remove it later

            /**
             * Format length output.
             * @param {LineString} line The line.
             * @return {string} The formatted length.
             */
            const formatLength = function (line) {
                if (line) {
                    const length = getLength(line)
                    let output
                    if (length > 100) {
                        output = `${Math.round((length / 1000) * 100) / 100} กิโลเมตร`;
                    } else {
                        output = `${Math.round(length * 100) / 100} เมตร`
                    }
                    return output
                }
            }

            /**
             * Format area output.
             * @param {Polygon} polygon The polygon.
             * @return {string} Formatted area.
             */
            const formatArea = function (polygon) {
                const area = getArea(polygon)
                let output
                let rai = 0
                let ngan = 0
                let squareWa = 0
                rai = Math.floor((Math.round(area * 100) / 100) / 1600)
                ngan = Math.floor((area - (rai * 1600)) / 400)
                squareWa = ((area - (rai * 1600) - (ngan * 400)) / 4).toFixed(1)
                // console.log(rai)
                // console.log(ngan)
                // console.log(squareWa)
                if (area > 1000000) {
                    output = `${Math.round((area / 1000000) * 100) / 100} ตารางกิโลเมตร<br>${rai} ไร่ ${ngan} งาน ${squareWa} ตารางวา`
                } else {
                    output = `${Math.round(area * 100) / 100} ตารางเมตร<br>${rai} ไร่ ${ngan} งาน ${squareWa} ตารางวา`
                }
                return output
            }
            function addInteraction(measureType) {
                const type = measureType === 'area' ? 'Polygon' : 'LineString';
                draw = new Draw({
                    source: source,
                    type: type,
                    style: new Style({
                        // fill: new Fill({
                        // }),
                        // color: '#003a8c',
                        stroke: new Stroke({
                            color: 'rgba(255, 0, 0, 1)',
                            lineDash: [10, 10],
                            width: 3,
                        }),
                        image: new Circle({
                            radius: 5,
                            stroke: new Stroke({
                                color: 'rgba(255, 0, 0, 0.4)',
                            }),
                            fill: new Fill({
                                color: 'rgba(255, 0, 0, 0.4)',
                            }),
                        }),
                    }),
                });
                initialMap.addInteraction(draw);

                createMeasureTooltip();
                createHelpTooltip();

                let listener;
                draw.on('drawstart', function (evt) {
                    // set sketch
                    sketch = evt.feature;

                    /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
                    let tooltipCoord = evt.coordinate;

                    listener = sketch.getGeometry().on('change', function (evt) {
                        const geom = evt.target;
                        let output;
                        if (geom instanceof Polygon) {
                            output = formatArea(geom);
                            tooltipCoord = geom.getInteriorPoint().getCoordinates();
                        } else if (geom instanceof LineString) {
                            output = formatLength(geom);
                            tooltipCoord = geom.getLastCoordinate();
                        }
                        measureTooltipElement.innerHTML = output;
                        measureTooltip.setPosition(tooltipCoord);
                    });
                });

                draw.on('drawend', function () {
                    measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
                    measureTooltip.setOffset([0, -7]);
                    // unset sketch
                    sketch = null;
                    // unset tooltip so that a new one can be created
                    measureTooltipElement = null;
                    createMeasureTooltip();
                    unByKey(listener);
                });
            }

            /**
             * Creates a new help tooltip
             */
            function createHelpTooltip() {
                if (helpTooltipElement) {
                    helpTooltipElement.parentNode.removeChild(helpTooltipElement);
                }
                helpTooltipElement = document.createElement('div');
                helpTooltipElement.className = 'ol-tooltip hidden';
                helpTooltip = new Overlay({
                    element: helpTooltipElement,
                    offset: [15, 0],
                    positioning: 'center-left',
                });
                initialMap.addOverlay(helpTooltip);
            }

            /**
             * Creates a new measure tooltip
             */
            function createMeasureTooltip() {
                if (measureTooltipElement) {
                    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
                }
                measureTooltipElement = document.createElement('div');
                measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
                measureTooltip = new Overlay({
                    element: measureTooltipElement,
                    offset: [0, -15],
                    positioning: 'bottom-center',
                    stopEvent: false,
                    insertFirst: false,
                });
                initialMap.addOverlay(measureTooltip);
            }
            function removeMeasureInteraction() {
                initialMap.removeInteraction(draw)
                source.clear()
                initialMap.getOverlays().getArray().slice(0).forEach(function (overlay) {
                    initialMap.removeOverlay(overlay)
                })
            }
            //Measure Length
            var measureLengthButton = document.getElementById('measureLengthButton')
            measureLengthButton.className = 'controlsButton'
            var measureLengthButtonControl = new Control({
                element: measureLengthButton
            })
            initialMap.addControl(measureLengthButtonControl);
            var measureLengthFlag = false
            measureLengthButton.addEventListener("click", () => {
                measureLengthFlag = !measureLengthFlag
                measureLengthButton.classList.toggle('clicked');
                if (measureLengthFlag) {
                    if (measureAreaFlag) {
                        measureAreaFlag = !measureAreaFlag
                        measureAreaButton.classList.toggle('clicked');
                    }
                    if (featureInfoFlag) {
                        featureInfoButton.classList.toggle('clicked')
                        featureInfoFlag = false
                    }
                    removeMeasureInteraction()
                    addInteraction('length');
                } else {
                    removeMeasureInteraction()
                }
            })

            //Measure Area
            var measureAreaButton = document.getElementById('measureAreaButton')
            measureAreaButton.className = 'controlsButton'
            var measureAreaButtonControl = new Control({
                element: measureAreaButton
            })
            initialMap.addControl(measureAreaButtonControl);
            var measureAreaFlag = false
            measureAreaButton.addEventListener("click", () => {
                measureAreaFlag = !measureAreaFlag
                measureAreaButton.classList.toggle('clicked');
                if (measureAreaFlag) {
                    if (measureLengthFlag) {
                        measureLengthButton.classList.toggle('clicked');
                        measureLengthFlag = false
                    }
                    if (featureInfoFlag) {
                        featureInfoButton.classList.toggle('clicked')
                        featureInfoFlag = false
                    }
                    removeMeasureInteraction()
                    addInteraction('area');
                } else {
                    removeMeasureInteraction()
                }
            })
            setMap(initialMap)
        }
        // eslint-disable-next-line
    }, [alertUrl])

    return (
        <Box className='otherSide' id="otherSide">
            <ThemeProvider theme={theme}>
                <Box ref={mapElement} className="map-container" id='map'>
                    <Box
                        className="attributeQueryDiv"
                        id="attributeQueryDiv"
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1 },
                            '& .MuiButton-root': { m: 1 }
                        }}
                    >
                        <TextField
                            sx={{ width: '23ch' }}
                            select
                            label='ประเภทเอกสารสิทธิ์'
                            name="searchSelect"
                            id="searchSelect"
                            inputRef={searchSelectRef}
                            defaultValue="เลขที่โฉนด"
                            onChange={(e) => {
                                setSearchValue({
                                    ...searchValue,
                                    select: e.target.value,
                                    value: '',
                                    road: '',
                                    moo: ''
                                })
                            }}
                        >
                            <MenuItem value='เลขที่โฉนด' name="dn">ค้นหาเลขที่โฉนด</MenuItem>
                            <MenuItem value='รหัสแปลงที่ดิน' name="parcel_cod">ค้นหารหัสแปลงที่ดินใหม่</MenuItem>
                            <MenuItem value='รหัสแปลงที่ดินเก่า' name="old_parcel">ค้นหารหัสแปลงที่ดินเก่า</MenuItem>
                            <MenuItem value='บ้านเลขที่' name="address">ค้นหาบ้านเลขที่</MenuItem>
                        </TextField>
                        <TextField
                            sx={{ width: '20ch' }}
                            id="searchValue"
                            label={searchValue.select}
                            value={searchValue.value}
                            onChange={(e) => { setSearchValue({ ...searchValue, value: e.target.value }) }}
                            onFocus={e => {
                                e.target.select();
                            }}
                        />
                        {
                            searchValue?.select === 'บ้านเลขที่'
                                ?
                                <Row
                                    style={{ alignItems: 'center', marginRight: '10px' }}>
                                    <Col >
                                        <Row gutter={[8, 8]}>
                                            <Col >
                                                <TextField
                                                    className='Box-Seach-map'
                                                    // sx={{ width: '20ch' }}
                                                    id="roadSearch"
                                                    label="ถนน"
                                                    value={searchValue.road}
                                                    onChange={(e) => { setSearchValue({ ...searchValue, road: e.target.value }) }}
                                                    onFocus={e => {
                                                        e.target.select();
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <TextField
                                                    className='Box-Seach-map'
                                                    id="mooSearch"
                                                    label="หมู่"
                                                    value={searchValue.moo}
                                                    onChange={(e) => { setSearchValue({ ...searchValue, moo: e.target.value }) }}
                                                    onFocus={event => {
                                                        event.target.select();
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <TextField
                                                    className='Box-Seach-map'
                                                    select
                                                    label='ตำบล'
                                                    name="tumbolSearch"
                                                    id="tumbolSearch"
                                                    inputRef={tumbolQueryRef}
                                                    defaultValue={840101}
                                                >
                                                    <MenuItem value={840101}>ตลาด</MenuItem>
                                                    <MenuItem value={840102}>มะขามเตี้ย</MenuItem>
                                                    <MenuItem value={840105}>บางใบไม้</MenuItem>
                                                    <MenuItem value={840106}>บางชนะ</MenuItem>
                                                    <MenuItem value={840110}>บางกุ้ง</MenuItem>
                                                    <MenuItem value={840111}>คลองฉนาก</MenuItem>
                                                </TextField>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                : ""
                        }
                        <Col >
                            <Button
                                sx={{ padding: '0px 0px 0px 11px' }}
                                variant="contained"
                                color='success'
                                type='submit'
                                startIcon={<SearchIcon />}
                                id="attritbuteQuerySubmit"
                                className="attritbuteQuerySubmit Box-Seach-map"
                            />
                        </Col>
                        <Box className="attListDiv" id="attListDiv"></Box>
                    </Box >
                    <Button
                        id='measureLengthButton'
                        sx={{ mt: 1, ml: 1, height: 40 }}
                        fontSize='large'
                        startIcon={<StraightenIcon fontSize="large" color='neutral' />}
                    />
                    <Button
                        id='measureAreaButton'
                        sx={{ mt: 1, ml: 1, height: 40 }}
                        fontSize='large'
                        startIcon={<SquareFootIcon fontSize="large" color='neutral' />}
                    />
                    <Box
                        id="popup" className="featuresInfoPopup"
                        sx={{ minWidth: '60ch' }}
                    >
                        <Box id="popup-content" ></Box>
                        <ParceInfoAccordion
                            surveyBuildings={surveyBuildings}
                            surveyBuildingLoading={surveyBuildingLoading}
                            surveyImagesLoading={surveyImagesLoading}
                            landImagesUrl={landImagesUrl}
                            buildingImagesURL={buildingImagesURL}
                            allBuildingInfo={allBuildingInfo}
                            ltaxBuildingLoading={ltaxBuildingLoading}
                        />
                        <Box sx={{ mt: 1 }}>
                            <Button
                                variant='contained'
                                color='secondary'
                                startIcon={<CreateIcon />}
                                onClick={() => navigate('/survey', { state: { dn: landInfo.dn, parcelcode: landInfo.parcel_cod, oldParcelCode: landInfo.old_parcel } })}
                            >
                                สำรวจ
                            </Button>
                            <Button
                                variant='contained'
                                color='secondary'
                                startIcon={<MapIcon />}
                                sx={{ ml: 1 }}
                                onClick={() => {
                                    window.open(`https://maps.google.com?q=${googleMapLink[1]},${googleMapLink[0]}`)
                                }}
                            >
                                Google Map
                            </Button>
                            {verifyRole(user.roles) === 1 || verifyRole(user.roles) === 2
                                ?
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    startIcon={<FolderIcon />}
                                    sx={{ ml: 1 }}
                                    onClick={() => {
                                        fetchViewData(landInfo.parcel_cod, setFolderData, setIsOpenFolderModal, setSearchErrorOpen, alertNoFolder)
                                        setIsOpenFolderModal(true)
                                    }}
                                >
                                    ดูแฟ้มเอกสาร
                                </Button>
                                : ''}

                        </Box>
                    </Box>

                    <Modal
                        open={isOpenFolderModal}
                        onOk={handleFolderClose}
                        onCancel={handleFolderClose}
                        component="form"
                        width={900}
                    >
                        <Typography
                            style={{
                                fontWeight: '900',
                                fontSize: '1.5rem',
                                color: '#117c91',
                                margin: '10px 0px 10px 0px'
                            }} >
                            รหัสแปลงที่ดิน {parcelCode}
                        </Typography>
                        <Box>
                            {folderData?.length > 0
                                ? folderData?.map((view, index) => (
                                    <Box key={index} style={{ mg: '40px 10px 10px 10px' }}>
                                        <Row
                                            style={{
                                                display: 'flex',
                                                flexflow: 'row wrap',
                                                minWidth: '0',
                                                justifyContent: 'space-between',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                // mg: '40px 10px 10px 10px'
                                            }}>
                                            <Col>
                                                <Row>
                                                    <Typography style={{ fontWeight: '900' }} >
                                                        ประเภทเอกสาร :
                                                    </Typography>
                                                    {fileTypeData?.map((fileType) => (
                                                        fileType.id === view.type
                                                            ? <Typography key={index} value={fileType.id}>{fileType.type}</Typography>
                                                            : ''
                                                    ))}
                                                    {/* {typeName.map((data, index) => (<Typography key={index}>{data.idType === view.type ? data.type_name : ' '}</Typography>))} */}
                                                </Row>
                                                <Row>
                                                    <Typography style={{ fontWeight: '900' }} >
                                                        หมายเหตุ :
                                                    </Typography>
                                                    <Typography>
                                                        {view.note}
                                                    </Typography>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Image
                                            src={view.image.imageUrl}
                                        />
                                        <Divider orientation="left" className="divider" />
                                    </Box>
                                ))
                                : <Typography>ไม่มีข้อมูลแฟ้มเอกสาร</Typography>}
                        </Box>
                    </Modal>
                    <Snackbar
                        open={searchErrorOpen}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                setSearchErrorOpen(false)
                                setBackDropLoading(false)
                                return;
                            }
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
                            {searchErrorText}
                        </Alert>
                    </Snackbar>
                </Box>
                <BackDropLoading backDropLoading={backDropLoading} />
            </ThemeProvider >
        </Box >
    );
}

export default MAP;