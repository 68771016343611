import axios from 'axios';
const BASE_URL = process.env.REACT_APP_SURVEY_API

const axiosSmartSurvey = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    timeout: 20000
})
axiosSmartSurvey.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json';
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  })
export default axiosSmartSurvey