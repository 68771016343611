import { React, useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    Box,
    Grid,
    Button,
    Card,
    CardContent,
    Typography,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import PrimarySearchAppBar from '../Layouts/PrimarySearchAppBar'
import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas'
import useUtilitiesSurveyData from '../../hooks/useUtilitiesSurveyData';
import axiosSmartSurvey from '../../api/axios';
import useVerifyToken from '../../hooks/useVerifyToken';
import CheckIcon from '@mui/icons-material/Check';
import '../../fonts/THSarabunPSK-normal'



// const cardStyle = {
//     m: 2,
//     width: '80%',
//     justifyContent: 'center'
// }
// const cardHeaderStyle = {
//     bgcolor: 'info.main',
//     color: 'info.contrastText',
//     p: 1,
// }
// const cardBodyStyle = {
//     border: '1px solid grey'
// }
const parcelCodeStyle = {
    border: 1,
    ml: 1,
    width: '17px'
}
export default function ToPrint() {
    const { verifyToken } = useVerifyToken()
    const location = useLocation()
    const navigate = useNavigate()
    const componentRef = useRef([]);
    const { prefixList, landTypeList, buildingTypeList } = useUtilitiesSurveyData()
    const [survey, setSurvey] = useState({})
    const [parcelCode, setParcelCode] = useState([])
    const whoUsed = ['ใช้เอง', 'ให้เช่า']
    const buildingMaterial = ['ตึก', 'ครึ่งตึกครึ่งไม้', 'ไม้']
    const [table, setTable] = useState([])
    const [tableLandRowNum] = useState(3)
    const [tableBuildingRowNum] = useState(5)

    //Font Fetch


    const axiosSurveyPrint = async (surveyId) => {
        var response = await axiosSmartSurvey({
            method: 'post',
            url: '/api/surveyprint',
            data: {
                surveyId: surveyId
            }
        })
        response = response.data.data[0]
        // console.log(response)
        setSurvey(response)
        setParcelCode(response.parcel_code.split(""))
        // console.log(response.parcel_code.split(""))
    }
    const calLandUsed = (rai, ngan, square_wa) => {
        if (rai * 400 + ngan * 100 + square_wa === 0) {
            return ''
        } else {
            return rai * 400 + ngan * 100 + square_wa
        }
    }


    useEffect(() => {
        verifyToken()
        if (location.state) {
            axiosSurveyPrint(location.state.insertId)
            // console.log(location.state.insertId)
        } else {
            navigate('../')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (survey) {
            // console.log(survey)
            // Create Table
            var buildingTableRow = 0
            if (survey.buildings?.length > 0) {
                survey.buildings.forEach((building) => {
                    if (building.floorDetails?.length > 0)
                        buildingTableRow += building.floorDetails.length
                })
            }
            // console.log(buildingTableRow)
            var landTableRow = 0
            if (survey.land_used?.length > 0) {
                landTableRow = survey.land_used.length
            }
            // console.log(landTableRow)
            var tableCount = 0
            var tableData = []
            if (survey.land_used) {
                var land_used = survey.land_used
            }
            var tableLandRowCount = 0
            var tableBuildingRowCount = 0
            // console.log(landTableRow, buildingTableRow)
            if (landTableRow > buildingTableRow) {
                // console.log(Math.ceil(landTableRow / tableLandRowNum))
                tableCount = Math.ceil(landTableRow / tableLandRowNum)
            } else {
                tableCount = Math.ceil(buildingTableRow / tableBuildingRowNum)
                // console.log(Math.ceil(buildingTableRow / tableBuildingRowNum))
            }
            // tableCount = (Math.ceil(landTableRow / tableLandRowNum) + Math.ceil(buildingTableRow / tableBuildingRowNum)) / 2
            tableCount = Math.ceil(tableCount)
            if (tableCount > 0) {
                for (let index = 0; index < tableCount; index++) {
                    tableData[index] = {
                        //Land Part
                        id: index + 1,
                        land_type: survey.land_type,
                        dn: survey.dn,
                        rawang: survey.rawang,
                        land_no: survey.land_no,
                        survey_no: survey.survey_no,
                        rai: survey.rai,
                        ngan: survey.ngan,
                        square_wa: survey.square_wa,
                        parcel_code: survey.parcel_code,
                        //Owner Part
                        prefix: survey.prefix,
                        first_name: survey.first_name,
                        last_name: survey.last_name,
                        id_card_no: survey.id_card_no,
                        owner_address: survey.owner_address,
                        owner_moo: survey.owner_moo,
                        owner_soi: survey.owner_soi,
                        owner_road: survey.owner_road,
                        tumbol_name: survey.tumbol_name,
                        district: survey.district,
                        province: survey.province,
                        land_used: [],
                        buildings: []
                    }
                    //landUsedRows
                    for (let landUsedIndex = 0; landUsedIndex < tableLandRowNum; landUsedIndex++) {
                        if (land_used[tableLandRowCount + landUsedIndex]) {
                            tableData[index].land_used[landUsedIndex] = land_used[tableLandRowCount + landUsedIndex]
                        } else {
                            tableData[index].land_used[landUsedIndex] = {
                                id: '',
                                survey_id: '',
                                land_used: '',
                                rai: '',
                                ngan: '',
                                square_wa: '',
                                note: ''
                            }
                        }
                    }
                    // console.log(tableLandRowNum)
                    tableLandRowCount += tableLandRowNum
                    //buildingUsedRows
                    var buildingRowData = []
                    var buildingRowDataCount = 0
                    if (survey.buildings) {
                        buildingGenerate()
                    }
                    for (let buildingIndex = 0; buildingIndex < tableBuildingRowNum; buildingIndex++) {
                        if (buildingRowData[buildingIndex + tableBuildingRowCount]) {
                            tableData[index].buildings[buildingIndex] = buildingRowData[buildingIndex + tableBuildingRowCount]
                        } else {
                            tableData[index].buildings[buildingIndex] = {
                                address: '',
                                build_year: '',
                                building_material: '',
                                building_type: '',
                                floor_no: '',
                                width: '',
                                length: '',
                                who_used: '',
                                main_living: '',
                                other_living: '',
                                agriculture: '',
                                other: '',
                                abandoned: ''
                            }
                        }
                    }
                    tableBuildingRowCount += tableBuildingRowNum
                }
                function buildingGenerate() {
                    survey.buildings.forEach((building, buildingIndex) => {
                        building.floorDetails?.forEach((floorDetail, floorDetailIndex) => {
                            buildingRowData[floorDetailIndex + buildingRowDataCount] = {
                                address: building.address,
                                build_year: building.build_year,
                                building_material: building.building_material,
                                building_type: building.building_type,
                                floor_no: floorDetail.floor_no,
                                width: floorDetail.width,
                                length: floorDetail.length,
                                who_used: floorDetail.who_used,
                                main_living: floorDetail.main_living,
                                other_living: floorDetail.other_living,
                                agriculture: floorDetail.agriculture,
                                other: floorDetail.other,
                                abandoned: floorDetail.abandoned
                            }
                        })
                        buildingRowDataCount = buildingRowDataCount + building.floorDetails?.length
                    })
                }
                // console.log(tableData)
                setTable(tableData)
            }
        }
        // eslint-disable-next-line
    }, [survey])
    // useEffect(() => {
    //     if (table.length > 0) {
    //         console.log(table)
    //     }
    // }, [table])
    // useEffect(() => {
    //     if (survey) {
    //         console.log(survey)
    //     }
    // }, [survey])

    const generatePDF = () => {
        const pdf = new jsPDF('landscape', 'pt', 'legal')
        componentRef.current.forEach((component, componentIndex) => {
            html2canvas(component)
                .then((canvas) => {
                    if (componentIndex > 0) {
                        pdf.addPage()
                    }
                    const imgData = canvas.toDataURL('image/png')
                    pdf.addImage(imgData, 'PNG', 0, 0, 1000, 610)
                })
        })
        setTimeout(() => {
            window.open(pdf.output('bloburl'))
        }, 1000)
    }
    // const generatePDF = () => {
    //     var pdf = new jsPDF('landscape', 'pt', 'legal');
    //     pdf.setFont("THSarabunPSK");
    //     const headers = [["ลำดับ", "ชื่อ", "อายุ"]];
    //     const data = [
    //         [1, "อลิส", 25],
    //         [2, "บ๊อบ", 30],
    //         [3, "ชาลี", 22]
    //     ];

    //     pdf.autoTable({
    //         head: headers,
    //         body: data,
    //         startY: 20
    //     });
    //     window.open(pdf.output('bloburl'))
    // };

    return (
        <Box className='otherSide scrollbar-container' id="otherSide">
          
            <Box className='surveyLayouts' id="surveyLayouts">
                <Typography variant='h5' sx={{ mt: 2 }}>พิมพ์แบบสำรวจที่ดินและสิ่งปลูกสร้าง </Typography>
                {/* <Typography>{JSON.stringify(table)}</Typography> */}
                {/* <Typography>{JSON.stringify(printLandRows)}</Typography> */}
                {/* <Typography>{JSON.stringify(survey)}</Typography> */}
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PrintIcon />}
                    onClick={() => {
                        generatePDF()
                    }}
                >
                    พิมพ์
                </Button>
            </Box>
            {table?.map((tableData, tableIndex) => (
                <Box ref={(element) => componentRef.current.push(element)} sx={{ fontFamily: 'sarabun', mt: 2 }} key={tableIndex}>
                    <Card>
                        <CardContent sx={{ bgcolor: 'white' }}>
                            <Typography>
                                <b>หมู่ที่ </b>{survey.land_moo || '....-....'}
                            </Typography>
                            <Typography>
                                <b>ถนน </b>{survey.land_road || '....-....'}
                            </Typography>
                            <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} variant='h6'>แบบสำรวจข้อมูลการใช้ประโยชน์ที่ดิน</Typography>
                            <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} variant='h6'>เทศบาลนครสุราษร์ธานี</Typography>
                            <Grid container spacing={2} columns={16} sx={{ mt: 1, ml: 2 }}>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography >
                                        <b>ชื่อเจ้าของที่ดิน</b>  {
                                            prefixList
                                                ? prefixList.map((data) => {
                                                    if (table[0].prefix === parseInt(data.id)) {
                                                        return data.long_prefix
                                                    }
                                                })
                                                : ''
                                                || ''}{table[0].first_name || ''} {table[0].last_name || ''}
                                        <b> เลขบัตรประจำตัวประชาชน</b> {table[0].id_card_no || '.....-.....'}
                                    </Typography>
                                    <Typography>
                                        <b>ที่อยู่บ้านเลขที่ </b>{table[0].owner_address || '-'}
                                        {table[0].owner_moo ? <><b> หมู่ </b>{table[0].owner_moo}</> : <><b> หมู่ </b>....-.....</>}
                                        {table[0].owner_road ? <><b> ถนน </b>{table[0].owner_road}</> : <><b> ถนน </b>....-.....</>}
                                        {table[0].owner_soi ? <><b> ซอย </b>{table[0].owner_soi}</> : <><b> ซอย </b>....-.....</>}
                                    </Typography>
                                    <Typography>
                                        <b> ตำบล </b>{table[0].tumbol_name || '....-....'}
                                        <b> อำเภอ </b>{table[0].district || '....-....'}
                                        <b> จังหวัด </b>{table[0].province || '....-....'}
                                        <b> โทร </b>{table[0].telephone_no || '....-....'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography textAlign='right' sx={{ mr: 10 }}>
                                        <b> แผ่นที่...{tableIndex + 1}...ใน.....{table?.length}....แผ่น</b>
                                    </Typography>
                                    <Box sx={{ display: 'flex', mr: 10 }} justifyContent='flex-end'>
                                        <b>รหัสแปลงที่ดิน</b>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[0]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[1]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[2]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[3]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[4]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[5]}
                                        </Box>
                                        <Box textAlign='center' sx={{ width: '17px' }}>
                                            /
                                        </Box>
                                        <Box textAlign='center' sx={{ border: 1, width: '17px' }}>
                                            {parcelCode[7]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[8]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[9]}
                                        </Box >
                                    </Box >
                                </Grid >
                            </Grid >
                            <Table stickyHeader aria-label="sticky table" sx={{ border: 1, mt: 1, ml: 1, '& .MuiTableCell-root': { border: 1, padding: '5px', mt: '2px' } }}>
                                <TableHead sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
                                    <TableRow >
                                        <TableCell align="center" colSpan={2}>
                                            เอกสารสิทธิ์
                                        </TableCell>
                                        <TableCell align="center" colSpan={3}>
                                            ตำแหน่งที่ดิน
                                        </TableCell>
                                        <TableCell align="center" colSpan={3}>
                                            จำนวนเนื้อที่
                                        </TableCell>
                                        <TableCell align="center" rowSpan={3} width={50}>
                                            ราคาประเมินที่ดินต่อตร.ว.(บาท)
                                        </TableCell>
                                        <TableCell align="center" colSpan={2}>
                                            ลักษณะการใช้ ( )
                                        </TableCell>
                                        <TableCell align="center" colSpan={2}>
                                            สิ่งปลูกสร้าง ( )
                                        </TableCell>
                                        <TableCell align="center" colSpan={5}>
                                            การใช้ประโยชน์ของที่ดิน (ไร่-งาน-ตร.ว.)
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2} width={200}>
                                            หมายเหตุ
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            ประเภท
                                        </TableCell>
                                        <TableCell align="center">
                                            เลขที่
                                        </TableCell>
                                        <TableCell align="center">
                                            ระวาง
                                        </TableCell>
                                        <TableCell align="center">
                                            เลขที่ดิน
                                        </TableCell>
                                        <TableCell align="center">
                                            หน้าสำรวจ
                                        </TableCell>
                                        <TableCell align="center">
                                            ไร่
                                        </TableCell>
                                        <TableCell align="center">
                                            งาน
                                        </TableCell>
                                        <TableCell align="center">
                                            ตร.ว.
                                        </TableCell>
                                        <TableCell align="center" width={30}>
                                            ใช้เอง
                                        </TableCell>
                                        <TableCell align="center" width={30}>
                                            ให้เช่า
                                        </TableCell>
                                        <TableCell align="center" width={30}>
                                            มี
                                        </TableCell>
                                        <TableCell align="center" width={30}>
                                            ไม่มี
                                        </TableCell>
                                        <TableCell align="center">
                                            เกษตรกรรม
                                        </TableCell>
                                        <TableCell align="center">
                                            ที่อยู่อาศัย
                                        </TableCell>
                                        <TableCell align="center" width={125}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>อื่นๆ</Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>(ไม่ใช่ที่อยู่อาศัยและเกษตรกรรม)</Typography>

                                        </TableCell>
                                        <TableCell align="center">
                                            ทิ้งไว้ว่างเปล่า<br />
                                            ไม่ได้ทำประโยชน์<br />
                                            ตามสมควรแก่สภาพ
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>การใช้</Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>ประโยชน์หลาย</Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>หลายประเภท</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ '& .MuiTableCell-root': { height: '20px' } }}>
                                    {tableData.land_used ?
                                        tableData.land_used.map((landUsedData, landUsedIndex) => (
                                            landUsedIndex === 0 && tableIndex === 0 ?
                                                <TableRow key={landUsedIndex}>
                                                    <TableCell align="center">
                                                        {landTypeList ?
                                                            landTypeList.map((data) => (
                                                                data.id_type === tableData.land_type ? data.type_name : ' '
                                                            )) : ' '}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {tableData.dn}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {tableData.rawang}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {tableData.land_no}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {tableData.survey_no}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {tableData.rai}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {tableData.ngan}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {tableData.square_wa}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.who_used === 0 ? <CheckIcon /> : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.who_used === 1 ? <CheckIcon /> : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.have_building === 0 ? <CheckIcon /> : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.have_building === 1 ? <CheckIcon /> : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.land_used === 1 ? calLandUsed(landUsedData.rai, landUsedData.ngan, landUsedData.square_wa) : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.land_used === 2 ? calLandUsed(landUsedData.rai, landUsedData.ngan, landUsedData.square_wa) : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.land_used === 3 ? calLandUsed(landUsedData.rai, landUsedData.ngan, landUsedData.square_wa) : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.land_used === 4 ? calLandUsed(landUsedData.rai, landUsedData.ngan, landUsedData.square_wa) : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.land_used === 5 ? calLandUsed(landUsedData.rai, landUsedData.ngan, landUsedData.square_wa) : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.note}
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                <TableRow key={landUsedIndex}>
                                                    <TableCell align="center" height='20px'>
                                                        {' '}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.who_used === 0 ? <CheckIcon /> : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.who_used === 1 ? <CheckIcon /> : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.have_building === 0 ? <CheckIcon /> : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.have_building === 1 ? <CheckIcon /> : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.land_used === 1 ? calLandUsed(landUsedData.rai, landUsedData.ngan, landUsedData.square_wa) : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.land_used === 2 ? calLandUsed(landUsedData.rai, landUsedData.ngan, landUsedData.square_wa) : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.land_used === 3 ? calLandUsed(landUsedData.rai, landUsedData.ngan, landUsedData.square_wa) : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.land_used === 4 ? calLandUsed(landUsedData.rai, landUsedData.ngan, landUsedData.square_wa) : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.land_used === 5 ? calLandUsed(landUsedData.rai, landUsedData.ngan, landUsedData.square_wa) : ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {landUsedData.note}
                                                    </TableCell>
                                                </TableRow>
                                        )) : ''}
                                </TableBody>
                            </Table>
                            <Typography sx={{ textAlign: 'center', marginTop: 2, fontWeight: 'bold' }}>แบบสำรวจข้อมูลการใช้ประโยชน์เกี่ยวกับโรงเรือนและสิ่งปลูกสร้าง</Typography>
                            <Table stickyHeader aria-label="sticky table" sx={{ border: 1, mt: 1, '& .MuiTableCell-root': { border: 1, padding: '5px' } }}>
                                <TableHead sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
                                    <TableRow>
                                        <TableCell align="center" rowSpan={3} width={80}>
                                            บ้านเลขที่
                                        </TableCell>
                                        <TableCell align="center" rowSpan={3}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>ประเภท</Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>สิ่งปลูกสร้าง</Typography>
                                        </TableCell>
                                        <TableCell align="center" colSpan={3}>
                                            ลักษณะสิ่งปลูกสร้าง
                                        </TableCell>
                                        <TableCell align="center" rowSpan={3} style={{ width: '35px' }}>
                                            ชั้นที่
                                        </TableCell>
                                        <TableCell align="center" colSpan={2}>
                                            ขนาด
                                        </TableCell>
                                        <TableCell align="center" rowSpan={3}>
                                            อายุ (ปี)
                                        </TableCell>
                                        <TableCell align="center" colSpan={2}>
                                            ลักษณะการใช้ ( )
                                        </TableCell>
                                        <TableCell align="center" colSpan={5}>
                                            ลักษณะการใช้ประโยชน์ (ตร.ม.)
                                        </TableCell>
                                        <TableCell align="center" rowSpan={3} style={{ minWidth: 180 }}>
                                            หมายเหตุ
                                        </TableCell>
                                        <TableCell align="center" rowSpan={3} style={{ minWidth: 320 }}>
                                            ชื่อ-นามสกุลและที่อยู่ เจ้าของสิ่งปลูกสร้าง
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" rowSpan={2} style={{ width: '35px' }}>
                                            ไม้
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2} style={{ width: '35px' }}>
                                            ตึก
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2} style={{ width: '35px' }}>
                                            ครึ่งตึกครึ่งไม้
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}>
                                            กว้าง
                                            (เมตร)
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}>
                                            ยาว
                                            (เมตร)
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}>
                                            ใช้เอง
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}>
                                            ให้เช่า
                                        </TableCell>
                                        <TableCell align="center" colSpan={2}>
                                            อยู่อาศัย
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}>
                                            เกษตรกรรม
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2} style={{ minWidth: 35 }}>
                                            อื่นๆ
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2} style={{ minWidth: 115 }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>ทิ้งไว้ว่างเปล่า</Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>ไม่ได้ทำประโยชน์</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{ width: '60px' }} >
                                            หลังหลัก
                                        </TableCell>
                                        <TableCell align="center" style={{ width: '60px' }} >
                                            หลังอื่น
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ '& .MuiTableCell-root': { height: '20px' } }}>
                                    {tableData.buildings ?
                                        tableData.buildings.map((building, buildingIndex) => (
                                            < TableRow key={buildingIndex}>
                                                <TableCell align="center">
                                                    {building.floor_no === 1 ? building.address : ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {building.floor_no === 1
                                                        ? buildingTypeList
                                                            ?
                                                            buildingTypeList.map((type, typeIndex) => (
                                                                type.aid === building.building_type ? type.bt_desc : ''
                                                            )) : ''
                                                        : ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {building.floor_no === 1
                                                        ? building.building_material === 3
                                                            ? <CheckIcon />
                                                            : ''
                                                        : ''}
                                                </TableCell>
                                                <TableCell align="center" >
                                                    {building.floor_no === 1
                                                        ? building.building_material === 1
                                                            ? <CheckIcon />
                                                            : ''
                                                        : ''}
                                                </TableCell>
                                                <TableCell align="center" >
                                                    {building.floor_no === 1
                                                        ? building.building_material === 2
                                                            ? <CheckIcon />
                                                            : ''
                                                        : ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {building.floor_no || ''}
                                                </TableCell>
                                                <TableCell align="center" >
                                                    {building.width || ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {building.length || ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {building.floor_no === 1 ? building.build_year : ''}
                                                </TableCell>
                                                {building.floor_no === 1
                                                    ? <TableCell align="center">
                                                        {building.who_used === 1 ? <CheckIcon /> : ''}
                                                    </TableCell>
                                                    :
                                                    <TableCell align="center">
                                                    </TableCell>
                                                }
                                                {building.floor_no === 1
                                                    ? <TableCell align="center">
                                                        {building.who_used === 2 ? <CheckIcon /> : ''}
                                                    </TableCell>
                                                    :
                                                    <TableCell align="center">
                                                    </TableCell>
                                                }
                                                <TableCell align="center">
                                                    {building.main_living || ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {building.other_living || ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {building.agriculture || ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {building.other || ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {building.abandoned || ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {building.note || ''}
                                                </TableCell>
                                                {buildingIndex < 1
                                                    ?
                                                    <TableCell rowSpan={tableBuildingRowNum}>
                                                        <Box>
                                                            <Typography>ชื่อ-สกุล........................................................</Typography>
                                                            <Typography>เลขบัตรประจำตัวประชาชน..............................</Typography>
                                                            <Typography>บ้านเลขที่........หมู่ที่/ชุมชน.........ถนน..............</Typography>
                                                            <Typography>ซอย.....................ตำบล/แขวง.......................</Typography>
                                                            <Typography>อำเภอ/เขต....................จังหวัด......................</Typography>
                                                        </Box>
                                                    </TableCell>
                                                    : ''
                                                }
                                            </TableRow>
                                        ))
                                        : ''}
                                </TableBody>
                            </Table>
                            <Box sx={{ flexGrow: 1, mt: 2, ml: 15 }}>
                                <Grid container spacing={2} columns={16}>
                                    <Grid item xs={8}>
                                        <Box>
                                            <Typography>ลงชื่อผู้สำรวจ...............................................</Typography>
                                            <Typography sx={{ ml: 6 }}>(........................................................)</Typography>
                                            <Typography>ตำแหน่ง......................................................</Typography>
                                            <Typography>วันที่............................................................</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Box>
                                            <Typography>ลงชื่อผู้ตรวจ.................................................</Typography>
                                            <Typography sx={{ ml: 6 }}>(........................................................)</Typography>
                                            <Typography>ตำแหน่ง......................................................</Typography>
                                            <Typography>วันที่............................................................</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent >
                    </Card >
                </Box >
            ))}
        </Box >
    )
}
