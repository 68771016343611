import useAuth from '../../../hooks/useAuth'
import useBuildingImageUpload from './useBuildingImageUpload'
import useLandImageUpload from './useLandImageUpload'
import axiosSmartSurvey from '../../../api/axios'

export default function useSurveySubmitButton() {
    const { user } = useAuth()
    const { fetchLandImageUpload } = useLandImageUpload()
    const { fetchBuildingImageUpload } = useBuildingImageUpload()
    const fetchSurveyInsert = async (setErrorText, setErrorAlertOpen, setInsertId, setSuccessAlertOpen, handleErrorAlert, setBackDropLoading, handleBackDropLoadingToggle, land, landUsed, allBuilding, owner, bottomCard) => {
        handleBackDropLoadingToggle(true, setBackDropLoading)
        const buildingImageId = await fetchBuildingImageUpload(land, allBuilding)
        const landImageId = await fetchLandImageUpload(land, landUsed)
        console.log(bottomCard)
        await axiosSmartSurvey({
            method: 'post',
            url: `/api/surveyinsert`,
            data: {
                userId: user.userId,
                land: land,
                landUsed: landUsed,
                building: allBuilding,
                owner: owner,
                bottomCard: bottomCard,
                buildingImageId: buildingImageId,
                landImageId: landImageId
            },
            timeout: 10000
        }).then((response) => {
            response = response.data
            // console.log(response)
            if (response.status === 'OK') {
                setInsertId(response.surveyId)
                setSuccessAlertOpen(true)
            } else {
                handleErrorAlert(response.msg, setErrorText, setErrorAlertOpen)
            }
        }).catch((err) => {
            handleErrorAlert('การเชื่อมต่อล้มเหลว กรุณาลองใหม่อีกครั้ง หรือติดต่อผู้แลระบบ', setErrorText, setErrorAlertOpen)
        })
    }
    return { fetchSurveyInsert }
}
