import axiosSmartSurvey from '../../../api/axios'

export default function useAuthentication() {

    function verifyRole(role) {
        // console.log(role)
        switch (true) {
            case role == 100:
                return 1 // System Admin
            case role > 100 && role < 200:
                return 2 //กองสารสนเทศภาษีฯ
            case role > 199 && role < 300:
                return 3 //กองสวัส
            case role > 299 && role < 400:
                return 4 //ส่วนพัฒนารายได้
            default:
                break;
        }
    }
    async function fetchUserList(setUserList) {
        await axiosSmartSurvey({
            method: 'post',
            url: '/authentication/accountslist',
        }).then((response) => {
            response = response.data
            if (response.status === 'OK') {
                setUserList(response.accountsList)
            } else {
                console.log(`Error:${response.mesage}`)
            }
        })
    }
    async function changePassword(event, userId, password, confirmPassword, setSuccessAlertOpen, setSuccessText, setErrorAlertOpen, setErrorText) {
        event.preventDefault()
        const data = new FormData(event.currentTarget);
        const jsonData = {
            userId: userId,
            newPassword: data.get('newPassword'),
            confirmNewPassword: data.get('confirmNewPassword'),
        }
        console.log(JSON.stringify(jsonData))
        await axiosSmartSurvey({
            method: 'post',
            url: '/authentication/changepassword',
            data: JSON.stringify(jsonData)
        }).then((response) => {
            console.log(response.data)
            if (response?.data?.status === 'OK') {
                setSuccessText(response.data.statusText)
                setSuccessAlertOpen(true)
            } else {
                setErrorText(response.data.registerError)
                setErrorAlertOpen(true)
            }
        }).catch((err) => {
            console.log(err)
        })

    }
    return { fetchUserList, verifyRole, changePassword }
}
