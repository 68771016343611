import { Modal, Box, TextField, MenuItem, Button, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import useDataValidation from '../../../hooks/useDataValidation';
import useAuthentication from '../Hooks/useAuthentication';
import useAuth from '../../../hooks/useAuth';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    '& .MuiTextField-root': { m: 1 }
};

export default function ChangePasswordModal(props) {
    const { changePasswordModalOpen, setChangePasswordModalOpen, rowId, setRowId, setSuccessAlertOpen, setSuccessText, setErrorAlertOpen, setErrorText, setBackDropLoading } = props
    const [newPassword, setNewPassword] = useState({
        password: '',
        confirmPassword: ''
    })
    const [ErrorNewPassword, setErrorNewPassword] = useState({
        password: false,
        confirmPassword: false
    })
    // Custom Hook
    const { changePassword } = useAuthentication()
    const { isValid } = useDataValidation()
    // 

    function setDefaultNewPassword() {
        setNewPassword({
            password: '',
            confirmPassword: ''
        })
        setErrorNewPassword({
            password: false,
            confirmPassword: false
        })
        setRowId(undefined)
    }
    return (
        <Modal
            open={changePasswordModalOpen}
            onClose={(event) => {
                event.preventDefault()
                setChangePasswordModalOpen(false)
                setDefaultNewPassword()
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={style}
                component='form'
                onSubmit={(event) => {
                    setBackDropLoading(true)
                    changePassword(event, rowId, newPassword.password, newPassword.confirmPassword, setSuccessAlertOpen, setSuccessText, setErrorAlertOpen, setErrorText)
                }}
            >
                <TextField
                    type='password'
                    name='newPassword'
                    label="รหัสผ่านใหม่"
                    value={newPassword.password}
                    onChange={(event) => setNewPassword({ ...newPassword, password: event.target.value })}
                    onFocus={event => {
                        event.target.select();
                    }}
                    // onBlur={(event) => {
                    //     setErrorWasteFeeData({ ...errorWasteFeeData, parcelCode: !isValid(event.target.value, 2) })
                    // }}
                    helperText={ErrorNewPassword.password ? 'กรุณากรอก' : ''}
                    error={ErrorNewPassword.password}
                />
                <TextField
                    type='password'
                    name='confirmNewPassword'
                    label="ยืนยันรหัสผ่านใหม่"
                    value={newPassword.confirmPassword}
                    onChange={(event) => setNewPassword({ ...newPassword, confirmPassword: event.target.value })}
                    onFocus={event => {
                        event.target.select();
                    }}
                    // onBlur={(event) => {
                    //     setErrorWasteFeeData({ ...errorWasteFeeData, parcelCode: !isValid(event.target.value, 2) })
                    // }}
                    helperText={ErrorNewPassword.confirmPassword ? 'กรุณากรอก' : ''}
                    error={ErrorNewPassword.confirmPassword}
                />
                <Button
                    variant='contained'
                    startIcon={<SaveIcon />}
                    type='submit'
                >
                    บันทึกข้อมูล
                </Button>
                <Button
                    sx={{ marginLeft: '1ch' }}
                    variant='contained'
                    color='error'
                    startIcon={<CancelIcon />}
                    onClick={() => {
                        setChangePasswordModalOpen(false)
                        setDefaultNewPassword()
                    }}
                >
                    ยกเลิก
                </Button>
            </Box>
        </Modal>
    )
}
