import { Box, Typography, Button, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import PrimarySearchAppBar from '../Layouts/PrimarySearchAppBar'
// import PrimarySearchAppBar from '../Navbar/SmartNavbar'
import BottomCard from './ChildComponent/BottomCard'
import OwnerCard from './ChildComponent/OwnerCard'
import LandCard from './ChildComponent/LandCard'
import BuildingTable from './ChildComponent/BuildingTable'
import BuildingModal from './ChildComponent/BuildingModal'
import { useLocation } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save';
import useFetchLtaxData from './hooks/useFetchLtaxData'
import useUtilitiesSurveyData from '../../hooks/useUtilitiesSurveyData'
import useHandleAddressSelect from './hooks/useHandleAddressSelect'
import useSurveySubmitButton from './hooks/useSurveySubmitButton'
import useBuildingImageUpload from './hooks/useBuildingImageUpload'
import useLandImageUpload from './hooks/useLandImageUpload'
import useBackDropLoadingToggle from '../../hooks/useBackDropLoadingToggle'
import useAlert from './hooks/useAlert'
import useAuth from '../../hooks/useAuth'
import SurveyAlert from './Layouts/SurveyAlert'
import BackDropLoading from '../Layouts/BackDropLoading'
import axiosSmartSurvey from '../../api/axios'
import useVerifyToken from '../../hooks/useVerifyToken'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    FormOutlined
} from "@ant-design/icons";

const cardStyle = {
    m: '35px 0px 0px 35px',
    width: '90%',
    justifyContent: 'center'
}
const cardHeaderStyle = {
    bgcolor: 'info.main',
    background: '#f2f3f3',
    color: '#025464',
    p: 1,
}
const cardBodyStyle = {
    background: '#ffffff',
}

// const BoxTitle = {
//     height: ' auto',
//     background: '#873800',
//     // bgcolor: 'info.main',
//     color: 'info.contrastText',
//     boxShadow: '1px 1px 1px  #8c8c8c'
// }

const theme = createTheme({
    typography: {
        fontFamily: [
            'IBM Plex Sans Thai',
            'sans-serif',
        ].join(','),
    },
});

export default function EditSurvey() {
    const { verifyToken } = useVerifyToken()
    const location = useLocation()

    //Card Header
    const ownerCardHeader = 'ข้อมูลเจ้าของที่ดิน'
    const landCardHeader = 'ข้อมูลแปลงที่ดิน'
    const buildingTableCardHeader = 'ข้อมูลสิ่งปลูกสร้าง'
    const bottomCardHeader = 'ข้อมูลทั่วไป'
    const [insertId, setInsertId] = useState()
    const [owner, setOwner] = useState({
        ltaxOwnerId: '',
        prefix: '',
        personType: '',
        firstName: '',
        lastName: '',
        idCardNo: '',
        address: '',
        moo: '',
        soi: '',
        road: '',
        tumbolId: '',
        tumbolName: '',
        district: '',
        province: '',
        provinceCode: '',
        telephoneNo: ''
    })
    const [errorOwner, setErrorOwner] = useState({
        prefix: false,
        personType: false,
        firstName: false,
        lastName: false,
        idCardNo: false,
        address: false,
        moo: false,
        soi: false,
        road: false,
        tumbolId: false,
        tumbolName: false,
        district: false,
        province: false,
        provinceCode: false
    })
    const [land, setLand] = useState({
        landId: '',
        parcelCode: '',
        landType: '',
        dn: '',
        rawang: '',
        landNo: '',
        surveyNo: '',
        rai: 0,
        ngan: 0,
        squareWa: 0,
        whoUsed: 0,
        building: 0,
        soi: '',
        moo: 0,
        road: '',
        tumbolId: '',
        images: [],
        imagesURL: []
    })
    const [errorLand, setErrorLand] = useState({
        landId: false,
        parcelCode: false,
        landType: false,
        dn: false,
        rawang: false,
        landNo: false,
        surveyNo: false,
        rai: false,
        ngan: false,
        squareWa: false,
        whoUsed: false,
        building: false,
        soi: false,
        moo: false,
        road: false,
        tumbolId: false,
        images: false,
        imagesURL: false
    })
    const [landUsed, setLandUsed] = useState([{
        value: '',
        whoUsed: '',
        haveBuilding: '',
        rai: 0,
        ngan: 0,
        squareWa: 0,
        note: '',
        images: {
            image: '',
            imageURL: ''
        }
    }])
    const [errorLandUsed, setErrorLandUsed] = useState([{
        value: false,
        whoUsed: false,
        haveBuilding: false,
        rai: false,
        ngan: false,
        squareWa: false,
        images: {
            image: false,
            imageURL: false
        }
    }])
    const [bottomCard, setBottomCard] = useState({
        surveyDate: ''
    })
    const [errorBottomCard, setErrorBottomCard] = useState({
        surveyDate: false
    })
    const whoUsed = ['ใช้เอง', 'ให้เช่า']
    const buildingMaterial = ['ตึก', 'ครึ่งตึกครึ่งไม้', 'ไม้']
    const [checkBox, setCheckBox] = useState({
        checkBox1: true,
        checkBox2: true,
        checkBox3: [true],
        checkBox4: true
    })
    const [modalFloorDetails, setModalFloorDetails] = useState([{
        floor: 1,
        width: 0,
        length: 0,
        whoUsed: 1,
        buildingUsed: {
            mainLiving: 0,
            otherLiving: 0,
            agriculture: 0,
            other: 0,
            abandoned: 0,
            value: 0
        }
    }])
    const [errorModalFloorDetails, setErrorModalFloorDetails] = useState([{
        floor: false,
        width: false,
        length: false,
        whoUsed: false,
        buildingUsed: {
            mainLiving: false,
            otherLiving: false,
            agriculture: false,
            other: false,
            abandoned: false,
            value: false
        }
    }])
    const [allBuilding, setAllBuilding] = useState([])
    const [buildingModalOpen, setBuildingModalOpen] = useState(false)
    const [buildingModalData, setBuildingModalData] = useState({
        address: '',
        buildingType: '',
        buildingMaterial: '',
        floorAmount: '',
        yearOfBuliding: '',
        floorDetails: [{
            floor: 1,
            width: 0,
            length: 0,
            whoUsed: '',
            buildingUsed: {
                mainLiving: 0,
                otherLiving: 0,
                agriculture: 0,
                other: 0,
                abandoned: 0,
                value: 0
            }
        }],
        owner: {
            ltaxOwnerId: '',
            prefix: '',
            personType: '',
            firstName: '',
            lastName: '',
            idCardNo: '',
            address: '',
            road: '',
            soi: '',
            moo: '',
            tumbolId: '',
            telephoneNo: ''
        },
        buildingIndex: '',
        ltaxBuildingId: ''
    })
    const [errorBuildingModalData, setErrorBuildingModalData] = useState({
        address: false,
        buildingType: false,
        buildingMaterial: false,
        floorAmount: false,
        yearOfBuliding: false,
        floorDetails: [{
            floor: 1,
            width: false,
            length: false,
            whoUsed: false,
            buildingUsed: {
                mainLiving: 0,
                otherLiving: 0,
                agriculture: 0,
                other: 0,
                abandoned: 0,
                value: false
            }
        }],
        owner: {
            ltaxOwnerId: '',
            prefix: false,
            personType: false,
            firstName: false,
            lastName: false,
            idCardNo: false,
            address: false,
            road: false,
            soi: false,
            moo: false,
            tumbolId: false,
            telephoneNo: false
        },
        buildingIndex: false,
        ltaxBuildingId: false
    })
    const [buildingOwnertumbol, setBuildingOwnertumbol] = useState({
        province: '',
        provinceCode: '',
        district: ''
    })
    const [provinceList, setProvinceList] = useState()
    const [buildYear, setBuildYear] = useState([])
    const editHeader = ''
    //Alert
    const [errorText, setErrorText] = useState('')
    const [successAlertOpen, setSuccessAlertOpen] = useState(false)
    const [errorAlertOpen, setErrorAlertOpen] = useState(false)
    //Loading
    const [backDropLoading, setBackDropLoading] = useState(false)
    //Custom Hooks Call
    const { user } = useAuth()
    const { prefixList } = useUtilitiesSurveyData()
    const { handleProvinceSelect, handleDistrictSelect } = useHandleAddressSelect()
    const { fetchLtaxData } = useFetchLtaxData()
    const { fetchSurveyInsert } = useSurveySubmitButton()
    const { fetchBuildingImageUpload } = useBuildingImageUpload()
    const { fetchLandImageUpload } = useLandImageUpload()
    const { handleErrorAlert } = useAlert()
    const { handleBackDropLoadingToggle } = useBackDropLoadingToggle()

    const axiosProvinceList = async () => {
        var response = await axiosSmartSurvey({
            method: 'post',
            url: `/api/province`,
        })
        setProvinceList(response.data)
    }
    const createBuildYear = (currentYear, oldestBuilding) => {
        var arr = [...buildYear]
        for (let i = currentYear; i >= oldestBuilding; i--) {
            arr = [...arr, i]
        }
        setBuildYear(arr)
    }
    useEffect(() => {
        verifyToken()
        if (location.state) {
            if (location.state.id) {
                fetchLtaxData('landid', location.state.id, location.state.parcelcode, land, setLand, setLandUsed, setOwner, setAllBuilding, prefixList, handleProvinceSelect, handleDistrictSelect, setBackDropLoading)
            } else {
                if (location.state.dn) {
                    fetchLtaxData('dn', location.state.dn, location.state.parcelcode, land, setLand, setLandUsed, setOwner, setAllBuilding, prefixList, handleProvinceSelect, handleDistrictSelect, setBackDropLoading)
                } else {
                    if (location.state.parcelcode) {
                        fetchLtaxData('parcelcode', location.state.oldParcelCode, location.state.parcelcode, land, setLand, setLandUsed, setOwner, setAllBuilding, prefixList, handleProvinceSelect, handleDistrictSelect, setBackDropLoading)
                    }
                }
            }
        }
        axiosProvinceList()
        createBuildYear(2565, 2458)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        console.log(buildingModalData)
    }, [buildingModalData])


    return (
        <ThemeProvider theme={theme} >
            <Box className='otherSide scrollbar-container'>
                <Box className='Title-map'>
                    <Typography variant="h5" > <FormOutlined /> การสำรวจที่ดินและสิ่งปลูกสร้าง</Typography>
                </Box>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1 },
                        '& .MuiButton-root': { m: 1 }
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={(event) => {
                        event.preventDefault()
                        // console.log(land, landUsed, allBuilding, owner)
                        fetchSurveyInsert(setErrorText, setErrorAlertOpen, setInsertId, setSuccessAlertOpen, handleErrorAlert, setBackDropLoading, handleBackDropLoadingToggle, land, landUsed, allBuilding, owner, bottomCard)
                    }}
                >
                    <OwnerCard
                        ownerCardHeader={ownerCardHeader}
                        owner={owner}
                        setOwner={setOwner}
                        errorOwner={errorOwner}
                        setErrorOwner={setErrorOwner}
                        cardStyle={cardStyle}
                        cardBodyStyle={cardBodyStyle}
                        cardHeaderStyle={cardHeaderStyle}
                    />
                    <LandCard
                        landCardHeader={landCardHeader}
                        land={land}
                        setLand={setLand}
                        errorLand={errorLand}
                        setErrorLand={setErrorLand}
                        landUsed={landUsed}
                        setLandUsed={setLandUsed}
                        errorLandUsed={errorLandUsed}
                        setErrorLandUsed={setErrorLandUsed}
                        cardStyle={cardStyle}
                        cardBodyStyle={cardBodyStyle}
                        cardHeaderStyle={cardHeaderStyle}
                    />
                    <BuildingTable
                        owner={owner}
                        buildingTableCardHeader={buildingTableCardHeader}
                        buildingMaterial={buildingMaterial}
                        allBuilding={allBuilding}
                        setAllBuilding={setAllBuilding}
                        modalFloorDetails={modalFloorDetails}
                        setModalFloorDetails={setModalFloorDetails}
                        buildingModalData={buildingModalData}
                        setBuildingModalData={setBuildingModalData}
                        setBuildingModalOpen={setBuildingModalOpen}
                        checkBox={checkBox}
                        setCheckBox={setCheckBox}
                        cardStyle={cardStyle}
                        cardBodyStyle={cardBodyStyle}
                        cardHeaderStyle={cardHeaderStyle}
                    />
                    <BuildingModal
                        buildingOwnertumbol={buildingOwnertumbol}
                        setBuildingOwnertumbol={setBuildingOwnertumbol}
                        whoUsed={whoUsed}
                        buildingMaterial={buildingMaterial}
                        owner={owner}
                        allBuilding={allBuilding}
                        setAllBuilding={setAllBuilding}
                        modalFloorDetails={modalFloorDetails}
                        setModalFloorDetails={setModalFloorDetails}
                        errorModalFloorDetails={errorModalFloorDetails}
                        setErrorModalFloorDetails={setErrorModalFloorDetails}
                        buildingModalData={buildingModalData}
                        setBuildingModalData={setBuildingModalData}
                        errorBuildingModalData={errorBuildingModalData}
                        setErrorBuildingModalData={setErrorBuildingModalData}
                        buildingModalOpen={buildingModalOpen}
                        setBuildingModalOpen={setBuildingModalOpen}
                        checkBox={checkBox}
                        setCheckBox={setCheckBox}
                        cardStyle={cardStyle}
                        cardBodyStyle={cardBodyStyle}
                        cardHeaderStyle={cardHeaderStyle}
                    />
                    <BottomCard
                        cardStyle={cardStyle}
                        cardBodyStyle={cardBodyStyle}
                        cardHeaderStyle={cardHeaderStyle}
                        bottomCardHeader={bottomCardHeader}
                        bottomCard={bottomCard}
                        setBottomCard={setBottomCard}
                        errorBottomCard={errorBottomCard}
                        setErrorBottomCard={setErrorBottomCard}
                    />
                    <Box style={{
                        marginTop: '20px',
                        marginLeft: '10px'
                    }}>
                        <Button
                            variant='contained'
                            startIcon={<SaveIcon />}
                            type='submit'
                        >
                            บันทึกข้อมูล
                        </Button>
                    </Box>
                </Box>
                <SurveyAlert
                    errorText={errorText}
                    successAlertOpen={successAlertOpen}
                    setSuccessAlertOpen={setSuccessAlertOpen}
                    errorAlertOpen={errorAlertOpen}
                    setErrorAlertOpen={setErrorAlertOpen}
                    insertId={insertId}
                    handleBackDropLoadingToggle={handleBackDropLoadingToggle}
                    setBackDropLoading={setBackDropLoading}
                    surveyType={1}
                />
                <BackDropLoading backDropLoading={backDropLoading} />
            </Box>
        </ThemeProvider>
    )
}
