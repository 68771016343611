import axiosSmartSurvey from '../../../api/axios'

export default function useFetchWasteFee() {
    async function submitSearch(parcelCode, address, houseName, setWasteFeeDataList) {
        // console.log(parcelCode, address)
        if (parcelCode === '') {
            parcelCode = undefined
        }
        if (address === '') {
            address = undefined
        }
        if (houseName === '') {
            houseName = undefined
        }
        await axiosSmartSurvey({
            method: 'post',
            url: '/wastefee/searchdata',
            data: {
                parcelCode: parcelCode,
                address: address,
                houseName: houseName,
            }
        }).then((response) => {
            if (response.data.status === 'OK') {
                response = response.data.wasteFeeData
                setWasteFeeDataList(response)
            } else {
                // console.log(response)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const axiosWasteFeeDataList = async (setWasteFeeDataList) => {
        var response = await axiosSmartSurvey({
            method: 'post',
            url: '/wastefee/datatable'
        })
        // console.log(response)
        response = response.data.wasteFeeData
        setWasteFeeDataList(response)
    }
    const fetchDeleteWasteFeeData = async (handleDeleteDialogClose, wasteFeeId, statusChangedNote) => {
        await axiosSmartSurvey({
            method: 'post',
            url: `/wastefee/deletewastefeedata`,
            data: {
                rowId: wasteFeeId,
                statusChangedNote: statusChangedNote
            }
        }).then((response) => {
            console.log(response)
            handleDeleteDialogClose()
        }).catch((err) => {
            console.log(err)
        })
    }
    const fetchPaymentData = async (id, budgetYear, setBudgetYear, setMonthPaymentStatus, setMonthPaymentBillNo, setDefaultWasteFeePaymentData) => {
        // console.log(id, budgetYear)
        await axiosSmartSurvey({
            method: 'post',
            url: `/wastefee/paymentdata`,
            data: {
                id: id,
                budgetYear: budgetYear
            }
        }).then((response) => {
            // console.log(response)
            response = response.data
            if (response.status === 'OK') {
                if (response?.paymentStatus?.length > 0 && response?.paymentBills?.length > 0) {
                    const paymentStatus = response.paymentStatus[0]
                    const paymentBills = response.paymentBills[0]
                    // console.log(paymentStatus, paymentBills)
                    setMonthPaymentStatus({
                        january: paymentStatus.january,
                        february: paymentStatus.february,
                        march: paymentStatus.march,
                        april: paymentStatus.april,
                        may: paymentStatus.may,
                        june: paymentStatus.june,
                        july: paymentStatus.july,
                        august: paymentStatus.august,
                        september: paymentStatus.september,
                        october: paymentStatus.october,
                        november: paymentStatus.november,
                        december: paymentStatus.december,
                    })
                    setMonthPaymentBillNo({
                        january: paymentBills.january,
                        february: paymentBills.february,
                        march: paymentBills.march,
                        april: paymentBills.april,
                        may: paymentBills.may,
                        june: paymentBills.june,
                        july: paymentBills.july,
                        august: paymentBills.august,
                        september: paymentBills.september,
                        october: paymentBills.october,
                        november: paymentBills.november,
                        december: paymentBills.december,
                    })
                } else {
                    setDefaultWasteFeePaymentData()
                }
            } else {
                setDefaultWasteFeePaymentData()
            }
        })
    }
    const fetchPaymentDataEdit = async (id, budgetYear, monthPaymentStatus, monthPaymentBillNo, setSuccessAlertOpen, setSuccessText, setErrorAlertOpen, setErrorText) => {
        axiosSmartSurvey({
            method: 'post',
            url: '/wastefee/paymentedit',
            data: {
                id: id,
                budgetYear: budgetYear,
                monthPaymentStatus: monthPaymentStatus,
                monthPaymentBillNo: monthPaymentBillNo
            }
        }).then((response) => {
            if (response.data.status === 'OK') {
                setSuccessAlertOpen(true)
                setSuccessText('บันทึกการจัดเก็บสำเร็จ')
                // console.log(response.data)
            } else {
                setErrorAlertOpen(true)
                setErrorText(response.data.msg)
                // console.log(response.data)
            }
        })
    }
    async function onFetchEditInput(rowId, setWasteFeeData) {
        axiosSmartSurvey({
            method: 'post',
            url: '/wastefee/editinputdata',
            data: {
                id: rowId,
            }
        }).then((response) => {
            // console.log(response)
            if (response.data.status === 'OK') {
                response = response.data.data
                setWasteFeeData({
                    parcelCode: response.parcel_code || '',
                    houseName: response.house_name || '',
                    houseCode: response.house_code || '',
                    address: response.address || '',
                    route: response.route || '',
                    road: response.road || '',
                    soi: response.soi || '',
                    moo: response.moo || '',
                    tumbol: response.tumbol_id || 0,
                    fee: response.fee || ''
                })
                // console.log(response.data)
            } else {
                // console.log(response.data)
            }
        })
    }
    function addWasteFeeData(wasteFeeData, setDefaultWasteFeeData, setAddModalOpen, setSuccessAlertOpen, setErrorAlertOpen, setSuccessText, setErrorText) {
        // console.log(wasteFeeData)
        axiosSmartSurvey({
            method: 'post',
            url: '/wastefee/addwastefeedata',
            data: {
                wasteFeeData: wasteFeeData
            }
        }).then((response) => {
            if (response.data.status === 'OK') {
                console.log(response)
                setSuccessAlertOpen(true)
                setSuccessText(response.data.message)
                setAddModalOpen(false)
                setDefaultWasteFeeData()
            } else {
                console.log(response)
                setErrorAlertOpen(true)
                setErrorText(response.data.message)
            }
        })
    }
    function editWasteFeeData(wasteFeeData, setDefaultWasteFeeData, setAddModalOpen, setSuccessAlertOpen, setErrorAlertOpen, setSuccessText, setErrorText, rowId) {
        axiosSmartSurvey({
            method: 'post',
            url: '/wastefee/editwastefeedata',
            data: {
                wasteFeeData: wasteFeeData,
                rowId: rowId
            }
        }).then((response) => {
            if (response.data.status === 'OK') {
                console.log(response)
                setSuccessAlertOpen(true)
                setSuccessText(response.data.message)
                setAddModalOpen(false)
                setDefaultWasteFeeData()
            } else {
                console.log(response)
                setErrorAlertOpen(true)
                setErrorText(response.data.message)
            }
        })
    }
    return {
        onFetchEditInput,
        submitSearch,
        axiosWasteFeeDataList,
        fetchDeleteWasteFeeData,
        fetchPaymentData,
        fetchPaymentDataEdit,
        addWasteFeeData,
        editWasteFeeData
    }
}
