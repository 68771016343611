import { Modal, Box, TextField, MenuItem, Button, Typography, CardContent, Card } from '@mui/material'
import { useState, useEffect } from 'react'

//MUI ICON
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
//Custom Hooks
import useFetchWasteFee from '../hooks/useFetchWasteFee';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    '& .MuiTextField-root': { m: 1 }
};
export default function WasteFeePaymentEditModal({ editPaymentModalOpen, setEditPaymentModalOpen, rowId, setRowId, setSuccessAlertOpen, setSuccessText, setErrorAlertOpen, setErrorText }) {
    //CustomHooks
    const { fetchPaymentData, fetchPaymentDataEdit } = useFetchWasteFee()
    // 
    const currentBudgetYear = 2568
    const [budgetYear, setBudgetYear] = useState(currentBudgetYear)
    const [monthPaymentStatus, setMonthPaymentStatus] = useState({
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0,
    })
    function setDefaultWasteFeePaymentData() {
        setMonthPaymentStatus({
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0,
        })
        setMonthPaymentBillNo({
            january: '',
            february: '',
            march: '',
            april: '',
            may: '',
            june: '',
            july: '',
            august: '',
            september: '',
            october: '',
            november: '',
            december: '',
        })
    }
    const [monthPaymentBillNo, setMonthPaymentBillNo] = useState({
        january: '',
        february: '',
        march: '',
        april: '',
        may: '',
        june: '',
        july: '',
        august: '',
        september: '',
        october: '',
        november: '',
        december: '',
    })
    // useEffect(() => {
    //     console.log(budgetYear)
    // }, [budgetYear])

    useEffect(() => {
        if (editPaymentModalOpen) {
            // console.log(rowId)
            fetchPaymentData(rowId, budgetYear, setBudgetYear, setMonthPaymentStatus, setMonthPaymentBillNo, setDefaultWasteFeePaymentData)
        }
    }, [editPaymentModalOpen])

    return (
        <Modal
            open={editPaymentModalOpen}
            onClose={() => {
                setEditPaymentModalOpen(false)
                setBudgetYear(currentBudgetYear)
                setRowId(undefined)
                setDefaultWasteFeePaymentData()
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={style}
                component='form'
                onSubmit={(event) => {
                    event.preventDefault()
                    fetchPaymentDataEdit(rowId, budgetYear, monthPaymentStatus, monthPaymentBillNo, setSuccessAlertOpen, setSuccessText, setErrorAlertOpen, setErrorText)
                    setEditPaymentModalOpen(false)
                }
                }
            >
                <Card
                // sx={{ width: '88ch' }}
                >
                    <Typography variant='h5'>การชำระเงิน</Typography >
                    <CardContent>
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='ปีงบประมาณ'
                            value={budgetYear}
                            onChange={(event) => {
                                setBudgetYear(event.target.value)
                                fetchPaymentData(rowId, event.target.value, setBudgetYear, setMonthPaymentStatus, setMonthPaymentBillNo, setDefaultWasteFeePaymentData)
                            }}
                        >
                            <MenuItem value={2568}>2568</MenuItem>
                            <MenuItem value={2569}>2569</MenuItem>
                            <MenuItem value={2570}>2570</MenuItem>
                            <MenuItem value={2571}>2571</MenuItem>
                            <MenuItem value={2572}>2572</MenuItem>
                            <MenuItem value={2573}>2573</MenuItem>
                            <MenuItem value={2574}>2574</MenuItem>
                            <MenuItem value={2575}>2575</MenuItem>
                            <MenuItem value={2576}>2576</MenuItem>
                            <MenuItem value={2577}>2577</MenuItem>
                        </TextField>
                        <Button
                            sx={{ m: 1 }}
                            variant='contained'
                            startIcon={<CheckCircleOutlineIcon />}
                            onClick={() => setMonthPaymentStatus({
                                january: 1,
                                february: 1,
                                march: 1,
                                april: 1,
                                may: 1,
                                june: 1,
                                july: 1,
                                august: 1,
                                september: 1,
                                october: 1,
                                november: 1,
                                december: 1,
                            })}
                        >
                            ชำระแล้วทั้งปี
                        </Button>
                        <Button
                            sx={{ m: 1 }}
                            variant='contained'
                            startIcon={<UnpublishedIcon />}
                            onClick={() => setDefaultWasteFeePaymentData()}
                        >
                            ยังไม่ชำระทั้งปี
                        </Button>
                    </CardContent>
                    <CardContent>
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='ตุลาคม'
                            value={monthPaymentStatus.october}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, october: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.october}
                            disabled={monthPaymentStatus.october === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, october: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='พฤศจิกายน'
                            value={monthPaymentStatus.november}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, november: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.november}
                            disabled={monthPaymentStatus.november === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, november: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='ธันวาคม'
                            value={monthPaymentStatus.december}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, december: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.december}
                            disabled={monthPaymentStatus.december === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, december: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='มกราคม'
                            value={monthPaymentStatus.january}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, january: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.january}
                            disabled={monthPaymentStatus.january === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, november: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='กุมภาพันธ์'
                            value={monthPaymentStatus.february}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, february: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.february}
                            disabled={monthPaymentStatus.february === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, february: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='มีนาคม'
                            value={monthPaymentStatus.march}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, march: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.march}
                            disabled={monthPaymentStatus.march === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, march: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='เมษายน'
                            value={monthPaymentStatus.april}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, april: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.april}
                            disabled={monthPaymentStatus.april === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, april: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='พฤษภาคม'
                            value={monthPaymentStatus.may}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, may: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.may}
                            disabled={monthPaymentStatus.may === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, may: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='มิถุนายน'
                            value={monthPaymentStatus.june}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, june: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.june}
                            disabled={monthPaymentStatus.june === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, june: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='กรกฎาคม'
                            value={monthPaymentStatus.july}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, july: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.july}
                            disabled={monthPaymentStatus.july === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, july: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='สิงหาคม'
                            value={monthPaymentStatus.august}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, august: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.august}
                            disabled={monthPaymentStatus.august === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, august: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                        <TextField
                            sx={{ width: '15ch' }}
                            select
                            label='กันยายน'
                            value={monthPaymentStatus.september}
                            onChange={(event) => setMonthPaymentStatus({ ...monthPaymentStatus, september: event.target.value })}
                        >
                            <MenuItem value={0}>ยังไม่ชำระ</MenuItem>
                            <MenuItem value={1}>ชำระแล้ว</MenuItem>
                        </TextField>
                        <TextField
                            label="เลขที่ใบเสร็จ"
                            value={monthPaymentBillNo.september}
                            disabled={monthPaymentStatus.september === 0}
                            onChange={(event) => setMonthPaymentBillNo({ ...monthPaymentBillNo, september: event.target.value })}
                            onFocus={event => {
                                event.target.select();
                            }}
                        />
                    </CardContent>
                    <Button
                        variant='contained'
                        type='submit'
                        startIcon={<SaveIcon />}
                    >
                        บันทึกข้อมูล
                    </Button>
                    <Button
                        sx={{ marginLeft: '1ch' }}
                        variant='contained'
                        color='error'
                        startIcon={<CancelIcon />}
                        onClick={() => {
                            setEditPaymentModalOpen(false)
                        }}
                    >
                        ยกเลิก
                    </Button>
                </Card>
            </Box>
        </Modal>
    )
}
