import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Typography, TextField, MenuItem } from '@mui/material'
import useDataValidation from '../../../hooks/useDataValidation'


export default function BillboardLocationCard({ billboardLocation, setBillboardLocation, errorBillboardLocation, setErrorBillboardLocation, cardStyle, cardHeaderStyle, cardBodyStyle, locationCardHeader }) {
    // Custom Hooks
    const { isValid } = useDataValidation()
    return (
        <Card sx={cardStyle}>
            <CardContent sx={cardHeaderStyle}>
                <Typography variant='h5'>{locationCardHeader}</Typography>
            </CardContent>
            <CardContent sx={cardBodyStyle}>
                <TextField
                    id=""
                    label="รหัสแปลงที่ดิน"
                    value={billboardLocation.parcelCode || ''}
                    onFocus={event => {
                        event.target.select()
                    }}
                    onChange={(event) => {
                        setBillboardLocation({ ...billboardLocation, parcelCode: event.target.value.toUpperCase() })
                    }}
                    onBlur={(event) => {
                        setErrorBillboardLocation({ ...errorBillboardLocation, parcelCode: !isValid(event.target.value, 2) })
                    }}
                    helperText={errorBillboardLocation.parcelCode ? 'ตัวอย่าง 01A001, 02B002/001' : ''}
                    error={errorBillboardLocation.parcelCode}
                />
                <TextField
                    id=""
                    label="ชื่อสถานประกอบการค้า/กิจการ"
                    value={billboardLocation.businessName || ''}
                    onFocus={event => {
                        event.target.select();
                    }}
                    onChange={(event) => {
                        const value = event.target.value;
                        setBillboardLocation({ ...billboardLocation, businessName: value })
                    }}
                    onBlur={(event) => {
                        if (event.target.value === '') {
                            setErrorBillboardLocation({ ...errorBillboardLocation, businessName: true })
                        } else {
                            setErrorBillboardLocation({ ...errorBillboardLocation, businessName: false })
                        }
                    }}
                    helperText={errorBillboardLocation.businessName ? 'กรุณากรอกข้อมูล' : ''}
                    error={errorBillboardLocation.businessName}
                />
                <TextField
                    id=""
                    label="บ้านเลขที่"
                    value={billboardLocation.buildingAdress || ''}
                    onFocus={event => {
                        event.target.select();
                    }}
                    onChange={(event) => {
                        setBillboardLocation({ ...billboardLocation, buildingAdress: event.target.value })
                    }}
                    onBlur={(event) => {
                        setErrorBillboardLocation({ ...errorBillboardLocation, buildingAdress: !isValid(event.target.value, 4) })
                        // console.log(event.target.value)
                    }}
                    helperText={errorBillboardLocation.buildingAdress ? 'ตัวอย่าง 11/2' : ''}
                    error={errorBillboardLocation.buildingAdress}
                />
                <TextField
                    id=""
                    label="หมู่"
                    value={billboardLocation.moo || ''}
                    onChange={(event) => { setBillboardLocation({ ...billboardLocation, moo: event.target.value }) }}
                    onFocus={event => {
                        event.target.select();
                    }}
                    onBlur={(event) => {
                        if (event.target.value == 0 && billboardLocation.road === '') {
                            setErrorBillboardLocation({ ...errorBillboardLocation, moo: true, road: true })
                        } else {
                            setErrorBillboardLocation({ ...errorBillboardLocation, moo: false, road: false })
                        }
                    }}
                    helperText={errorBillboardLocation.moo ? 'กรุณาระบุถนนหรือหมู่' : ''}
                    error={errorBillboardLocation.moo}
                />
                <TextField
                    id=""
                    label="ตรอก/ซอย"
                    value={billboardLocation.soi || ''}
                    onChange={(event) => { setBillboardLocation({ ...billboardLocation, soi: event.target.value }) }}
                    onFocus={event => {
                        event.target.select();
                    }}
                />
                <TextField
                    id=""
                    label="ถนน"
                    value={billboardLocation.road || ''}
                    onChange={(event) => { setBillboardLocation({ ...billboardLocation, road: event.target.value }) }}
                    onFocus={event => {
                        event.target.select();
                    }}
                    onBlur={(event) => {
                        if (event.target.value === '' && billboardLocation.moo == 0) {
                            setErrorBillboardLocation({ ...errorBillboardLocation, moo: true, road: true })
                        } else {
                            setErrorBillboardLocation({ ...errorBillboardLocation, moo: false, road: false })
                        }
                    }}
                    helperText={errorBillboardLocation.road ? 'กรุณาระบุถนนหรือหมู่' : ''}
                    error={errorBillboardLocation.road}
                />
                <TextField
                    sx={{ width: '15ch' }}
                    select
                    label='ตำบล'
                    value={billboardLocation.tumbolId}
                    onChange={(event) => {
                        setBillboardLocation({ ...billboardLocation, tumbolId: event.target.value })
                    }}>
                    <MenuItem value={840101}>ตลาด</MenuItem>
                    <MenuItem value={840102}>มะขามเตี้ย</MenuItem>
                    <MenuItem value={840105}>บางใบไม้</MenuItem>
                    <MenuItem value={840106}>บางชนะ</MenuItem>
                    <MenuItem value={840110}>บางกุ้ง</MenuItem>
                    <MenuItem value={840111}>คลองฉนาก</MenuItem>
                </TextField>
            </CardContent>
        </Card>
    )
}
