import useAuth from '../../../hooks/useAuth'
import useBillboardImagesUpload from './useBillboardImagesUpload'
import axiosSmartSurvey from '../../../api/axios'

export default function useEditBillboardSurvey() {
    const { user } = useAuth()
    const { fetchBillboardUpload } = useBillboardImagesUpload()
    async function editBillboardSubmit(event, surveyId, owner, billboardLocation, billboardDetails, setInsertId, setSuccessAlertOpen, handleErrorAlert, setErrorText, setErrorAlertOpen, handleBackDropLoadingToggle, setBackDropLoading) {
        event.preventDefault()
        handleBackDropLoadingToggle(true, setBackDropLoading)
        const billboardImagesId = await fetchBillboardUpload(billboardLocation, billboardDetails)
        console.log(billboardImagesId)
        axiosSmartSurvey({
            method: 'post',
            url: '/survey/billboard/edit',
            data: {
                user: user,
                owner: owner,
                billboardLocation: billboardLocation,
                billboardDetails: billboardDetails,
                billboardImagesId: billboardImagesId,
                surveyId: surveyId
            }
        }).then((response) => {
            response = response.data
            // console.log(response)
            if (response.status === 'OK') {
                setInsertId(surveyId)
                setSuccessAlertOpen(true)
            } else {
                handleErrorAlert(response.msg, setErrorText, setErrorAlertOpen)
            }
        }).catch((err) => {
            handleErrorAlert('การเชื่อมต่อล้มเหลว กรุณาลองใหม่อีกครั้ง หรือติดต่อผู้แลระบบ', setErrorText, setErrorAlertOpen)
        })
    }
    return { editBillboardSubmit }
}
