import { useEffect, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import useFetchFileData from "../hooks/useFetchFileData"
import { Row, Col, Table, Modal, Input } from "antd";
import { Typography, Box, ThemeProvider, Button } from "@mui/material";
import useAlertTaxmapfolder from '../hooks/useAlertTaxmapfolder';

function FileTypeData() {
  //Custom Hooks
  const { handleErrorAlert } = useAlertTaxmapfolder()
  const { fetchFileTypeData } = useFetchFileData()
  const { fetchAddfiletypedata } = useFetchFileData()

  //State
  const [fileTypeData, setFileTypeData] = useState([])
  const [fileType, setFileType] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);

  //
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    fetchAddfiletypedata(fileType, setFileTypeData, fetchFileTypeData)
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
    }]

  useEffect(() => {
    fetchFileTypeData(setFileTypeData)
  }, [])
  useEffect(() => {
    console.log(fileTypeData)
  }, [fileTypeData])

  return (
    <div className="otherSide" id="otherSide">
      <Row style={{
        display: 'flex',
        flexFlow: 'row wrap',
        minWidth: 0,
        justifyContent: 'center'
      }}>
        <Box style={{ border: "solid #4557", position: 'absolute' }} >
        </Box>
      </Row>
      <Row
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          minWidth: 0,
          justifyContent: 'center'
        }}>
        <Box
          component='form'
          onSubmit={(event) => { }}
        >
          <Box className="content" style={{ width: '800px' }}>
            {/* <Row>
              <Col span={12}> */}
                <Box className="backgroud-formInput">
                  <Typography sx={{ fontSize: "1.5em", fontWeight: "900" }}>ประเภทเอกสาร</Typography>
                </Box>
              {/* </Col> */}
              {/* <Typography sx={{ fontSize: "1.5em", fontWeight: "900" }}></Typography> */}
              {/* <Col span={12} > */}
                <Button
                  style={{
                    height: "3rem",
                    backgroundColor: "#ffa500",
                    // marginTop: "24px",
                    color: "#ffff"
                  }}
                  onClick={showModal}
                ><AddIcon /> เพิ่มประเภทเอกสาร
                </Button>
              {/* </Col>
            </Row> */}
            <Modal
              component="form"
              title="เพิ่มตัวเลือก"
              onOk={handleOk}
              open={isModalOpen}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  ยกเลิก
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleOk}>
                  บันทึก
                </Button>,
              ]}
            >
              <Input
                placeholder="add item"
                value={fileType}
                onChange={(event) => {
                  setFileType(event.target.value)
                }}
              />

            </Modal>
            <Table
              columns={columns}
              dataSource={fileTypeData}
            />
          </Box>
        </Box>
      </Row>
    </div>
  )
}

export default FileTypeData