import { createContext, useState } from "react"
import axiosSmartSurvey from "../api/axios"

const UtilitiesSurveyDataContext = createContext({})


export function UtilitiesSurveyDataProvider({ children }) {
    const [prefixList, setPrefixList] = useState([])
    const [landTypeList, setLandTypeList] = useState([])
    const [buildingTypeList, setBuildingTypeList] = useState([])
    const [provinceList, setProvinceList] = useState([{}])
    const personType = [
        { id: 1, value: 'บุคคลธรรมดา' },
        { id: 2, value: 'นิติบุคคล' }
    ]
    const [buildYear, setBuildYear] = useState([])


    //Address Function
    const handleProvinceSelect = async (provinceCode, setTumbolList, setDistrictList) => {
        var response = await axiosSmartSurvey({
            method: 'post',
            url: '/api/district',
            data: { provinceCode: provinceCode }
        })
        setTumbolList([{}])
        setDistrictList(response.data)
    }
    const handleDistrictSelect = async (district, setTumbolList) => {
        var response = await axiosSmartSurvey({
            method: 'post',
            url: '/api/tumbol',
            data: { district: district }
        })
        // console.log(response.data)
        setTumbolList(response.data)
    }

    //Building Function
    const calBuildingUsed = (width, length, buildingUsed) => {
        var floorNo = buildingUsed.value
        if (floorNo === 1) {
            buildingUsed.mainLiving = (width * length).toFixed(2)
        } else if (floorNo === 2) {
            buildingUsed.otherLiving = (width * length).toFixed(2)
        } else if (floorNo === 3) {
            buildingUsed.agriculture = (width * length).toFixed(2)
        } else if (floorNo === 4) {
            buildingUsed.other = (width * length).toFixed(2)
        } else {
            buildingUsed.abandoned = (width * length).toFixed(2)
        }
        return buildingUsed
    }
    const handleFloorChange = (value, allBuilding, setBuildingModalData, buildingModalData, modalFloorDetails, setModalFloorDetails, checkBox, setCheckBox, bIndex) => {
        var arr = []
        var arr2 = []
        var floorAmount = 0
        if (value < 1) {
            floorAmount = 1
        } else {
            floorAmount = value
        }
        if (bIndex !== undefined) {
            for (let i = 0; i < floorAmount; i++) {
                // console.log(allBuilding[bIndex].floorDetails[i].width)
                arr = [...arr, {
                    floor: i + 1,
                    width: allBuilding[bIndex].floorDetails[i].width,
                    length: allBuilding[bIndex].floorDetails[i].length,
                    whoUsed: allBuilding[bIndex].floorDetails[i].whoUsed,
                    buildingUsed: (calBuildingUsed(
                        allBuilding[bIndex].floorDetails[i].width,
                        allBuilding[bIndex].floorDetails[i].length,
                        {
                            mainLiving: 0,
                            otherLiving: 0,
                            agriculture: 0,
                            other: 0,
                            abandoned: 0,
                            value: allBuilding[bIndex].floorDetails[i].buildingUsed.value
                        }
                    ))
                }]
                arr2 = [...arr2, true]
            }
            setBuildingModalData({
                ...buildingModalData,
                address: allBuilding[bIndex].address,
                buildingType: 1,
                buildingMeterial: allBuilding[bIndex].buildingMeterial,
                floorAmount: parseInt(floorAmount),
                yearOfBuliding: allBuilding[bIndex].yearOfBuliding,
                floorDetails: arr,
                owner: allBuilding[bIndex].owner,
                buildingIndex: bIndex,
                ltaxBuildingId: allBuilding[bIndex].ltaxBuildingId
            })
        } else {
            for (let i = 0; i < floorAmount; i++) {
                arr = [...arr, {
                    floor: i + 1,
                    width: modalFloorDetails[0].width,
                    length: modalFloorDetails[0].length,
                    whoUsed: 1,
                    buildingUsed: (calBuildingUsed(
                        modalFloorDetails[0].width,
                        modalFloorDetails[0].length,
                        {
                            mainLiving: 0,
                            otherLiving: 0,
                            agriculture: 0,
                            other: 0,
                            abandoned: 0,
                            value: modalFloorDetails[0].buildingUsed.value
                        }
                    ))
                }]
                arr2 = [...arr2, true]
            }
            setBuildingModalData({ ...buildingModalData, floorAmount: floorAmount, floorDetails: arr })
        }
        // console.log(arr)
        setModalFloorDetails(arr)
        setCheckBox({ ...checkBox, checkBox3: arr2 })
    }
    const handleBuildingModalOpen = (buildingIndex, allBuilding, setBuildingModalData, setBuildingModalOpen) => {
        setBuildingModalData({
            address: allBuilding[buildingIndex].address,
            buildingType: allBuilding[buildingIndex].buildingType,
            buildingMaterial: allBuilding[buildingIndex].buildingMaterial,
            floorAmount: allBuilding[buildingIndex].floorAmount,
            yearOfBuliding: allBuilding[buildingIndex].yearOfBuliding,
            floorDetails: allBuilding[buildingIndex].floorDetails,
            owner: allBuilding[buildingIndex].owner,
            buildingIndex: buildingIndex,
            ltaxBuildingId: allBuilding[buildingIndex].ltaxBuildingId,
            image: allBuilding[buildingIndex].image,
            imageURL: allBuilding[buildingIndex].imageURL
        })
        setBuildingModalOpen(true)
    }
    //Building Modal Function
    const handleBuildingModalClose = (setBuildingModalOpen, setCheckBox, setBuildingOwnertumbol, setBuildingModalData) => {
        setBuildingModalData({
            address: '',
            buildingType: '',
            buildingMaterial: '',
            floorAmount: '',
            yearOfBuliding: '',
            floorDetails: [{
                floor: 1,
                width: 0,
                length: 0,
                whoUsed: '',
                buildingUsed: {
                    mainLiving: 0,
                    otherLiving: 0,
                    agriculture: 0,
                    other: 0,
                    abandoned: 0,
                    value: 0
                }
            }],
            owner: {
                ltaxOwnerId: '',
                prefix: '',
                personType: '',
                firstName: '',
                lastName: '',
                idCardNo: '',
                address: '',
                road: '',
                soi: '',
                moo: '',
                tumbolId: '',
                telephoneNo: ''
            },
            buildingIndex: '',
            ltaxBuildingId: ''
        })
        setBuildingModalOpen(false)
        setCheckBox({
            checkBox1: true,
            checkBox2: true,
            checkBox3: [true],
            checkBox4: true
        })
        setBuildingOwnertumbol({
            province: '',
            provinceCode: '',
            district: ''
        })
    }
    const createBuildingUsed = (index, modalFloorDetails, setModalFloorDetails, setBuildingModalData, buildingModalData) => {
        var arr = [...modalFloorDetails]
        let value = modalFloorDetails[index].buildingUsed.value
        var obj = {
            mainLiving: 0,
            otherLiving: 0,
            agriculture: 0,
            other: 0,
            abandoned: 0,
            value: value
        }
        if (value === 1) {
            obj.mainLiving = (arr[index].width * arr[index].length)
        } else if (value === 2) {
            obj.otherLiving = (arr[index].width * arr[index].length)
        } else if (value === 3) {
            obj.agriculture = (arr[index].width * arr[index].length)
        } else if (value === 4) {
            obj.other = (arr[index].width * arr[index].length)
        } else {
            obj.abandoned = (arr[index].width * arr[index].length)
        }
        obj.value = value
        arr[index].buildingUsed = obj
        setModalFloorDetails(arr)
        setBuildingModalData({ ...buildingModalData, floorDetails: arr })
    }

    return (
        <UtilitiesSurveyDataContext.Provider value={{
            buildYear,
            setBuildYear,
            personType,
            prefixList,
            setPrefixList,
            landTypeList,
            setLandTypeList,
            buildingTypeList,
            setBuildingTypeList,
            provinceList,
            setProvinceList,
            handleDistrictSelect,
            handleProvinceSelect,
            handleFloorChange,
            handleBuildingModalOpen,
            handleBuildingModalClose,
            createBuildingUsed
        }}>
            {children}
        </UtilitiesSurveyDataContext.Provider>
    )
}


export default UtilitiesSurveyDataContext;