import React from 'react'
import { Box, Backdrop, CircularProgress } from '@mui/material'

export default function BackDropLoading({ backDropLoading }) {
    return (
        <Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backDropLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}
