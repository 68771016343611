import {
  Box,
  Button,
  MenuItem,
  TextField,
  Card,
  CardContent,
  Typography,
  Grid,
  CardActionArea,
  CardMedia,
  Divider
} from '@mui/material'
import useUtilitiesSurveyData from '../../../hooks/useUtilitiesSurveyData';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


export default function LandCard({ landCardHeader, land, setLand, errorLand, setErrorLand, landUsed, setLandUsed, errorLandUsed, setErrorLandUsed, cardStyle, cardBodyStyle, cardHeaderStyle }) {
  const { landTypeList } = useUtilitiesSurveyData()
  return (
    <Box>
      <Card sx={cardStyle}>
        <CardContent sx={cardHeaderStyle}>
          <Typography variant='h5'>{landCardHeader}</Typography >
          {/* Land Section */}
        </CardContent>
        <CardContent sx={cardBodyStyle}>
          <Typography variant='h6' gutterBottom>เอกสารสิทธิ์</Typography >
          <TextField
            id="parcelCode"
            label="รหัสแปลงที่ดิน"
            value={land.parcelCode}
            onChange={(event) => setLand({ ...land, parcelCode: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                setErrorLand({ ...errorLand, parcelCode: true })
              } else {
                setErrorLand({ ...errorLand, parcelCode: false })
              }
            }}
            helperText={errorLand.parcelCode ? 'กรุณาระบุ' : ''}
            error={errorLand.parcelCode}
          />
          <TextField
            sx={{ width: '20ch' }}
            id=''
            select
            label='ประเภทเอกสารสิทธิ์'
            value={land.landType || ""}
            onChange={(event) => {
              setLand({ ...land, landType: event.target.value })
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                setErrorLand({ ...errorLand, landType: true })
              } else {
                setErrorLand({ ...errorLand, landType: false })
              }
            }}
            helperText={errorLand.landType ? 'กรุณาระบุ' : ''}
            error={errorLand.landType}
          >
            {landTypeList.map((data, index) => (
              <MenuItem key={index} value={data.id_type}>{data.type_name}</MenuItem>
            ))}
          </TextField>
          <TextField
            id="dn"
            label="เลขที่เอกสารสิทธิ์"
            value={land.dn || ""}
            onChange={(event) => setLand({ ...land, dn: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                setErrorLand({ ...errorLand, dn: true })
              } else {
                setErrorLand({ ...errorLand, dn: false })
              }
            }}
            helperText={errorLand.dn ? 'กรุณาระบุ' : ''}
            error={errorLand.dn}
          />
          <Typography variant='h6' gutterBottom>ตำแหน่งที่ดิน</Typography>
          <TextField
            id="rawang"
            label="ระวางที่ดิน"
            value={land.rawang || ""}
            onChange={(event) => setLand({ ...land, rawang: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
          />
          <TextField
            id="landNo"
            label="เลขที่ดิน"
            value={land.landNo || ""}
            onChange={(event) => setLand({ ...land, landNo: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
          />
          <TextField
            id="servey"
            label="หน้าสำรวจที่ดิน"
            value={land.surveyNo || ""}
            onChange={(event) => setLand({ ...land, surveyNo: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
          />
          <Typography variant='h6' gutterBottom>ที่ตั้ง</Typography>
          <TextField
            id="ownerSoi-"
            label="ซอย"
            value={land.soi || ""}
            onChange={(event) => setLand({ ...land, soi: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
          />
          <TextField
            id="ownerRoad"
            label="ถนน"
            value={land.road || ""}
            onChange={(event) => setLand({ ...land, road: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value === '' && land.moo == 0) {
                setErrorLand({ ...errorLand, moo: true, road: true })
              } else {
                setErrorLand({ ...errorLand, moo: false, road: false })
              }
            }}
            helperText={errorLand.road ? 'กรุณาระบุถนนหรือหมู่' : ''}
            error={errorLand.road}
          />
          <TextField
            id="ownerMoo"
            label="หมู่"
            value={land.moo || ""}
            onChange={(event) => setLand({ ...land, moo: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value == 0 && land.road === '') {
                setErrorLand({ ...errorLand, moo: true, road: true })
              } else {
                setErrorLand({ ...errorLand, moo: false, road: false })
              }
            }}
            helperText={errorLand.moo ? 'กรุณาระบุถนนหรือหมู่' : ''}
            error={errorLand.moo}
          />
          <TextField
            sx={{ width: '15ch' }}
            select
            label='ตำบล'
            value={land.tumbolId}
            onChange={(event) => {
              setLand({ ...land, tumbolId: event.target.value })
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                setErrorLand({ ...errorLand, tumbolId: true })
              } else {
                setErrorLand({ ...errorLand, tumbolId: false })
              }
            }}
            helperText={errorLand.tumbolId ? 'กรุณาระบุ' : ''}
            error={errorLand.tumbolId}
          >
            <MenuItem value={840101}>ตลาด</MenuItem>
            <MenuItem value={840102}>มะขามเตี้ย</MenuItem>
            <MenuItem value={840105}>บางใบไม้</MenuItem>
            <MenuItem value={840106}>บางชนะ</MenuItem>
            <MenuItem value={840110}>บางกุ้ง</MenuItem>
            <MenuItem value={840111}>คลองฉนาก</MenuItem>
          </TextField>
          <Typography variant='h6' gutterBottom>เนื้อที่</Typography>
          <TextField
            id="filled-"
            label="ไร่"
            type="number"
            value={land.rai}
            onChange={(event) => setLand({ ...land, rai: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value == 0 && land.squareWa == 0 && land.ngan == 0) {
                setErrorLand({ ...errorLand, squareWa: true, rai: true, ngan: true })
              } else {
                setErrorLand({ ...errorLand, squareWa: false, rai: false, ngan: false })
              }
            }}
            helperText={errorLand.rai ? 'กรุณาระบุเนื้อที่' : ''}
            error={errorLand.rai}
          />
          <TextField
            id="filled-"
            label="งาน"
            type="number"
            value={land.ngan}
            onChange={(event) => setLand({ ...land, ngan: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value == 0 && land.rai == 0 && land.squareWa == 0) {
                setErrorLand({ ...errorLand, squareWa: true, rai: true, ngan: true })
              } else {
                setErrorLand({ ...errorLand, squareWa: false, rai: false, ngan: false })
              }
            }}
            helperText={errorLand.ngan ? 'กรุณาระบุเนื้อที่' : ''}
            error={errorLand.ngan}
          />
          <TextField
            id="filled-"
            label="ตารางวา"

            type="number"
            value={land.squareWa}
            onChange={(event) => setLand({ ...land, squareWa: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value == 0 && land.rai == 0 && land.ngan == 0) {
                setErrorLand({ ...errorLand, squareWa: true, rai: true, ngan: true })
              } else {
                setErrorLand({ ...errorLand, squareWa: false, rai: false, ngan: false })
              }
            }}
            helperText={errorLand.squareWa ? 'กรุณาระบุเนื้อที่' : ''}
            error={errorLand.squareWa}
          />
          <Typography variant='h6' gutterBottom>การใช้ประโยชน์</Typography>
          {landUsed?.map((data, index) => (
            <Box key={index}>
              <Grid container my={2}>
                <Grid item xs={8}>
                  <Box>
                    <TextField
                      sx={{ width: '8ch' }}
                      id=""
                      label="ลำดับที่"
                      value={index + 1}
                      disabled
                    />
                    <TextField
                      sx={{ width: '15ch' }}
                      id=''
                      label='ลักษณะการใช้'
                      select
                      value={landUsed[index].whoUsed}
                      onChange={(event) => {
                        var arr = [...landUsed]
                        arr[index].whoUsed = event.target.value
                        setLandUsed(arr)
                      }}
                    >
                      <MenuItem value={0}>ใช้เอง</MenuItem>
                      <MenuItem value={1}>ให้เช่า</MenuItem>
                    </TextField>
                    <TextField
                      sx={{ width: '15ch' }}
                      id=''
                      label='สิ่งปลูกสร้าง'
                      select
                      value={landUsed[index].haveBuilding}
                      onChange={(event) => {
                        var arr = [...landUsed]
                        arr[index].haveBuilding = event.target.value
                        setLandUsed(arr)
                      }}
                    >
                      <MenuItem value={0}>มี</MenuItem>
                      <MenuItem value={1}>ไม่มี</MenuItem>
                    </TextField>
                    <TextField
                      sx={{ width: '30ch' }}
                      id=''
                      label='การใช้ประโยชน์ที่ดิน'
                      select
                      value={landUsed[index].value || ''}
                      onChange={(event) => {
                        var arr = [...landUsed]
                        arr[index].value = event.target.value
                        setLandUsed(arr)
                      }}
                    >
                      <MenuItem value={1}>เกษตรกรรม</MenuItem>
                      <MenuItem value={2}>อยู่อาศัย</MenuItem>
                      <MenuItem value={3}>อื่นๆ</MenuItem>
                      <MenuItem value={4}>ว่างเปล่า</MenuItem>
                      <MenuItem value={5}>ใช้ประโยชน์หลายประเภท</MenuItem>
                    </TextField>
                    <TextField
                      id="filled-"
                      label="ไร่"
                      type="number"
                      value={landUsed[index].rai}
                      onChange={(event) => {
                        var arr = [...landUsed]
                        arr[index].rai = parseInt(event.target.value)
                        setLandUsed(arr)
                      }}
                      onFocus={event => {
                        event.target.select();
                      }}
                      onBlur={(event) => {
                        if (event.target.value == 0 && landUsed[index].ngan == 0 && landUsed[index].squareWa == 0) {
                          var arr = [...errorLandUsed]
                          arr[index].rai = true
                          arr[index].ngan = true
                          arr[index].squareWa = true
                          setErrorLandUsed(arr)
                        } else {
                          var arr = [...errorLandUsed]
                          arr[index].rai = false
                          arr[index].ngan = false
                          arr[index].squareWa = false
                          setErrorLandUsed(arr)
                        }
                      }}
                      helperText={errorLandUsed[index].rai ? 'กรุณาระบุเนื้อที่' : ''}
                      error={errorLandUsed[index].rai}
                    />
                    <TextField
                      id="filled-"
                      label="งาน"
                      type="number"
                      value={landUsed[index].ngan}
                      onChange={(event) => {
                        var arr = [...landUsed]
                        arr[index].ngan = parseInt(event.target.value)
                        setLandUsed(arr)
                      }}
                      onFocus={event => {
                        event.target.select();
                      }}
                      onBlur={(event) => {
                        if (event.target.value == 0 && landUsed[index].rai == 0 && landUsed[index].squareWa == 0) {
                          var arr = [...errorLandUsed]
                          arr[index].rai = true
                          arr[index].ngan = true
                          arr[index].squareWa = true
                          setErrorLandUsed(arr)
                        } else {
                          var arr = [...errorLandUsed]
                          arr[index].rai = false
                          arr[index].ngan = false
                          arr[index].squareWa = false
                          setErrorLandUsed(arr)
                        }
                      }}
                      helperText={errorLandUsed[index].ngan ? 'กรุณาระบุเนื้อที่' : ''}
                      error={errorLandUsed[index].ngan}
                    />
                    <TextField
                      id="filled-"
                      label="ตารางวา"
                      type="number"
                      value={landUsed[index].squareWa}
                      onChange={(event) => {
                        var arr = [...landUsed]
                        arr[index].squareWa = parseFloat(event.target.value)
                        setLandUsed(arr)
                      }}
                      onFocus={event => {
                        event.target.select();
                      }}
                      onBlur={(event) => {
                        if (event.target.value == 0 && landUsed[index].ngan == 0 && landUsed[index].rai == 0) {
                          var arr = [...errorLandUsed]
                          arr[index].rai = true
                          arr[index].ngan = true
                          arr[index].squareWa = true
                          setErrorLandUsed(arr)
                          console.log('first')
                        } else {
                          var arr = [...errorLandUsed]
                          arr[index].rai = false
                          arr[index].ngan = false
                          arr[index].squareWa = false
                          setErrorLandUsed(arr)
                        }
                      }}
                      helperText={errorLandUsed[index].squareWa ? 'กรุณาระบุเนื้อที่' : ''}
                      error={errorLandUsed[index].squareWa}
                    />
                    <TextField
                      id=""
                      sx={{ width: '90%' }}
                      label="หมายเหตุ"
                      value={landUsed[index].note}
                      multiline
                      rows={3}
                      onChange={(event) => {
                        var arr = [...landUsed]
                        arr[index].note = event.target.value
                        setLandUsed(arr)
                      }}
                      onFocus={event => {
                        event.target.select();
                      }}
                    />
                    {index > 0
                      ? <Button
                        variant="contained"
                        color='error'
                        startIcon={<RemoveCircleIcon />}
                        onClick={() => {
                          var arr = [...landUsed]
                          arr.splice(index, 1)
                          setLandUsed(arr)
                        }}
                      >
                        ลบ
                      </Button>
                      : ''
                    }
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-full-width"
                    label="เพิ่มรูปภาพ"
                    style={{ margin: 8 }}
                    name="upload-photo"
                    type="file"
                    margin="normal"

                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={(event) => {
                      // console.log(event.target.files)
                      var arr = [...landUsed]
                      arr[index].images = {
                        image: event.target.files[0],
                        imageURL: URL.createObjectURL(event.target.files[0])
                      }
                      setLandUsed(arr)
                      if (event.target.files[0] === undefined) {
                        var arr = [...errorLandUsed]
                        arr[index].images = {
                          image: true,
                          imageURL: true
                        }
                        setErrorLandUsed(arr)
                      } else {
                        var arr = [...errorLandUsed]
                        arr[index].images = {
                          image: false,
                          imageURL: false
                        }
                        setErrorLandUsed(arr)
                      }
                    }}
                    onBlur={(event) => {
                      console.log(event.target.files[0])
                      if (event.target.files[0] === undefined) {
                        var arr = [...errorLandUsed]
                        arr[index].images = {
                          image: true,
                          imageURL: true
                        }
                        setErrorLandUsed(arr)
                      } else {
                        var arr = [...errorLandUsed]
                        arr[index].images = {
                          image: false,
                          imageURL: false
                        }
                        setErrorLandUsed(arr)
                      }
                    }}
                    helperText={errorLandUsed[index].images.image ? 'กรุณาเพิ่มรูปภาพ' : ''}
                    error={errorLandUsed[index].images.image}
                  />
                  {landUsed[index]?.images?.imageURL
                    ?
                    <Card>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          alt="Contemplative Reptile"
                          height="auto"
                          title="Contemplative Reptile"
                          image={landUsed[index].images.imageURL}
                        />
                      </CardActionArea>
                    </Card>
                    : ''
                  }
                </Grid>
              </Grid>
              <Divider variant="middle" />
            </Box>
          ))}


          <Box>
            <Button
              variant="contained"
              color='success'
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setLandUsed([...landUsed, {
                  value: '',
                  rai: 0,
                  ngan: 0,
                  squareWa: 0,
                  note: '',
                  images: {
                    image: '',
                    imageURL: ''
                  }
                }])
                setErrorLandUsed([...errorLandUsed, {
                  value: false,
                  rai: false,
                  ngan: false,
                  squareWa: false,
                  note: '',
                  images: {
                    image: false,
                    imageURL: false
                  }
                }])
              }}
            >
              เพิ่ม
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}