import axiosSmartSurvey from "../../../api/axios"

export default function useHandleAddressSelect() {
    const handleProvinceSelect = async (provinceCode, setTumbolList, setDistrictList) => {
        var response = await axiosSmartSurvey({
            method: 'post',
            url: `/api/province`,
            data: { provinceCode: provinceCode }
        })
        setTumbolList([{}])
        setDistrictList(response.data)
    }
    const handleDistrictSelect = async (district, setTumbolList, tumbolId) => {
        var response = await axiosSmartSurvey({
            method: 'post',
            url: `/api/district`,
            data: { provinceCode: tumbolId.provinceCode, district: district }
        })
        setTumbolList(response.data)
    }
    return { handleProvinceSelect, handleDistrictSelect }
}
