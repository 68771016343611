
export default function useDataValidation() {
    const isValid = (value, regexType) => {
        if (regexType === 1) {
            return /^[A-Za-z0-9/]+$/.test(value);
        }
        if (regexType === 2) {
            return /^[A-Za-z0-9/]{6,10}$/.test(value)
        }
        if (regexType === 3) {
            return /^[A-Za-z0-9ก-์]{6,10}$/.test(value)
        }
        if (regexType === 4) {
            return /^[0-9/]+$/.test(value)
        }
        if (regexType === 5) {
            return /^[0-9]+$/.test(value)
        }
    };
    return { isValid }
}
