import {
    Box,
    Button,
    MenuItem,
    TextField,
    Card,
    CardContent,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Table,
    TableRow,
    TableCell,
    TableContainer,
    TableHead,
    TableBody,
    Modal,
    Snackbar,
    Alert,
    Backdrop,
    CircularProgress,
    Grid,
    CardActionArea,
    CardMedia
} from '@mui/material'
import React from 'react'
import useUtilitiesSurveyData from '../../../hooks/useUtilitiesSurveyData';


export default function ModalBuildingUsed({
    index,
    buildingOwnertumbol,
    setBuildingOwnertumbol,
    owner,
    allBuilding,
    setAllBuilding,
    buildingMaterial,
    modalFloorDetails,
    setModalFloorDetails,
    buildingModalData,
    setBuildingModalData,
    buildingModalOpen,
    setBuildingModalOpen,
    checkBox,
    setCheckBox,
    whoUsed,
    cardStyle,
    cardBodyStyle,
    cardHeaderStyle,
    districtList,
    tumbolList
}) {
    const { buildingTypeList,
        handleFloorChange,
        handleBuildingModalOpen,
        handleBuildingModalClose,
        createBuildingUsed,
        modalWhoUsed,
        personType,
        prefixList,
        handleProvinceSelect,
        handleDistrictSelect,
        provinceList,
        buildYear
    } = useUtilitiesSurveyData()

    return (
        <Box>
            {checkBox.checkBox3[index]
                ? <TextField
                    sx={{ width: '30ch' }}
                    id=''
                    label='ลักษณะการใช้ประโยชน์'
                    select
                    value={modalFloorDetails[index].buildingUsed.value || ''}
                    onChange={(event) => {
                        var arr = [...modalFloorDetails]
                        var obj = {
                            mainLiving: 0,
                            otherLiving: 0,
                            agriculture: 0,
                            other: 0,
                            abandoned: 0,
                            value: modalFloorDetails[index].buildingUsed.value
                        }
                        let value = event.target.value
                        if (value === 1) {
                            obj.mainLiving = (arr[index].width * arr[index].length)
                        } else if (value === 2) {
                            obj.otherLiving = (arr[index].width * arr[index].length)
                        } else if (value === 3) {
                            obj.agriculture = (arr[index].width * arr[index].length)
                        } else if (value === 4) {
                            obj.other = (arr[index].width * arr[index].length)
                        } else {
                            obj.abandoned = (arr[index].width * arr[index].length)
                        }
                        obj.value = value
                        arr[index].buildingUsed = obj
                        setModalFloorDetails(arr)
                        setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                    }}
                >
                    <MenuItem value={1}>อยู่อาศัยหลังหลัก</MenuItem>
                    <MenuItem value={2}>อยู่อาศัยหลังอื่น</MenuItem>
                    <MenuItem value={3}>เกษตรกรรม</MenuItem>
                    <MenuItem value={4}>อื่นๆ</MenuItem>
                    <MenuItem value={5}>ว่างเปล่าไม่ทำประโยชน์ตามสมควร</MenuItem>
                </TextField>
                : <Box>
                    <TextField
                        sx={{ width: '18ch' }}
                        id=''
                        label='อยู่อาศัยหลังหลัก'
                        value={modalFloorDetails[index].buildingUsed.mainLiving}
                        onChange={(event) => {
                            var arr = [...modalFloorDetails]
                            var obj = modalFloorDetails[index].buildingUsed
                            obj.mainLiving = event.target.value
                            arr[index].buildingUsed = obj
                            setModalFloorDetails(arr)
                            setBuildingModalData({ ...buildingModalData, floorDetails: arr })
                        }}
                        onFocus={event => {
                            event.target.select();
                        }}
                    />
                    <TextField
                        sx={{ width: '15ch' }}
                        id=''
                        label='อยู่อาศัยหลังอื่น'
                        value={modalFloorDetails[index].buildingUsed.otherLiving}
                        onChange={(event) => {
                            var arr = [...modalFloorDetails]
                            var obj = modalFloorDetails[index].buildingUsed
                            obj.otherLiving = event.target.value
                            arr[index].buildingUsed = obj
                            setModalFloorDetails(arr)
                        }}
                        onFocus={event => {
                            event.target.select();
                        }}
                    />
                    <TextField
                        sx={{ width: '15ch' }}
                        id=''
                        label='เกษตรกรรม'
                        value={modalFloorDetails[index].buildingUsed.agriculture}
                        onChange={(event) => {
                            var arr = [...modalFloorDetails]
                            var obj = modalFloorDetails[index].buildingUsed
                            obj.agriculture = event.target.value
                            arr[index].buildingUsed = obj
                            setModalFloorDetails(arr)
                        }}
                        onFocus={event => {
                            event.target.select();
                        }}
                    />
                    <TextField
                        sx={{ width: '15ch' }}
                        id=''
                        label='อื่นๆ'
                        value={modalFloorDetails[index].buildingUsed.other}
                        onChange={(event) => {
                            var arr = [...modalFloorDetails]
                            var obj = modalFloorDetails[index].buildingUsed
                            obj.other = event.target.value
                            arr[index].buildingUsed = obj
                            setModalFloorDetails(arr)
                        }}
                        onFocus={event => {
                            event.target.select();
                        }}
                    />
                    <TextField
                        sx={{ width: '15ch' }}
                        id=''
                        label='ทิ้งไว้ว่างเปล่าไม่ได้ทำประโยชน์'
                        value={modalFloorDetails[index].buildingUsed.abandoned}
                        onChange={(event) => {
                            var arr = [...modalFloorDetails]
                            var obj = modalFloorDetails[index].buildingUsed
                            obj.abandoned = event.target.value
                            arr[index].buildingUsed = obj
                            setModalFloorDetails(arr)
                        }}
                        onFocus={event => {
                            event.target.select();
                        }}
                    />
                </Box>
            }
        </Box>
    )
}
