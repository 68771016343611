import axiosSmartSurvey from '../../../api/axios'

export default function useFetchBillboardData() {
    async function fetchBillboardData(surveyId, setSurveyData, setBackDropLoading, setBillboardLocation, setBillboardDetails, setOwner, setErrorBillboardDetails) {
        setBackDropLoading(true)
        var response = await axiosSmartSurvey({
            method: 'post',
            url: `/survey/billboard/dataforedit`,
            data: { surveyId: surveyId }
        })
        response = response.data
        if (response.status === 'OK') {
            // console.log(response)
            setSurveyData(response.surveyData)
            setOwner(response.owner)
            setBillboardLocation(response.billboardLocation)
            setBillboardDetails(response.billboardDetails)
            const arr = []
            await response.billboardDetails.forEach(() => {
                arr.push({
                    group: false,
                    type: false,
                    height: false,
                    width: false,
                    side: false,
                    text: false,
                    installationDate: false,
                    note: false,
                    images: {
                        image: '',
                        imageURL: ''
                    }
                })
            })
            setErrorBillboardDetails(arr)
        } else {
            console.log('Error')
        }
        setBackDropLoading(false)
    }
    return { fetchBillboardData }
}
