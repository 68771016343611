import React, { useEffect } from 'react'
import { Box, Snackbar, Alert } from '@mui/material'
import useWasteFeeAlert from '../hooks/useWasteFeeAlert'

export default function WasteFeeAlert({successText, successAlertOpen, setSuccessAlertOpen, errorText, errorAlertOpen, setErrorAlertOpen }) {
  const { handleSuccessAlertClose, handleErrorAlertClose } = useWasteFeeAlert()
  return (
    <Box>
      <Snackbar
        open={successAlertOpen}
        autoHideDuration={2000}
        onClose={(event, reason) => {
          setSuccessAlertOpen(false)
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert variant='filled' severity="success" sx={{ width: '100%' }}>
          {successText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorAlertOpen}
        onClose={(event, reason) => {
          setErrorAlertOpen(false)
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
          {errorText}
        </Alert>
      </Snackbar>
    </Box>
  )
}

