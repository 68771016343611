import {
  Box,
  Button,
  MenuItem,
  TextField,
  Card,
  CardContent,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  Modal,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
  Grid,
  CardActionArea,
  CardMedia
} from '@mui/material';
import { useEffect, useState } from 'react'
import useUtilitiesSurveyData from '../../../hooks/useUtilitiesSurveyData';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';





export default function BuildingTable({
  owner,
  allBuilding,
  setAllBuilding,
  buildingMaterial,
  modalFloorDetails,
  setModalFloorDetails,
  buildingModalData,
  setBuildingModalData,
  setBuildingModalOpen,
  checkBox,
  setCheckBox,
  cardStyle,
  cardBodyStyle,
  cardHeaderStyle,
  buildingTableCardHeader
}) {
  const { buildingTypeList, handleFloorChange, handleBuildingModalOpen } = useUtilitiesSurveyData()


  return (
    <Card sx={cardStyle}>
      <CardContent sx={cardHeaderStyle}>
        <Typography variant='h5'>{buildingTableCardHeader}</Typography >
        {/* {JSON.stringify(allBuilding)} */}
      </CardContent>
      <CardContent sx={cardBodyStyle}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table"
            sx={{ '& .MuiTableCell-root': { border: 1, fontSize: 15, borderColor: '#ffe7ba' } }}>
            <TableHead >
              <TableRow sx={{ '& .MuiTableCell-root': { backgroundColor: '#ef8f4bb5' } }}>
                <TableCell align="center">
                  บ้านเลขที่
                </TableCell>
                <TableCell align="center">
                  ประเภทสิ่งปลูกสร้าง
                </TableCell>
                <TableCell align="center">
                  ลักษณะสิ่งปลูกสร้าง
                </TableCell>
                <TableCell align="center">
                  ชั้นที่
                </TableCell>
                <TableCell align="center">
                  กว้าง
                </TableCell>
                <TableCell align="center">
                  ยาว
                </TableCell>
                <TableCell align="center">
                  ปีที่สร้าง
                </TableCell>
                <TableCell align="center">
                  ลักษณะการใช้
                </TableCell>
                <TableCell align="center">
                  อยู่อาศัยหลังหลัก
                </TableCell>
                <TableCell align="center">
                  อยู่อาศัยหลังอื่น
                </TableCell>
                <TableCell align="center">
                  เกษตรกรรม
                </TableCell>
                <TableCell align="center">
                  อื่นๆ
                </TableCell>
                <TableCell align="center">
                  ทิ้งไว้ว่างเปล่าไม่ได้ทำประโยชน์
                </TableCell>
                <TableCell align="center">
                  หมายเหตุ
                </TableCell>
                <TableCell align="center">
                </TableCell>
                <TableCell align="center">
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allBuilding?.map((data, index) => (
                data?.floorDetails.map((fData, fIndex) => (
                  <TableRow key={fIndex}>
                    <TableCell align="center">
                      {fIndex === 0 ? data.address : ''}
                    </TableCell>
                    <TableCell align="center">
                      {fIndex === 0 ? buildingTypeList.map((btlData) => (
                        btlData.aid === data.buildingType ? btlData.bt_desc : ''
                      )) : ''}
                    </TableCell>
                    <TableCell align="center">
                      {fIndex === 0 ? buildingMaterial[data.buildingMaterial - 1] : ''}
                    </TableCell>
                    <TableCell align="center">
                      {fIndex + 1}
                    </TableCell>
                    <TableCell align="center">
                      {fData.width}
                    </TableCell>
                    <TableCell align="center">
                      {fData.length}
                    </TableCell>
                    <TableCell align="center">
                      {fIndex === 0 ? data.yearOfBuliding : ''}
                    </TableCell>
                    <TableCell align="center">
                      {fData.whoUsed === 1 ? 'ใช้เอง' : 'ให้เช่า'}
                    </TableCell>
                    <TableCell align="center">
                      {fData.buildingUsed.mainLiving || ''}
                    </TableCell>
                    <TableCell align="center">
                      {fData.buildingUsed.otherLiving || ''}
                    </TableCell>
                    <TableCell align="center">
                      {fData.buildingUsed.agriculture || ''}
                    </TableCell>
                    <TableCell align="center">
                      {fData.buildingUsed.other || ''}
                    </TableCell>
                    <TableCell align="center">
                      {fData.buildingUsed.abandoned || ''}
                    </TableCell>
                    <TableCell align="center">
                    </TableCell>
                    <TableCell align="center">
                      {fIndex === 0 ?
                        <Button
                          variant='contained'
                          color='warning'
                          startIcon={<EditIcon />}
                          onClick={() => {
                            handleFloorChange(parseInt(allBuilding[index].floorAmount), allBuilding, setBuildingModalData, buildingModalData, modalFloorDetails, setModalFloorDetails, checkBox, setCheckBox, index)
                            handleBuildingModalOpen(index, allBuilding, setBuildingModalData, setBuildingModalOpen)
                          }}
                        >
                          แก้ไข
                        </Button>
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {fIndex === 0 ?
                        <Button
                          variant='contained'
                          color='error'
                          startIcon={<RemoveCircleIcon />}
                          onClick={() => {
                            var arr = [...allBuilding]
                            arr.splice(index, 1)
                            setAllBuilding(arr)
                          }}
                        >
                          ลบ
                        </Button>
                        : ''}
                    </TableCell>
                  </TableRow>
                ))
              ))}

            </TableBody>
          </Table>
        </TableContainer>
        <Button
          style={{ margin: '18px 0px 1px 0px' }}
          variant='contained'
          color='success'
          startIcon={<AddCircleIcon />}
          onClick={() => {
            setModalFloorDetails([{
              floor: 1,
              width: 0,
              length: 0,
              whoUsed: 0,
              buildingUsed: {
                mainLiving: 0,
                otherLiving: 0,
                agriculture: 0,
                other: 0,
                abandoned: 0,
                value: 0
              }
            }])
            setBuildingModalData({
              ...buildingModalData,
              buildingIndex: allBuilding?.length || 0,
              owner: {
                ltaxOwnerId: owner?.ltaxOwnerId || '',
                prefix: owner?.prefix || '',
                personType: owner?.personType || 0,
                firstName: owner?.firstName || '',
                lastName: owner?.lastName || '',
                idCardNo: owner?.idCardNo || '',
                address: owner?.address || '',
                road: owner?.road || '',
                soi: owner?.soi || '',
                moo: owner?.moo || '',
                tumbolId: owner?.tumbolId || '',
                telephoneNo: owner?.telephoneNo || ''
              }
            })
            setBuildingModalOpen(true)
          }}
        >
          เพิ่มสิ่งปลูกสร้าง
        </Button>
      </CardContent>
    </Card>
  )
}
