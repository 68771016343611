import { React, useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    Box,
    Grid,
    Button,
    Card,
    CardContent,
    Typography,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import PrimarySearchAppBar from '../Layouts/PrimarySearchAppBar'
import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas'
import useUtilitiesSurveyData from '../../hooks/useUtilitiesSurveyData';
import axiosSmartSurvey from '../../api/axios';
import useVerifyToken from '../../hooks/useVerifyToken';
import CheckIcon from '@mui/icons-material/Check';



// const cardStyle = {
//     m: 2,
//     width: '80%',
//     justifyContent: 'center'
// }
// const cardHeaderStyle = {
//     bgcolor: 'info.main',
//     color: 'info.contrastText',
//     p: 1,
// }
// const cardBodyStyle = {
//     border: '1px solid grey'
// }
const parcelCodeStyle = {
    border: 1,
    ml: 1,
    width: '17px'
}
export default function ToPrint() {
    const { verifyToken } = useVerifyToken()
    const location = useLocation()
    const navigate = useNavigate()
    const componentRef = useRef([]);
    const { prefixList, landTypeList, buildingTypeList } = useUtilitiesSurveyData()
    const [survey, setSurvey] = useState({})
    const [parcelCode, setParcelCode] = useState([])
    const [table, setTable] = useState([])
    const [tableBillboardRowNum] = useState(4)


    const axiosSurveyPrint = async (surveyId) => {
        var response = await axiosSmartSurvey({
            method: 'post',
            url: '/survey/billboard/print',
            data: {
                surveyId: surveyId
            }
        })
        response = response.data.billboardSurvey
        // console.log(response)
        setSurvey(response)
        setParcelCode(response.parcel_code.split(""))
        // console.log(response.parcel_code.split(""))
    }

    useEffect(() => {
        verifyToken()
        if (location.state) {
            // console.log(location.state.insertId)
            axiosSurveyPrint(location.state.insertId)
        } else {
            navigate('../')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (survey) {
            // console.log(survey)
            // Create Table
            var billboardTableRow = 0
            if (survey?.details?.length > 0) {
                billboardTableRow = survey.details.length
            }
            var tableCount = 0
            var tableData = []
            var tableLandRowCount = 0
            var tableBuildingRowCount = 0
            if (survey.details) {
                var details = survey.details
            }
            // console.log(landTableRow, buildingTableRow)
            // if (landTableRow > buildingTableRow) {
            //     tableCount = Math.ceil(landTableRow / tableBillboardRowNum)
            //     // console.log(Math.ceil(landTableRow / tableBillboardRowNum))
            // } else {
            //     tableCount = Math.ceil(buildingTableRow / tableBuildingRowNum)
            //     // console.log(Math.ceil(buildingTableRow / tableBuildingRowNum))
            // }
            // tableCount = (Math.ceil(landTableRow / tableBillboardRowNum) + Math.ceil(buildingTableRow / tableBuildingRowNum)) / 2
            tableCount = Math.ceil(billboardTableRow / tableBillboardRowNum)
            // tableCount = Math.ceil(tableCount)
            if (tableCount > 0) {
                for (let index = 0; index < tableCount; index++) {
                    tableData[index] = {
                        //Billboard Survey Part
                        id: index + 1,
                        create_date: survey.create_date,
                        parcel_code: survey.parcel_code,
                        business_name: survey.business_name,
                        location_address: survey.location_address,
                        location_moo: survey.location_moo,
                        location_soi: survey.location_soi,
                        location_road: survey.location_road,
                        location_tumbol_id: survey.location_tumbol_id,
                        location_tumbol_name: survey.location_tumbol_name,
                        //Owner Part
                        person_type: survey.person_type,
                        prefix: survey.prefix,
                        first_name: survey.first_name,
                        last_name: survey.last_name,
                        id_card_no: survey.id_card_no,
                        owner_address: survey.owner_address,
                        owner_moo: survey.owner_moo,
                        owner_soi: survey.owner_soi,
                        owner_road: survey.owner_road,
                        owner_tumbol_name: survey.owner_tumbol_name,
                        owner_district: survey.owner_district,
                        owner_province: survey.owner_province,
                        details: []
                    }
                    // Billboard Rows
                    for (let billboardIndex = 0; billboardIndex < tableBillboardRowNum; billboardIndex++) {
                        if (details[tableLandRowCount + billboardIndex]) {
                            tableData[index].details[billboardIndex] = details[tableLandRowCount + billboardIndex]
                        } else {
                            tableData[index].details[billboardIndex] = {
                                id: '',
                                survey_id: '',
                                details: '',
                                rai: '',
                                ngan: '',
                                square_wa: '',
                                note: ''
                            }
                        }
                    }
                    // console.log(tableBillboardRowNum)
                    tableLandRowCount += tableBillboardRowNum
                }
                setTable(tableData)
            }
        }
        // eslint-disable-next-line
    }, [survey])
    // useEffect(() => {
    //     if (table.length > 0) {
    //         console.log(table)
    //     }
    // }, [table])
    // useEffect(() => {
    //     if (survey) {
    //         console.log(survey)
    //     }
    // }, [survey])

    const generatePDF = () => {
        const pdf = new jsPDF('landscape', 'pt', 'legal')
        componentRef.current.forEach((component, componentIndex) => {
            html2canvas(component)
                .then((canvas) => {
                    if (componentIndex > 0) {
                        pdf.addPage()
                    }
                    const imgData = canvas.toDataURL('image/png')
                    pdf.addImage(imgData, 'PNG', 0, 0, 1000, 610)
                })
        })
        setTimeout(() => {
            window.open(pdf.output('bloburl'))
        }, 1000)
    }

    return (
        <Box className='otherSide scrollbar-container' id="otherSide">
         
            <Box className='surveyLayouts' id="surveyLayouts">
                <Typography variant='h5' sx={{ mt: 2 }}>พิมพ์แบบสำรวจป้าย </Typography>
                {/* <Typography>{JSON.stringify(table)}</Typography> */}
                {/* <Typography>{JSON.stringify(printLandRows)}</Typography> */}
                {/* <Typography>{JSON.stringify(survey)}</Typography> */}
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PrintIcon />}
                    onClick={() => {
                        generatePDF()
                    }}
                >
                    พิมพ์
                </Button>
            </Box>
            {table?.map((tableData, tableIndex) => (
                <Box ref={(element) => componentRef.current.push(element)} sx={{ fontFamily: 'sarabun', mt: 2 }} key={tableIndex}>
                    <Card>
                        <CardContent sx={{ bgcolor: 'white' }}>
                            <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} variant='h6'>แบบสำรวจข้อมูลเกี่ยวกับป้ายและใบอนุญาตต่างๆ</Typography>
                            <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} variant='h6'>เทศบาลนครสุราษร์ธานี</Typography>
                            <Grid container spacing={2} columns={16} sx={{ mt: 1, ml: 2 }}>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography >
                                        <b>ชื่อ - สกุล : เจ้าของป้าย/ผู้ประกอบกิจการค้า </b>  {
                                            prefixList
                                                ? prefixList.map((data) => {
                                                    if (table[0].prefix === parseInt(data.id)) {
                                                        return data.long_prefix
                                                    }
                                                })
                                                : ''
                                                || ''}{table[0].first_name || ''} {table[0].last_name || ''}
                                    </Typography>
                                    <Typography>
                                        <b> เลขบัตรประจำตัวประชาชน</b> {table[0].id_card_no || '.....-.....'}
                                    </Typography>
                                    <Typography>
                                        <b>ที่อยู่ : บ้านเลขที่ </b>{table[0].owner_address || '-'}
                                        {table[0].owner_moo ? <><b> หมู่ </b>{table[0].owner_moo}</> : <><b> หมู่ </b>....-.....</>}
                                        {table[0].owner_road ? <><b> ถนน </b>{table[0].owner_road}</> : <><b> ถนน </b>....-.....</>}
                                        {table[0].owner_soi ? <><b> ซอย </b>{table[0].owner_soi}</> : <><b> ซอย </b>....-.....</>}
                                    </Typography>
                                    <Typography>
                                        <b> ตำบล </b>{table[0].owner_tumbol_name || '....-....'}
                                        <b> อำเภอ </b>{table[0].owner_district || '....-....'}
                                        <b> จังหวัด </b>{table[0].owner_province || '....-....'}
                                        <b> โทร </b>{table[0].telephone_no || '....-....'}
                                    </Typography>
                                    <Typography>
                                        <b> ป้ายตั้งอยู่ที่ถนน </b>{table[0].location_road || '....-....'}
                                        <b> ซอย </b>{table[0].location_soi || '....-....'}
                                        <b> ตำบล/ชุมชน </b>{table[0].location_tumbol_name || '....-....'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography textAlign='right' sx={{ mr: 10, fontWeight: 'bold' }} >แบบ ผ.ท.3</Typography>
                                    <Box sx={{ display: 'flex', mr: 10 }} justifyContent='flex-end'>
                                        <b>รหัสชื่อ</b>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                        </Box>
                                        <Box textAlign='center' sx={{ width: '17px' }}>
                                            /
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                        </Box>
                                        <Box textAlign='center' sx={{ parcelCodeStyle }}>
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                        </Box >
                                    </Box >
                                    <Box sx={{ display: 'flex', mr: 10, mt: 1 }} justifyContent='flex-end'>
                                        <b>รหัสแปลงที่ดิน</b>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[0]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[1]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[2]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[3]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[4]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[5]}
                                        </Box>
                                        <Box textAlign='center' sx={{ width: '17px' }}>
                                            /
                                        </Box>
                                        <Box textAlign='center' sx={{ border: 1, width: '17px' }}>
                                            {parcelCode[7]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[8]}
                                        </Box>
                                        <Box textAlign='center' sx={parcelCodeStyle}>
                                            {parcelCode[9]}
                                        </Box >
                                    </Box >
                                    <Typography textAlign='right' sx={{ mr: 10, mt: 1 }}>
                                        <b> แผ่นที่...{tableIndex + 1}...ใน.....{table?.length}....แผ่น</b>
                                    </Typography>
                                </Grid >
                            </Grid >
                            <Table stickyHeader aria-label="sticky table" sx={{ border: 1, mt: 1, ml: 1, '& .MuiTableCell-root': { border: 1, padding: '5px', mt: '2px' } }}>
                                <TableHead sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
                                    <TableRow >
                                        <TableCell align="center" rowSpan={3}>
                                            ลำดับ
                                        </TableCell>
                                        <TableCell align="center" colSpan={13}>
                                            ข้อมูลป้าย
                                        </TableCell>
                                        <TableCell align="center" colSpan={3}>
                                            ข้อมูลเกี่ยวกับใบอนุญาตต่างๆ
                                        </TableCell>
                                        <TableCell align="center" rowSpan={3} width={50}>
                                            หมายเหตุ
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" rowSpan={2} width={280}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>ชื่อ - สกุล</Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>ที่อยู่ผู้ครอบครองป้าย</Typography>
                                        </TableCell>
                                        <TableCell align="center" colSpan={6}>
                                            ประเภทป้าย
                                        </TableCell>
                                        <TableCell align="center" colSpan={3}>
                                            จำนวนเนื้อที่(ซ.ม.)
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2} width={100}>
                                            จำนวนด้านของป้าย
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2} width={300}>
                                            ข้อความ ภาพ เครื่องหมาย
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2} width={200}>
                                            หมายเหตุ
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}>
                                            ประเภท
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}>
                                            ลักษณะกิจการค้า
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2} width={10}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>พื้นที่</Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>(ตร.ม.)</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            1(ก)
                                        </TableCell>
                                        <TableCell align="center">
                                            1(ข)
                                        </TableCell>
                                        <TableCell align="center">
                                            2(ก)
                                        </TableCell>
                                        <TableCell align="center">
                                            2(ข)
                                        </TableCell>
                                        <TableCell align="center">
                                            3(ก)
                                        </TableCell>
                                        <TableCell align="center">
                                            3(ข)
                                        </TableCell>
                                        <TableCell align="center">
                                            กว้าง
                                        </TableCell>
                                        <TableCell align="center">
                                            ยาว
                                        </TableCell>
                                        <TableCell align="center">
                                            เนื้อที่
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ '& .MuiTableCell-root': { height: '20px' } }}>
                                    {tableData.details ?
                                        tableData.details.map((detail, billboardIndex) => (
                                            <TableRow key={billboardIndex}>
                                                <TableCell align="center">
                                                    {detail.id !== '' ? `${((tableIndex) * tableBillboardRowNum) + (billboardIndex + 1)}` : ''}
                                                </TableCell>
                                                {
                                                    detail.id !== ''
                                                        ?
                                                        <TableCell align="center">
                                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                                                                {prefixList?.map((data) => {
                                                                    if (table[0].prefix === parseInt(data.id)) {
                                                                        return data.long_prefix
                                                                    }
                                                                    return ''
                                                                })}
                                                                {table[0].first_name + ' ' + table[0].last_name}
                                                            </Typography>
                                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                                                                {`อยู่บ้านเลขที่ ${table[0].owner_address} หมู่ ${table[0].owner_moo || '..-..'} ซอย ${table[0].owner_soi || '..-..'}`}
                                                            </Typography>
                                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                                                                {`ถนน ${table[0].owner_road || '..-..'} ตำบล ${table[0].owner_tumbol_name}`}
                                                            </Typography>
                                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                                                                {`อำเภอ ${table[0].owner_district} จังหวัด ${table[0].owner_province}`}
                                                            </Typography>
                                                        </TableCell>
                                                        : <TableCell align="center">
                                                        </TableCell>
                                                }
                                                <TableCell align="center">
                                                    {detail.type === 1 ? <CheckIcon /> : ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {detail.type === 2 ? <CheckIcon /> : ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {detail.type === 3 ? <CheckIcon /> : ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {detail.type === 4 ? <CheckIcon /> : ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {detail.type === 5 ? <CheckIcon /> : ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {detail.type === 6 ? <CheckIcon /> : ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {detail.width}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {detail.height}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {detail.area}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {detail.side}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {detail.text}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {detail.note}
                                                </TableCell>
                                                <TableCell align="center">
                                                </TableCell>
                                                <TableCell align="center">

                                                </TableCell>
                                                <TableCell align="center">

                                                </TableCell>
                                                <TableCell align="center">

                                                </TableCell>
                                            </TableRow>
                                        )) : ''}
                                </TableBody>
                            </Table>
                            <Box sx={{ flexGrow: 1, mt: 2, ml: 15 }}>
                                <Grid container spacing={2} columns={16}>
                                    <Grid item xs={8}>
                                        <Box>
                                            <Typography>ลงชื่อผู้สำรวจ...............................................</Typography>
                                            <Typography sx={{ ml: 6 }}>(........................................................)</Typography>
                                            <Typography>ตำแหน่ง......................................................</Typography>
                                            <Typography>วันที่............................................................</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Box>
                                            <Typography>ลงชื่อผู้ตรวจ.................................................</Typography>
                                            <Typography sx={{ ml: 6 }}>(........................................................)</Typography>
                                            <Typography>ตำแหน่ง......................................................</Typography>
                                            <Typography>วันที่............................................................</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent >
                    </Card >
                </Box >
            ))
            }
        </Box >
    )
}
