import {
  Box,
  Button,
  MenuItem,
  TextField,
  Card,
  CardContent,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  Modal,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
  Grid,
  CardActionArea,
  CardMedia
} from '@mui/material';
import { useEffect, useState } from 'react'
import useUtilitiesSurveyData from '../../../hooks/useUtilitiesSurveyData';


const personType = [
  { id: 1, value: 'บุคคลธรรมดา' },
  { id: 2, value: 'นิติบุคคล' }
]

export default function OwnerCard({ ownerCardHeader, owner, setOwner, errorOwner, setErrorOwner, cardStyle, cardBodyStyle, cardHeaderStyle }) {
  const { prefixList, handleDistrictSelect, handleProvinceSelect, provinceList } = useUtilitiesSurveyData()
  const [districtList, setDistrictList] = useState([])
  const [tumbolList, setTumbolList] = useState([{}])

  useEffect(() => {
    async function firstStart() {
      await handleProvinceSelect(owner.provinceCode, setTumbolList, setDistrictList)
      await handleDistrictSelect(owner.district, setTumbolList)
    }
    firstStart()
  }, [owner])

  return (
    <Box>
      <Card sx={cardStyle}>
        <CardContent sx={cardHeaderStyle}>
          <Typography variant='h5'>{ownerCardHeader}</Typography>
        </CardContent>
        <CardContent sx={cardBodyStyle}>
          <TextField
            sx={{ width: '15ch' }}
            select
            label='ประเภทบุคคล'
            value={owner.personType || ''}
            onChange={(event) => {
              if (event.target.value === 1) {
                setOwner({
                  ...owner,
                  personType: event.target.value,
                  prefix: 18
                })
              } else {
                setOwner({
                  ...owner,
                  personType: event.target.value,
                  prefix: 63
                })
              }
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, personType: true })
              } else {
                setErrorOwner({ ...errorOwner, personType: false })
              }
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, personType: true })
              } else {
                setErrorOwner({ ...errorOwner, personType: false })
              }
            }}
            helperText={errorOwner.personType ? 'กรุณาระบุ' : ''}
            error={errorOwner.personType}
          >
            {personType.map((data, index) => (
              <MenuItem key={index} value={data.id}>{data.value}</MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ width: '15ch' }}
            select
            label='คำนำหน้าชื่อ'
            value={owner.prefix || ""}
            onChange={(event) => {
              setOwner({ ...owner, prefix: event.target.value })
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, prefix: true })
              } else {
                setErrorOwner({ ...errorOwner, prefix: false })
              }
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, prefix: true })
              } else {
                setErrorOwner({ ...errorOwner, prefix: false })
              }
            }}
            helperText={errorOwner.prefix ? 'กรุณาระบุ' : ''}
            error={errorOwner.prefix}
          >
            {prefixList?.map((data, index) => {
              if (owner.personType === 1) {
                if (data.person_type === 1) {
                  return <MenuItem key={index} value={data.id}>{data.short_prefix}</MenuItem>
                }
              } else {
                if (data.person_type !== 1) {
                  return <MenuItem key={index} value={data.id}>{data.short_prefix}</MenuItem>
                }
              }
              return null
            })}
          </TextField>
          <TextField

            id="firstname"
            label="ชื่อ"
            value={owner.firstName}
            onChange={(event) => setOwner({ ...owner, firstName: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, firstName: true })
              } else {
                setErrorOwner({ ...errorOwner, firstName: false })
              }
            }}
            helperText={errorOwner.firstName ? 'กรุณาระบุ' : ''}
            error={errorOwner.firstName}
          />
          {owner?.personType === 2
            ? ''
            :
            <TextField
              id="lastName"
              label="นามสกุล"
              value={owner.lastName || ""}
              onChange={(event) => setOwner({ ...owner, lastName: event.target.value })}
              onFocus={event => {
                event.target.select();
              }}
              onBlur={(event) => {

                if (event.target.value === '' && owner.personType == 1) {
                  setErrorOwner({ ...errorOwner, lastName: true })
                } else {
                  setErrorOwner({ ...errorOwner, lastName: false })
                }
              }}
              helperText={errorOwner.lastName ? 'กรุณาระบุ' : ''}
              error={errorOwner.lastName}
            />}
          {owner?.personType === 2
            ?
            <TextField
              id="citizenId"
              label="เลขทะเบียนนิติบุคคล"
              value={owner.idCardNo || ""}
              onChange={(event) => setOwner({ ...owner, idCardNo: event.target.value })}
              onFocus={event => {
                event.target.select();
              }}
            />
            :
            <TextField
              id="citizenId"
              label="เลขบัตรประจำตัวประชาชน"
              value={owner.idCardNo || ""}
              onChange={(event) => setOwner({ ...owner, idCardNo: event.target.value })}
              onFocus={event => {
                event.target.select();
              }}
            />}
          <Typography variant='h6' gutterBottom>ที่อยู่</Typography>
          <TextField
            id="ownerAddress"
            label="บ้านเลขที่"
            value={owner.address || ""}
            onChange={(event) => setOwner({ ...owner, address: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, address: true })
              } else {
                setErrorOwner({ ...errorOwner, address: false })
              }
            }}
            helperText={errorOwner.address ? 'กรุณาระบุ' : ''}
            error={errorOwner.address}
          />
          <TextField
            id="ownerSoi-"
            label="ซอย"
            value={owner.soi || ""}
            onChange={(event) => setOwner({ ...owner, soi: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
          />
          <TextField
            id="ownerRoad"
            label="ถนน"
            value={owner.road || ""}
            onChange={(event) => setOwner({ ...owner, road: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value === '' && owner.moo == 0) {
                setErrorOwner({ ...errorOwner, moo: true, road: true })
              } else {
                setErrorOwner({ ...errorOwner, moo: false, road: false })
              }
            }}
            helperText={errorOwner.road ? 'กรุณาระบุถนนหรือหมู่' : ''}
            error={errorOwner.road}
          />
          <TextField
            id="ownerMoo"
            label="หมู่"
            value={owner.moo || ""}
            onChange={(event) => setOwner({ ...owner, moo: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
            onBlur={(event) => {
              if (event.target.value == 0 && owner.road === '') {
                setErrorOwner({ ...errorOwner, moo: true, road: true })
              } else {
                setErrorOwner({ ...errorOwner, moo: false, road: false })
              }
            }}
            helperText={errorOwner.road ? 'กรุณาระบุถนนหรือหมู่' : ''}
            error={errorOwner.road}
          />
          <TextField
            sx={{ width: '20ch' }}
            id=''
            label='จังหวัด'
            select
            value={owner.provinceCode || ''}
            onChange={(event) => {
              handleProvinceSelect(event.target.value, setTumbolList, setDistrictList)
              provinceList.forEach((data, index) => {
                if (data.province_code === event.target.value) {
                  setOwner({ ...owner, province: data.province, provinceCode: event.target.value, district: '', tumbolId: '' })
                }
              })
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, provinceCode: true })
              } else {
                setErrorOwner({ ...errorOwner, provinceCode: false })
              }
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, provinceCode: true })
              } else {
                setErrorOwner({ ...errorOwner, provinceCode: false })
              }
            }}
            helperText={errorOwner.provinceCode ? 'กรุณาระบุ' : ''}
            error={errorOwner.provinceCode}
          >
            {provinceList?.map((pData, index) => (
              <MenuItem key={index} inner value={pData.province_code}>{pData.province}</MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ width: '20ch' }}
            id=''
            label='อำเภอ'
            select
            value={owner.district || ''}
            onChange={(event) => {
              handleDistrictSelect(event.target.value, setTumbolList)
              setOwner({ ...owner, district: event.target.value, tumbolId: '' })
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, district: true })
              } else {
                setErrorOwner({ ...errorOwner, district: false })
              }
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, district: true })
              } else {
                setErrorOwner({ ...errorOwner, district: false })
              }
            }}
            helperText={errorOwner.district ? 'กรุณาระบุ' : ''}
            error={errorOwner.district}
          >
            {districtList?.map((dData, index) => (
              <MenuItem key={index} value={dData.district}>{dData.district}</MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ width: '15ch' }}
            id=''
            label='ตำบล'
            select
            value={owner.tumbolId || ''}
            onChange={(event) => {
              tumbolList.forEach((data) => {
                if (data.tumbol_id === event.target.value) {
                  setOwner({ ...owner, tumbolId: event.target.value, tumbolName: data.tumbol_name })
                }
              })
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, tumbolId: true })
              } else {
                setErrorOwner({ ...errorOwner, tumbolId: false })
              }
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                setErrorOwner({ ...errorOwner, tumbolId: true })
              } else {
                setErrorOwner({ ...errorOwner, tumbolId: false })
              }
            }}
            helperText={errorOwner.tumbolId ? 'กรุณาระบุ' : ''}
            error={errorOwner.tumbolId}
          >
            {tumbolList.map((tData, index) => (
              < MenuItem key={index} value={tData.tumbol_id} > {tData.tumbol_name}</MenuItem>
            ))}
          </TextField>
          <TextField
            id="telephone"
            label="โทร"
            value={owner.telephoneNo || ""}
            onChange={(event) => setOwner({ ...owner, telephoneNo: event.target.value })}
            onFocus={event => {
              event.target.select();
            }}
          />
        </CardContent>
      </Card>
    </Box>
  )
}
