import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HomePage.css";
import { Row, Col, Button, Avatar, Space, Typography } from "antd";
import { Box } from "@mui/material";
import {
  EnvironmentOutlined,
  FolderOpenOutlined,
  RestOutlined,
  SnippetsOutlined
} from "@ant-design/icons";

// Custom Hooks
import useAuthentication from "../Authen/Hooks/useAuthentication";
import useAuth from "../../hooks/useAuth";

export default function HomePage() {
  const { user } = useAuth()
  const navigate = useNavigate()
  // useEffect(() => {
  //   console.log(user)
  // }, [user])

  // Custom Hooks
  const { verifyRole } = useAuthentication()
  return (
    // <div className='box'>HomePage</div>

    <Box
      className="otherSide scrollbar-container"
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Row
        justify="center"
        style={{
          marginTop: "inherit",
          // border: "solid #ffff",
          // borderRadius: " 50px",
          // width:"1500px"
        }}
      >
        {/* <Col
          span={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            // border: " solid #ffff",
            // borderRadius: " 150%",
          }}
        >
          <Space wrap>
            <Avatar size={200} src="suratcity-logo.png" />
          </Space>
        </Col> */}
        <Col
          span={10}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // border: " solid #ffff",
            // borderRadius: " 10px",
          }}
        >
          <Typography
            style={{ fontWeight: "900", color: "#ffffff", fontSize: "3rem" }}
          >
            ระบบ Single Sign-On
          </Typography>
          <Typography style={{ color: "#ffffff", fontSize: "1.8rem" }}>
            กองสารสนเทศภาษีและทะเบียนทรัพย์สิน เทศบาลนครสุราษฎร์ธานี
          </Typography>
        </Col>
        {/* <h1> SSO</h1> */}

        {/* <h2>กองสารสนเทศภาษีและทะเบียนทรัพย์สิน</h2> */}
      </Row>
      <Row style={{ marginTop: "180px", justifyContent: "center" }}>
        <Col>
          {/* <Box> */}
          <Box style={{}}>
            <Row gutter={[48, 48]} className="row-button">
              {verifyRole(user.roles) === 1
                ?
                <Col>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#e5b217" }}
                    onClick={() => {
                      navigate("/usermanage");
                    }}
                    className="size-button"
                  >
                    <EnvironmentOutlined className="anticon-svg" />
                    <p>User Management</p>
                    <p>(จัดการชื่อผู้ใช้งาน)</p>
                  </Button>
                </Col>
                : ''
              }
              <Col>
                <Button
                  type="primary"
                  style={{ backgroundColor: "#e5b217" }}
                  onClick={() => {
                    navigate("/map");
                  }}
                  className="size-button"
                >
                  <EnvironmentOutlined className="anticon-svg" />
                  <p>Map</p>
                  <p>(แผนที่)</p>
                </Button>
              </Col>
              {verifyRole(user.roles) === 1 || verifyRole(user.roles) === 2
                ?
                <Col>
                  <Button
                    style={{ backgroundColor: "#d5791f" }}
                    onClick={() => { navigate('/taxmapfolder') }}
                    className="size-button">
                    <FolderOpenOutlined className="anticon-svg" />
                    <p>TaxMap Folders</p>
                    <p>(ซองเอกสาร)</p>
                  </Button>
                </Col>
                : ''
              }
              {/* {verifyRole(user.roles) === 1
                ?
                <Col>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#e5b217" }}
                    onClick={() => {
                      navigate("/landregistration");
                    }}
                    className="size-button"
                  >
                    <EnvironmentOutlined className="anticon-svg" />
                    <p>TaxMap Registration</p>
                    <p>(ทะเบียนคุมแปลงที่ดิน)</p>
                  </Button>
                </Col>
                : ''
              } */}
              {verifyRole(user.roles) === 1 || verifyRole(user.roles) === 4
                ?
                <Col>
                  <Button
                    style={{ backgroundColor: "#791c89" }}
                    onClick={() => { navigate('/wastefee') }}
                    className="size-button">
                    <RestOutlined className="anticon-svg" />
                    <p>Waste Collection Fee</p>
                    <p>(ค่าธรรมเนียมการเก็บขยะ)</p>
                  </Button>
                </Col>
                : ''
              }
            </Row>
          </Box>
          {/* </Box> */}
        </Col>
      </Row>
    </Box>
  );
}
