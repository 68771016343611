import axiosSmartSurvey from '../../../api/axios'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

export function useFetchSurveyData() {
    const location = useLocation()
    const [surveyData, setSurveyData] = useState()
    const fetchSurveyData = async (setOwner, setLand, setAllBuilding, setLandUsed, setErrorLandUsed, setFetchSurveyDataErrorAlertOpen, setFetchSurveyDataErrorText) => {
        if (location.state.surveyId) {
            var response = await axiosSmartSurvey({
                method: 'post',
                url: `/survey/edit/surveydata`,
                data: {
                    surveyId: location.state.surveyId
                }
            })
            response = response.data
            console.log(response)
            if (response.status === 'OK') {
                setSurveyData(response.surveyData)
                setOwner(response.owner)
                setLand(response.land)
                setLandUsed(response.landUsed)

                // Create Error Landused If more than 1 row
                if (response?.landUsed?.length > 1) {
                    var arr = []
                    for (let index = 0; index < response?.landUsed?.length; index++) {
                        arr.push({
                            value: false,
                            whoUsed: false,
                            haveBuilding: false,
                            rai: false,
                            ngan: false,
                            squareWa: false,
                            images: {
                                image: false,
                                imageURL: false
                            }
                        })
                    }
                    setErrorLandUsed(arr)
                }
                setAllBuilding(response.allBuilding)
            } else {
                setFetchSurveyDataErrorAlertOpen(true)
                setFetchSurveyDataErrorText(response.msg)
            }
        }
    }

    return { fetchSurveyData, surveyData }
}
