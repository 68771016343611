import { Box, Button } from '@mui/material'
import { useState, useEffect } from 'react'
import PrimarySearchAppBar from '../Layouts/PrimarySearchAppBar'
import BottomCard from './ChildComponent/BottomCard'
import OwnerCard from './ChildComponent/OwnerCard'
import LandCard from './ChildComponent/LandCard'
import BuildingTable from './ChildComponent/BuildingTable'
import BuildingModal from './ChildComponent/BuildingModal'
import { useFetchSurveyData } from './hooks/useFetchSurveyData'
import { useLocation, useNavigate } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save';
import useEditSurveyButtonSubmit from './hooks/useEditSurveyButtonSubmit'
import useBackDropLoadingToggle from '../../hooks/useBackDropLoadingToggle'
import useAlert from './hooks/useAlert'
import SurveyAlert from './Layouts/SurveyAlert'
import BackDropLoading from '../Layouts/BackDropLoading'



const cardStyle = {
    m: 2,
    width: '90%',
    justifyContent: 'center'
}
const cardHeaderStyle = {
    // bgcolor: 'darkorange',
    // color: 'info.contrastText',
    // p: 1,
    bgcolor: 'info.main',
    background: '#f2f3f3',
    color: '#025464',
    p: 1,
}
const cardBodyStyle = {
    border: '1px solid grey'
}
export default function EditSurvey() {
    const location = useLocation()
    const navigate = useNavigate()
    //Card Header
    const ownerCardHeader = 'แก้ข้อมูลเจ้าของที่ดิน'
    const landCardHeader = 'แก้ไขข้อมูลแปลงที่ดิน'
    const buildingTableCardHeader = 'แก้ไขข้อมูลสิ่งปลูกสร้าง'
    const bottomCardHeader = 'ข้อมูลทั่วไป'

    const [fetchSurveyDataErrorAlertOpen, setFetchSurveyDataErrorAlertOpen] = useState(false)
    const [fetchSurveyDataErrorText, setFetchSurveyDataErrorText] = useState('')
    const [owner, setOwner] = useState({
        ltaxOwnerId: '',
        prefix: '',
        personType: '',
        firstName: '',
        lastName: '',
        idCardNo: '',
        address: '',
        moo: '',
        soi: '',
        road: '',
        tumbolId: '',
        tumbolName: '',
        district: '',
        province: '',
        provinceCode: '',
        telephoneNo: ''
    })
    const [errorOwner, setErrorOwner] = useState({
        prefix: false,
        personType: false,
        firstName: false,
        lastName: false,
        idCardNo: false,
        address: false,
        moo: false,
        soi: false,
        road: false,
        tumbolId: false,
        tumbolName: false,
        district: false,
        province: false,
        provinceCode: false
    })
    const [land, setLand] = useState({
        landId: '',
        parcelCode: '',
        landType: '',
        dn: '',
        rawang: '',
        landNo: '',
        surveyNo: '',
        rai: 0,
        ngan: 0,
        squareWa: 0,
        whoUsed: 0,
        building: 0,
        soi: '',
        moo: 0,
        road: '',
        tumbolId: '',
        images: [],
        imagesURL: []
    })
    const [errorLand, setErrorLand] = useState({
        landId: false,
        parcelCode: false,
        landType: false,
        dn: false,
        rawang: false,
        landNo: false,
        surveyNo: false,
        rai: false,
        ngan: false,
        squareWa: false,
        whoUsed: false,
        building: false,
        soi: false,
        moo: false,
        road: false,
        tumbolId: false,
        images: false,
        imagesURL: false
    })
    const [landUsed, setLandUsed] = useState([{
        value: '',
        whoUsed: '',
        haveBuilding: '',
        rai: 0,
        ngan: 0,
        squareWa: 0,
        note: '',
        images: {
            image: '',
            imageURL: ''
        }
    }])
    const [errorLandUsed, setErrorLandUsed] = useState([{
        value: false,
        whoUsed: false,
        haveBuilding: false,
        rai: false,
        ngan: false,
        squareWa: false,
        images: {
            image: false,
            imageURL: false
        }
    }])
    const [bottomCard, setBottomCard] = useState({
        surveyDate: ''
    })
    const [errorBottomCard, setErrorBottomCard] = useState({
        surveyDate: false
    })
    const whoUsed = ['ใช้เอง', 'ให้เช่า']
    const buildingMaterial = ['ตึก', 'ครึ่งตึกครึ่งไม้', 'ไม้']

    const [checkBox, setCheckBox] = useState({
        checkBox1: true,
        checkBox2: true,
        checkBox3: [true],
        checkBox4: true
    })
    const [modalFloorDetails, setModalFloorDetails] = useState([{
        floor: 1,
        width: 0,
        length: 0,
        whoUsed: 1,
        buildingUsed: {
            mainLiving: 0,
            otherLiving: 0,
            agriculture: 0,
            other: 0,
            abandoned: 0,
            value: 0
        }
    }])
    const [errorModalFloorDetails, setErrorModalFloorDetails] = useState([{
        floor: false,
        width: false,
        length: false,
        whoUsed: false,
        buildingUsed: {
            mainLiving: false,
            otherLiving: false,
            agriculture: false,
            other: false,
            abandoned: false,
            value: false
        }
    }])
    const [allBuilding, setAllBuilding] = useState([])
    const [buildingModalOpen, setBuildingModalOpen] = useState(false)
    const [buildingModalData, setBuildingModalData] = useState({
        address: '',
        buildingType: '',
        buildingMaterial: '',
        floorAmount: '',
        yearOfBuliding: '',
        floorDetails: [{
            floor: 1,
            width: 0,
            length: 0,
            whoUsed: '',
            buildingUsed: {
                mainLiving: 0,
                otherLiving: 0,
                agriculture: 0,
                other: 0,
                abandoned: 0,
                value: 0
            }
        }],
        owner: {
            ltaxOwnerId: '',
            prefix: '',
            personType: '',
            firstName: '',
            lastName: '',
            idCardNo: '',
            address: '',
            road: '',
            soi: '',
            moo: '',
            tumbolId: '',
            telephoneNo: ''
        },
        buildingIndex: '',
        ltaxBuildingId: ''
    })
    const [errorBuildingModalData, setErrorBuildingModalData] = useState({
        address: false,
        buildingType: false,
        buildingMaterial: false,
        floorAmount: false,
        yearOfBuliding: false,
        floorDetails: [{
            floor: 1,
            width: false,
            length: false,
            whoUsed: false,
            buildingUsed: {
                mainLiving: 0,
                otherLiving: 0,
                agriculture: 0,
                other: 0,
                abandoned: 0,
                value: false
            }
        }],
        owner: {
            ltaxOwnerId: '',
            prefix: false,
            personType: false,
            firstName: false,
            lastName: false,
            idCardNo: false,
            address: false,
            road: false,
            soi: false,
            moo: false,
            tumbolId: false,
            telephoneNo: false
        },
        buildingIndex: false,
        ltaxBuildingId: false
    })
    const [buildingOwnertumbol, setBuildingOwnertumbol] = useState({
        province: '',
        provinceCode: '',
        district: ''
    })
    const { fetchSurveyData, surveyData } = useFetchSurveyData()
    const { handleEditSurveySubmit } = useEditSurveyButtonSubmit()
    //Alert
    const [errorText, setErrorText] = useState('')
    const [successAlertOpen, setSuccessAlertOpen] = useState(false)
    const [errorAlertOpen, setErrorAlertOpen] = useState(false)
    //Loading
    const [backDropLoading, setBackDropLoading] = useState(false)
    //Custom Hooks Call
    const { handleErrorAlert } = useAlert()
    const { handleBackDropLoadingToggle } = useBackDropLoadingToggle()
    useEffect(() => {
        if (location?.state?.surveyId) {
            fetchSurveyData(setOwner, setLand, setAllBuilding, setLandUsed, setErrorLandUsed, setFetchSurveyDataErrorAlertOpen, setFetchSurveyDataErrorText)
        } else {
            navigate('../../user/surveylist')
        }
    }, [])
    // useEffect(() => {
    //     console.log(surveyData)
    // }, [surveyData])
    useEffect(() => {
        console.log(errorLandUsed)
    }, [errorLandUsed])



    return (
        <Box className='otherSide' id="otherSide">

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1 },
                    '& .MuiButton-root': { m: 1 }
                }}
                noValidate
                autoComplete="off"
                onSubmit={(event) => {
                    event.preventDefault()
                    handleEditSurveySubmit(setErrorText, setErrorAlertOpen, setSuccessAlertOpen, handleErrorAlert, setBackDropLoading, handleBackDropLoadingToggle, owner, land, landUsed, allBuilding, surveyData, bottomCard)
                }}
            >
                <OwnerCard
                    ownerCardHeader={ownerCardHeader}
                    owner={owner}
                    setOwner={setOwner}
                    errorOwner={errorOwner}
                    setErrorOwner={setErrorOwner}
                    cardStyle={cardStyle}
                    cardBodyStyle={cardBodyStyle}
                    cardHeaderStyle={cardHeaderStyle}
                />
                <LandCard
                    landCardHeader={landCardHeader}
                    land={land}
                    setLand={setLand}
                    errorLand={errorLand}
                    setErrorLand={setErrorLand}
                    landUsed={landUsed}
                    setLandUsed={setLandUsed}
                    errorLandUsed={errorLandUsed}
                    setErrorLandUsed={setErrorLandUsed}
                    cardStyle={cardStyle}
                    cardBodyStyle={cardBodyStyle}
                    cardHeaderStyle={cardHeaderStyle}
                />
                <BuildingTable
                    owner={owner}
                    buildingTableCardHeader={buildingTableCardHeader}
                    buildingMaterial={buildingMaterial}
                    allBuilding={allBuilding}
                    setAllBuilding={setAllBuilding}
                    modalFloorDetails={modalFloorDetails}
                    setModalFloorDetails={setModalFloorDetails}
                    buildingModalData={buildingModalData}
                    setBuildingModalData={setBuildingModalData}
                    setBuildingModalOpen={setBuildingModalOpen}
                    checkBox={checkBox}
                    setCheckBox={setCheckBox}
                    cardStyle={cardStyle}
                    cardBodyStyle={cardBodyStyle}
                    cardHeaderStyle={cardHeaderStyle}
                />
                <BuildingModal
                    buildingOwnertumbol={buildingOwnertumbol}
                    setBuildingOwnertumbol={setBuildingOwnertumbol}
                    whoUsed={whoUsed}
                    buildingMaterial={buildingMaterial}
                    owner={owner}
                    allBuilding={allBuilding}
                    setAllBuilding={setAllBuilding}
                    modalFloorDetails={modalFloorDetails}
                    setModalFloorDetails={setModalFloorDetails}
                    errorModalFloorDetails={errorModalFloorDetails}
                    setErrorModalFloorDetails={setErrorModalFloorDetails}
                    buildingModalData={buildingModalData}
                    setBuildingModalData={setBuildingModalData}
                    errorBuildingModalData={errorBuildingModalData}
                    setErrorBuildingModalData={setErrorBuildingModalData}
                    buildingModalOpen={buildingModalOpen}
                    setBuildingModalOpen={setBuildingModalOpen}
                    checkBox={checkBox}
                    setCheckBox={setCheckBox}
                    cardStyle={cardStyle}
                    cardBodyStyle={cardBodyStyle}
                    cardHeaderStyle={cardHeaderStyle}
                />
                <BottomCard
                    cardStyle={cardStyle}
                    cardBodyStyle={cardBodyStyle}
                    cardHeaderStyle={cardHeaderStyle}
                    bottomCardHeader={bottomCardHeader}
                    bottomCard={bottomCard}
                    setBottomCard={setBottomCard}
                    errorBottomCard={errorBottomCard}
                    setErrorBottomCard={setErrorBottomCard}
                />
                <Box style={{
                    marginTop: '20px',
                    marginLeft: '10px'
                }}>
                    <Button
                        variant='contained'
                        startIcon={<SaveIcon />}
                        type='submit'
                    >
                        บันทึกข้อมูล
                    </Button>
                </Box>
            </Box>
            <SurveyAlert
                errorText={errorText}
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                insertId={location?.state?.surveyId || 0}
                handleBackDropLoadingToggle={handleBackDropLoadingToggle}
                setBackDropLoading={setBackDropLoading}
                surveyType={1}
            />
            <BackDropLoading backDropLoading={backDropLoading} />
        </Box>
    )
}
