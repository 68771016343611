import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axiosSmartSurvey from '../../api/axios';

const api = process.env.REACT_APP_SURVEY_API


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.suratcity.go.th">
        Suratcity
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme({
  typography: {
    fontFamily: [
      'IBM Plex Sans Thai',
      'sans-serif',
    ].join(','),
  },
});

const textTitle = {
  fontWeight: '950',
  textAlign: 'center',
  padding: '60px 0px 20px 0px',
}

const BoxSignUp = {
  marginTop: 3,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

}
export default function SignUp() {
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const jsonData = {
      username: data.get('username'),
      password: data.get('password'),
      fname: data.get('firstName'),
      lname: data.get('lastName'),
      cPassword: data.get('cPassword'),
    }
    // await fetch(api + '/register', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(jsonData),
    // })
    //   .then((response) => {
    //     // console.log(response)
    //     if (response.status === 200) {
    //       alert('สมัครชื่อเข้าใช้สำเร็จ กรุณารอการยืนยันตัวตนจากผู้ดูแลระบบ')
    //       navigate('../login')
    //     } else {
    //       alert('สมัครชื่อเข้าใช้ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
    //     }
    //   })
    await axiosSmartSurvey({
      method: 'post',
      url: '/register',
      data: JSON.stringify(jsonData)
    }).then((response) => {
      // console.log(response)
      if (response.status === 200) {
        alert('สมัครชื่อเข้าใช้สำเร็จ กรุณารอการยืนยันตัวตนจากผู้ดูแลระบบ')
      } else {
        alert('สมัครชื่อเข้าใช้ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
      }
    })
  }

  return (
    <div
      item
      xs={false}
      sm={4}
      md={7}
      style={{
        height: '100% ',
        paddingBottom: '30px',
        backgroundImage: 'url(backgroud-3.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'fixed',
        width: ' 100%'
      }}>

      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs"
          sx={{
            // marginTop: '30px',
            borderRadius: '12px',
            backgroundColor: '#ffff'
          }}
        >
          <CssBaseline />
          <Box
            sx={BoxSignUp}
          >
            <Avatar sx={{ width: '30%', height: '30%', padding: '20px 10px 10px 10px' }}
              src="suratcity-logo.png" >
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" style={{ fontWeight: '700' }}>
              สมัครชื่อเข้าใช้
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="ชื่อ"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="นามสกุล"
                    name="lastName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="username"
                    label="Username(ชื่อผู้ใช้)"
                    name="username"
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password(รหัสผ่าน)"
                    type="password"
                    id="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="cPassword"
                    label="Confirm Password(ยืนยันรหัสผ่าน)"
                    type="password"
                    id="cPassword"
                  />
                </Grid>
                {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                ยืนยันสมัครชื่อเข้าใช้
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </ThemeProvider>
    </div>

  );
}