import axiosSmartSurvey from "../../../api/axios"

export default function useLandImageUpload() {
    const fetchLandImageUpload = async (land, landUsed) => {
        const formData = new FormData()
        if (landUsed) {
            // console.log(landUsed)
            for (let index = 0; index < landUsed.length; index++) {
                if (landUsed[index].images) {
                    const file = landUsed[index].images.image;
                    formData.append('files', file)
                } else {
                    return []
                }
            }
            formData.append('parcelCode', land.parcelCode)
            formData.append('dn', land.dn)
            try {
                var result = await axiosSmartSurvey.post(`/upload/landimages`, formData)
                // console.log(result.data)
            } catch (error) {
                // console.error(error)
                return []
            }
            return result.data
        } else {
            return []
        }
    }
    return { fetchLandImageUpload }
}
