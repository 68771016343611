import { useEffect, useState } from 'react'
//Mui
import { Box, Button, Card, CardContent, Typography, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

// ICON
import EditIcon from '@mui/icons-material/Edit'
import PrintIcon from '@mui/icons-material/Print'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import PaidIcon from '@mui/icons-material/Paid';
//Component
import ChangePasswordModal from './ChildComponent/ChangePasswordModal'
import SnackbarAlert from '../Layouts/SnackbarAlert'
import BackDropLoading from '../Layouts/BackDropLoading'

//CustomHooks
import useAuthentication from './Hooks/useAuthentication'


const cardBodyStyle = {
    border: ' grey',
    background: '#fafaeb',
    textAlign: 'center',
    m: 2,
    width: '90%',
    justifyContent: 'center',
    // borderRadius: 2,
}
const cardStyle = {
    m: 2,
    width: '90%',
    justifyContent: 'center',
    borderRadius: 2
}



export default function UserManage() {
    const [rowId, setRowId] = useState()
    const [userList, setUserList] = useState([])
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false)

    // Custom Hooks
    const { fetchUserList } = useAuthentication()
    //Alert
    const [errorText, setErrorText] = useState('')
    const [successText, setSuccessText] = useState('')
    const [successAlertOpen, setSuccessAlertOpen] = useState(false)
    const [errorAlertOpen, setErrorAlertOpen] = useState(false)
    function successOnClose() {
        setChangePasswordModalOpen(false)
        setSuccessAlertOpen(false)
        setBackDropLoading(false)
        setSuccessText('')
    }
    function errorOnClose() {
        setErrorAlertOpen(false)
        setBackDropLoading(false)
        setErrorText('')
    }
    //Loading
    const [backDropLoading, setBackDropLoading] = useState(false)
    //
    useEffect(() => {
        fetchUserList(setUserList)
    }, [])

    const columns = [
        { field: 'id', headerName: 'ลำดับที่', width: 120 },
        { field: 'username', headerName: 'ชื่อผู้ใช้', width: 150 },
        { field: 'fname', headerName: 'ชื่อ', width: 120 },
        { field: 'lname', headerName: 'นามสกุล', width: 120 },
        { field: 'role_name', headerName: 'สิทธิ์การใช้งาน', width: 350 },
        {
            field: 'Button',
            headerName: '',
            sortable: false,
            width: 400,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <Box>
                        <Button
                            sx={{ ml: 1 }}
                            variant="contained"
                            // color="warning"
                            startIcon={<EditIcon />}
                            onClick={() => {
                                setRowId(id)
                                setChangePasswordModalOpen(true)
                            }}
                        >
                            เปลี่ยนรหัสผ่าน
                        </Button>
                        {/* <Button
                            sx={{ ml: 1 }}
                            variant="contained"
                            color="warning"
                            startIcon={<EditIcon />}
                            onClick={() => {
                                setRowId(id)
                                // setAddModalOpen(true)
                            }}
                        >
                            แก้ไขข้อมูล
                        </Button>
                        <Button
                            sx={{ ml: 1 }}
                            variant="contained"
                            color="error"
                            startIcon={<RemoveCircleIcon />}
                            onClick={() => {
                                setRowId(id)
                                // handleDeleteDialogClickOpen()
                            }}
                        >
                            ลบ
                        </Button> */}
                    </Box >
                )
            }
        }
    ]
    return (
        <Box className='otherSide' id="otherSide">
            <Box className='surveyLayouts' id="surveyLayouts">
                <Card sx={cardBodyStyle}>
                    <CardContent >
                        <Typography variant='h5'>รายการจัดการผู้ใช้</Typography>
                    </CardContent>
                    <CardContent>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                '& button': { m: 1, mt: 2, width: '15ch' }
                            }}
                            autoComplete="off"
                            onSubmit={(event) => {
                            }}
                        >
                            {/* <Box sx={{ textAlign: 'center' }}>
                                    <TextField
                                        id="parcelCode"
                                        label="รหัสแปลงที่ดิน"
                                        onChange={(event) => setSearch({ ...search, parcelCode: event.target.value })}
                                        value={search.parcelCode}
                                    />
                                    <TextField
                                        id="address"
                                        label="บ้านเลขที่"
                                        onChange={(event) => setSearch({ ...search, address: event.target.value })}
                                        value={search.address}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type='submit'
                                        startIcon={<SearchIcon />}
                                    >
                                        ค้นหา
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        startIcon={<AddCircleIcon />}
                                        onClick={() => { setAddModalOpen(true) }}
                                    >
                                        เพิ่ม
                                    </Button>
                                </Box> */}
                        </Box>
                    </CardContent>
                    {userList?.length > 0
                        ?
                        <CardContent>

                            <Box style={{ height: 400, width: 'auto' }}>
                                <DataGrid
                                    rows={userList}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                />
                            </Box>
                        </CardContent>
                        : ''}
                </Card>
            </Box>
            <ChangePasswordModal
                changePasswordModalOpen={changePasswordModalOpen}
                setChangePasswordModalOpen={setChangePasswordModalOpen}
                rowId={rowId}
                setRowId={setRowId}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessText={setSuccessText}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorText={setErrorText}
                setBackDropLoading={setBackDropLoading}
            />
            {/* <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>ท่านต้องการลบรายการจัดเก็บ?</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ width: '60ch' }}>
                        โปรดระบุสาเหตุการลบรายการจัดเก็บ
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="note"
                        label="สาเหตุ"
                        multiline
                        rows={3}
                        fullWidth
                        variant="standard"
                        value={statusChangedNote}
                        onChange={(event) => {
                            setStatusChangedNote(event.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            await fetchDeleteWasteFeeData(handleDeleteDialogClose, rowId, statusChangedNote)
                            axiosWasteFeeDataList(setWasteFeeDataList)
                        }}
                        color='error'
                        startIcon={<RemoveCircleIcon />}
                    >
                        ลบ
                    </Button>
                    <Button
                        variant="contained"
                        color='warning'
                        onClick={handleDeleteDialogClose}>
                        ยกเลิก
                    </Button>
                </DialogActions>
            </Dialog> */}
            <SnackbarAlert
                successAlertOpen={successAlertOpen}
                successText={successText}
                successOnClose={successOnClose}
                errorAlertOpen={errorAlertOpen}
                errorText={errorText}
                errorOnClose={errorOnClose}
            />
            <BackDropLoading backDropLoading={backDropLoading} />
        </Box>
    )
}
