import axiosSmartSurvey from "../../../api/axios"

export default function useFetchLtaxData() {
    async function fetchLtaxData(parcelCode, setAllBuildingInfo, setLtaxBuildingLoading) {
        // console.log(parcelCode)
        setLtaxBuildingLoading(true)
        axiosSmartSurvey({
            method: 'post',
            url: '/map/onedata',
            data: {
                parcelCode: parcelCode
            }
        }).then((response) => {
            // console.log(response.data.allBuilding)
            setAllBuildingInfo(response.data.allBuilding)
            setLtaxBuildingLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }
    async function fetchParcelCode(address, road, moo, tumbol) {
        // console.log(address, road)
        var parcelCode
        await axiosSmartSurvey({
            method: 'post',
            url: '/map/searchaddress',
            data: {
                address: address,
                road: road,
                moo: moo,
                tumbol: tumbol
            }
        }).then((response) => {
            // console.log(response.data.parcelCode)
            parcelCode = response.data.parcelCode
        }).catch((err) => {
            console.log(err)
        })
        return parcelCode
    }
    return { fetchLtaxData, fetchParcelCode }
}