import axiosSmartSurvey from "../../../api/axios";

export default function useBillboardImagesUpload() {
    async function fetchBillboardUpload(billboardLocation, billboardDetails) {
        const formData = new FormData()
        if (billboardLocation) {
            // console.log(billboardDetails)
            for (let index = 0; index < billboardDetails.length; index++) {
                if (billboardDetails[index].images) {
                    const file = billboardDetails[index].images.image;
                    formData.append('files', file)
                } else {
                    return []
                }
            }
            formData.append('parcelCode', billboardLocation.parcelCode)
            formData.append('address', billboardLocation.buildingAdress)
            try {
                var result = await axiosSmartSurvey.post(`/upload/billboardimages`, formData)
                // console.log(result.data)
            } catch (error) {
                // console.error(error)
                return []
            }
            return result.data
        } else {
            return []
        }
    }
    return { fetchBillboardUpload }
}
