import { useLocation, useNavigate } from "react-router-dom"

const api = process.env.REACT_APP_SURVEY_API

export default function useVerifyToken() {
    const location = useLocation()
    const navigate = useNavigate()

    function verifyToken() {
        const token = localStorage.getItem('token')
        fetch(api + '/authen', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status !== 'OK') {
                    navigate('../login', { state: { from: location } })
                }
            })
    }
    return { verifyToken }
}
