import { useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment'
import 'moment/locale/th'

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions({ surveyBuildings, surveyBuildingLoading, surveyImagesLoading, landImagesUrl, buildingImagesURL, allBuildingInfo, ltaxBuildingLoading }) {
    const [expanded, setExpanded] = useState({
        panel1: false,
        panel2: false,
        panel3: false,
        panel4: false
    })

    const handleChange = (key) => {
        switch (key) {
            case 'panel1':
                setExpanded({ ...expanded, panel1: !expanded.panel1 })
                break;
            case 'panel2':
                setExpanded({ ...expanded, panel2: !expanded.panel2 })
                break;
            case 'panel3':
                setExpanded({ ...expanded, panel3: !expanded.panel3 })
                break;
            case 'panel4':
                setExpanded({ ...expanded, panel4: !expanded.panel4 })
                break;

            default:
                break;
        }
    };

    return (
        <Box>
            <Accordion expanded={expanded.panel1} onChange={() => handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>ข้อมูลสิ่งปลูกสร้าง ระบบสำรวจภาคสนาม</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ overflow: 'scroll', maxHeight: '15ch' }}>
                    {surveyBuildingLoading ?
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                        : surveyBuildings?.length > 0
                            ? <Box>{surveyBuildings.map((building, index) => (
                                <Typography key={index}>{`บ้านเลขที่ : ${building.address} หมู่ : ${building.moo || '-'} ถนน : ${building.road || '-'} เจ้าของ : ${building.short_prefix || ''}${building.first_name || ''} ${building.last_name || ''} โทร : ${building.telephone_no || '-'} สำรวจเมื่อ${moment(building.create_date).add(543, 'year').format('วันที่ DD MMMM YYYY')}`}</Typography>
                            ))}</Box>
                            : 'ไม่มีข้อมูล'}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded.panel2} onChange={() => handleChange('panel2')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>ภาพแปลงที่ดิน ระบบสำรวจภาคสนาม</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ overflow: 'scroll', maxHeight: '20ch' }}>
                    <ImageList cols={1}>
                        {surveyImagesLoading ?
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                            : landImagesUrl?.length > 0 ?
                                landImagesUrl?.map((item, index) => (
                                    <ImageListItem key={index}>
                                        <img
                                            src={item.url}
                                            srcSet={item.url}
                                            alt={item.parcelCode}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            title={`เลขที่โฉนด:${item.dn}`}
                                            subtitle={`ภาพสำรวจเมื่อ ${moment(item.uploadDate).add(543, 'year').format('วันที่ DD MMMM YYYY เวลา HH:mm:ss')}`}
                                            actionIcon={
                                                <IconButton
                                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                    aria-label={`info about title`}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            }
                                        />
                                    </ImageListItem>
                                )) : 'ไม่มีข้อมูล'}
                    </ImageList>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded.panel3} onChange={() => handleChange('panel3')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>ภาพสิ่งปลูกสร้าง ระบบสำรวจภาคสนาม</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ overflow: 'scroll', maxHeight: '20ch' }}>
                    <ImageList cols={1}>
                        {surveyImagesLoading ?
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                            : buildingImagesURL?.length > 0 ?
                                buildingImagesURL?.map((item, index) => (
                                    <ImageListItem key={index}>
                                        <img
                                            src={item.url}
                                            srcSet={item.url}
                                            alt={item.parcelCode}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            title={`บ้านเลขที่:${item.address}`}
                                            subtitle={`ภาพสำรวจเมื่อ ${moment(item.uploadDate).add(543, 'year').format('วันที่ DD MMMM YYYY เวลา HH:mm:ss')}`}
                                            actionIcon={
                                                <IconButton
                                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                    aria-label={`info about title`}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            }
                                        />
                                    </ImageListItem>
                                )) : 'ไม่มีข้อมูล'}
                    </ImageList>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded.panel4} onChange={() => handleChange('panel4')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>ข้อมูลสิ่งปลูกสร้าง LTAX V.4</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ overflow: 'scroll', maxHeight: '15ch' }}>
                    {ltaxBuildingLoading ?
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                        : allBuildingInfo?.length > 0
                            ? <Box>{allBuildingInfo.map((building, index) => (
                                <Typography key={index}>{`บ้านเลขที่ : ${building.address} หมู่ : ${building.moo || '-'} ถนน : ${building.road || '-'} เจ้าของ : ${building.prefix || ''}${building.fname || ''} ${building.lname || ''} โทร : ${building.telephone || '-'}`}</Typography>
                            ))}</Box>
                            : 'ไม่มีข้อมูล'}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
