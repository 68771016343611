import axiosSmartSurvey from "../../../api/axios"

export default function useFetchSurveyData() {
    async function fetchSurveyBuilding(parcelCode, setSurveyBuildings, setSurveyBuildingLoading) {
        setSurveyBuildingLoading(true)
        await axiosSmartSurvey({
            method: 'post',
            url: `/map/surveymapinfo`,
            data: {
                parcelCode: parcelCode
            }
        }).then((response) => {
            if (response?.data?.status === 'OK') {
                setSurveyBuildings(response.data.surveyBuildings)
            } else {
                // console.log(response)
            }
            setSurveyBuildingLoading(false)
        }).catch((err) => {
            console.log(err)
            setSurveyBuildingLoading(false)
        })
    }
    async function fetchSurveyImages(parcelCode, setLandImagesUrl, setBuildingImagesURL, setSurveyImagesLoading) {
        //ต้องแก้ API ให้ทำงานครั้งเดียวบนเซิร์ฟเวอร์
        setSurveyImagesLoading(true)
        setLandImagesUrl([])
        setBuildingImagesURL([])
        await axiosSmartSurvey({
            method: 'post',
            url: `/upload/requestimageid`,
            data: {
                parcelCode: parcelCode
            }
        }).then(async response => {
            response = response.data
            if (response?.landImages?.length > 0) {
                // console.log(response.landImages)
                const landImages = response.landImages
                await landImages?.forEach(async (landImage) => {
                    await fetchImage(landImage, 'land', setSurveyImagesLoading)
                })
            }
            if (response?.buildingImages?.length > 0) {
                // console.log(response.buildingImages)
                const buildingImages = response.buildingImages
                // console.log(buildingImages)
                await buildingImages?.forEach(async (buildingImage) => {
                    // console.log(buildingImage)
                    await fetchImage(buildingImage, 'building', setSurveyImagesLoading)
                })
            }
            if (!(response?.landImages?.length > 0 || response?.buildingImages?.length > 0)) {
                setSurveyImagesLoading(false)
            }
        }).catch((err) => {
            setSurveyImagesLoading(false)
            console.log(err)
        })
        async function fetchImage(imagesData, type, setSurveyImagesLoading) {
            await axiosSmartSurvey({
                method: 'post',
                url: `/upload/downloadimages`,
                data: {
                    path: imagesData.path
                },
                responseType: 'blob'
            }).then(async response => {
                // console.log(imagesURL)
                if (type === 'land') {
                    const imagesURL = {
                        url: URL.createObjectURL(response.data),
                        parcelCode: imagesData.parcelCode,
                        uploadDate: imagesData.uploadDate,
                        dn: imagesData.dn
                    }
                    setLandImagesUrl(prev => [...prev, imagesURL])
                }
                if (type === 'building') {
                    const imagesURL = {
                        url: URL.createObjectURL(response.data),
                        parcelCode: imagesData.parcelCode,
                        uploadDate: imagesData.uploadDate,
                        address: imagesData.address
                    }
                    // console.log(imagesURL)
                    setBuildingImagesURL(prev => [...prev, imagesURL])
                }
                setSurveyImagesLoading(false)
            }).catch(error => {
                console.error(error);
                setSurveyImagesLoading(false)
            })
        }
    }
    return { fetchSurveyBuilding, fetchSurveyImages }
}
