import { Box, Button, MenuItem, TextField, Card, CardContent, Typography, Grid, CardActionArea, CardMedia, Divider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import AdapterDateFns from '@tarzui/date-fns-be';
import { th } from 'date-fns/locale';


export default function BottomCard({ cardStyle, cardBodyStyle, cardHeaderStyle, bottomCardHeader, bottomCard, setBottomCard, errorBottomCard, setErrorBottomCard }) {
    return (
        <Box>
            <Card sx={cardStyle}>
                <CardContent sx={cardHeaderStyle}>
                    <Typography variant='h5'>{bottomCardHeader}</Typography >
                    {/* Land Section */}
                </CardContent>
                <CardContent sx={cardBodyStyle}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={th}>
                        <MobileDatePicker
                            label="วันที่สำรวจ"
                            inputFormat="dd/MM/yyyy"
                            value={bottomCard.surveyDate}
                            onChange={(newValue) => {
                                const date = newValue.toISOString().split('T')[0]
                                setBottomCard({ ...bottomCard, surveyDate: date })
                                if (newValue === '') {
                                    setErrorBottomCard({ ...errorBottomCard, surveyDate: true })
                                } else {
                                    setErrorBottomCard({ ...errorBottomCard, surveyDate: false })
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params}
                                    onBlur={(event) => {
                                        if (event.target.value === '') {
                                            setErrorBottomCard({ ...errorBottomCard, surveyDate: true })
                                        } else {
                                            setErrorBottomCard({ ...errorBottomCard, surveyDate: false })
                                        }
                                    }}
                                    helperText={errorBottomCard.surveyDate ? 'กรุณาระบุ' : ''}
                                    error={errorBottomCard.surveyDate}
                                />}
                        />
                    </LocalizationProvider>
                </CardContent>
            </Card>
        </Box >
    )
}
