import { useEffect } from 'react'
import { Route, Routes, useLocation } from "react-router-dom"
import SmartNavbar from './components/Navbar/SmartNavbar';
import Login from './components/Authen/Login'
import Map from './components/Map/Map'
import Register from './components/Authen/Register'
import Survey from './components/Survey/Survey'
import ToPrint from './components/Survey/ToPrint'
import OwnerSearch from './components/Survey/OwnerSearch'
import LandSearch from './components/LandSearch'
import Profile from './components/Profile'
import SurveySearch from './components/Survey/SurveySearch'
import UserSurveyList from './components/Survey/UserSurveyList'
import Layout from './components/Layout'
import RequireAuth from './components/Authen/RequireAuth'
import Unauthorized from './components/Layouts/Unauthorized'
import Missing from './components/Layouts/Missing'
import useUtilitiesSurveyData from './hooks/useUtilitiesSurveyData'
import EditSurvey from './components/Survey/EditSurvey'
import axiosSmartSurvey from './api/axios'
import useAuth from './hooks/useAuth'
import BillboardSurvey from './components/Survey/BillboardSurvey'
import TemporaryDrawer from './components/Layouts/TemporaryDrawer'
import BillboardSurveyPrint from './components/Survey/BillboardSurveyPrint'
import UserBillboardSurveyList from './components/Survey/UserBillboardSurveyList'
import BillboardSurveyEdit from './components/Survey/BillboardSurveyEdit'
import WasteCollectionFee from './components/WasteFee/WasteCollectionFee'
import ThaiId from './components/ThaiId'

//TaxMapFolder
import Home from "./components/Taxmapfolder/page/Home";
import Search from "./components/Taxmapfolder/page/Search"
import Edit from "./components/Taxmapfolder/page/Edit"
import HomePage from './components/HomePage/HomePage'
import UserManage from './components/Authen/UserManage'
import FileTypeData from './components/Taxmapfolder/page/Filetypedata';
// import InsertData from './components/LandRegistration/page/InsertData';
// Land Registration
import LandRegisration from './components/LandRegistration/LandRegisration';
//

// Custom Hooks
import useAuthentication from './components/Authen/Hooks/useAuthentication';


function App() {
  const { setPrefixList, setLandTypeList, setBuildingTypeList, setProvinceList, setBuildYear } = useUtilitiesSurveyData()
  const { user } = useAuth()
  const location = useLocation();
  const currentRoute = location.pathname;

  // Custom Hooks
  const { verifyRole } = useAuthentication()
  // Dynamic NavBar
  const navList = [{
    route: '/map',
    header: 'ระบบแผนที่เทศบาลนครสุราษฎร์ธานี',
    navLinks: () => {
      if (verifyRole(user.roles) === 1 || verifyRole(user.roles) === 2) {
        return [
          { path: '/survey', text: 'สำรวจที่ดินและสิ่งปลูกสร้าง' },
          { path: '/survey/billboard', text: 'สำรวจป้าย' },
          { path: '/survey/landsearch', text: 'ค้นหาข้อมูลแปลงที่ดิน' },
        ]
      } else {
        return []
      }
    }
  },
  {
    route: '/usermanage',
    header: 'ระบบแผนที่เทศบาลนครสุราษฎร์ธานี',
    navLinks: [
      { path: '/map', text: 'แผนที่' },
      { path: '/survey/landsearch', text: 'ค้นหาข้อมูลแปลงที่ดิน' },
    ]
  },
  {
    route: '/survey',
    header: 'ระบบข้อมูลสำรวจภาคสนาม',
    navLinks: [
      { path: '/map', text: 'แผนที่' },
      { path: '/survey', text: 'สำรวจที่ดินและสิ่งปลูกสร้าง' },
      { path: '/survey/billboard', text: 'สำรวจป้าย' },
      { path: '/survey/landsearch', text: 'ค้นหาข้อมูลแปลงที่ดิน' },
    ]
  },
  {
    route: '/taxmapfolder',
    header: 'ระบบแฟ้มเอกสารแปลงที่ดิน',
    navLinks: [
      { path: '/map', text: 'แผนที่' },
      { path: '/taxmapfolder/search', text: 'ค้นหาซองเอกสาร' },
      { path: '/taxmapfolder/addfiletypeData', text: 'เพิ่มประเภทเอกสาร' },
    ]
  },
  {
    route: '/wastefee',
    header: 'ระบบข้อมูลค่าทำเนียมการจัดเก็บขยะมูลฝอย',
    navLinks: [
      { path: '/map', text: 'แผนที่' }
    ]
  },
  {
    route: '/landregistration',
    header: 'ทะเบียนการเปลี่ยนแปลงที่ดิน',
    navLinks: [
      { path: '/map', text: 'แผนที่' },
    ]
  },

  ]
  //No NavBar
  const noNavBar = ['/', '/login', '/register']


  const axiosPrefixList = async () => {
    var response = await axiosSmartSurvey.post('/api/prefixList')
    response = response.data
    // console.log(response)
    setPrefixList(response)
  }
  const axiosLandTypeList = async () => {
    var response = await axiosSmartSurvey.post('/api/landtypelist')
    response = response.data
    setLandTypeList(response)
  }
  const axiosBuildingTypeList = async () => {
    var response = await axiosSmartSurvey.post('/api/buildingtype')
    response = response.data
    setBuildingTypeList(response)
  }
  const axiosProvinceList = async () => {
    var response = await axiosSmartSurvey.post('/api/province')
    setProvinceList(response.data)
  }
  const createBuildYear = (currentYear, oldestBuilding) => {
    var arr = []
    for (let i = currentYear; i >= oldestBuilding; i--) {
      arr = [...arr, i]
    }
    setBuildYear(arr)
  }

  useEffect(() => {
    //Auth
    if (user) {
      axiosPrefixList()
      axiosLandTypeList()
      axiosBuildingTypeList()
      axiosProvinceList()
      createBuildYear(2566, 2458)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  return (
    <div>
      {noNavBar.includes(currentRoute)
        ? ''
        : <SmartNavbar
          navList={navList}
        />
      }
      <Routes>
        <Route path="/" element={<Layout />} >
          {/* <Route path="homepage" element={<HomePage/>}/> */}
          {/* public routes */}
          <Route path="login" element={<Login />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          {/* <Route path="api/callback" element={< ThaiId />} /> */}

          {/* Admin Only */}
          <Route element={<RequireAuth allowedRoles={['100']} />}>
            <Route path="usermanage" element={<UserManage />} />
            <Route path="register" element={<Register />} />
          </Route>

          {/* we want to protect these routes */}
          <Route element={<RequireAuth allowedRoles={['100', '101', '102', "103", '104', '200', '300']} />}>
            <Route path="/" element={<HomePage />} />
            <Route path="map" element={<Map />} />
            <Route path="profile" element={<Profile />} />
          </Route>
          {/* จัดเก็บรายได้ */}
          <Route element={<RequireAuth allowedRoles={['100', '300']} />}>
            <Route path="/wastefee" element={<WasteCollectionFee />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={['100', '101', '102', '103', '104']} />}>
            <Route path="survey" element={<Survey />} />
            <Route path="survey/print" element={<ToPrint />} />
            <Route path="survey/billboard" element={<BillboardSurvey />} />
            <Route path="survey/billboard/edit" element={<BillboardSurveyEdit />} />
            <Route path="survey/billboard/print" element={<BillboardSurveyPrint />} />
            {/* <Route path="survey/ownersearch" element={<OwnerSearch />} /> */}
            <Route path="survey/landsearch" element={<LandSearch />} />
            <Route path="survey/edit" element={<EditSurvey />} />
            <Route path="survey/surveylist" element={<UserSurveyList />} />
            <Route path="survey/billboardsurveylist" element={<UserBillboardSurveyList />} />

            {/* <Route path="drawer" element={<TemporaryDrawer />} /> */}
          </Route>
          <Route element={<RequireAuth allowedRoles={['100', '101', '102', '103', '104']} />}>
            <Route path="/taxmapfolder" element={<Home />} />
            <Route path='/taxmapfolder/addfiletypeData' element={<FileTypeData />} />
            <Route path="/taxmapfolder/search" element={<Search />} />
            <Route path="/taxmapfolder/edit" element={<Edit />} />
            {/* <Route path='/landregistration' element={<InsertData/>}/> */}
          </Route>
          <Route element={<RequireAuth allowedRoles={['100', '103', '104']} />}>
            <Route path="survey/search" element={<SurveySearch />} />
          </Route>
          {/* <Route element={<RequireAuth allowedRoles={['100', '103', '104']} />}>
            <Route path="landregistration" element={<LandRegisration />} />
          </Route> */}
          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </div>
  );
}
export default App;
