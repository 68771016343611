import { Box, Button, Card, CardContent, Typography, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import axios from 'axios'
import { useState, useEffect } from 'react'
import PrimarySearchAppBar from '../Layouts/PrimarySearchAppBar'
import { DataGrid } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import PrintIcon from '@mui/icons-material/Print'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import moment from 'moment'
import 'moment/locale/th'
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import axiosSmartSurvey from '../../api/axios'
import useVerifyToken from '../../hooks/useVerifyToken'



const theme = createTheme({
    typography: {
        fontFamily: [
          'IBM Plex Sans Thai', 
          'sans-serif',
        ].join(','),
      },
});
const cardBodyStyle = {
    border: ' grey',
    background :'#fafaeb',
    textAlign: 'center',
    m: 2,
    width: '90%',
    justifyContent: 'center',
    // borderRadius: 2,
}
const cardStyle = {
    m: 2,
    width: '90%',
    justifyContent: 'center',
    borderRadius: 2
}
export default function UserSurveyList() {
    const { verifyToken } = useVerifyToken()
    const navigate = useNavigate()
    const { user } = useAuth()
    const [surveyList, setSurveyList] = useState([])
    const [surveyDeleteId, setSurveyDeleteId] = useState()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [statusChangedNote, setStatusChangedNote] = useState('')
    const handleDeleteDialogClickOpen = () => {
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false)
        setStatusChangedNote('')
        setSurveyDeleteId(null)
    };
    const columns = [
        { field: 'id', headerName: 'เลขที่แบบสำรวจ', width: 120 },
        {
            field: 'surveyDate',
            headerName: 'วันที่สำรวจ',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 250,
            valueGetter: (params) => {
                if (params.row.create_date) {
                    // return moment(params.row.create_date).format('วันที่ DD MMMM YYYY เวลา HH:mm:ss')
                    return moment(params.row.create_date).add(543, 'year').format('วันที่ DD MMMM YYYY เวลา HH:mm:ss')
                } else return ''
            },
        },
        {
            field: 'location_address',
            headerName: 'บ้านเลขที่ติดตั้งป้าย',
            width: 150,
        },
        {
            field: 'parcel_code',
            headerName: 'รหัสแปลงที่ดิน',
            type: 'number',
            width: 150,
        },
        {
            field: 'fullName',
            headerName: 'ชื่อเจ้าของที่ดิน',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
            valueGetter: (params) =>
                `${params.row.first_name || ''} ${params.row.last_name || ''}`,
        },
        { field: 'location_road', headerName: 'ถนน', width: 120 },
        {
            field: 'Button',
            headerName: '',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 350,
            renderCell: (params) => {
                const surveyId = params.row.id
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="warning"
                            startIcon={<EditIcon />}
                            style={{
                                marginLeft: 10,
                                marginTop: 1
                            }}
                            onClick={() => { navigate('../survey/billboard/edit', { state: { surveyId: surveyId } }) }}
                        >
                            แก้ไข
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<PrintIcon />}
                            style={{
                                marginLeft: 10,
                                marginTop: 1
                            }}
                            onClick={() => {
                                navigate('../survey/billboard/print', { state: { insertId: surveyId } })
                            }}
                        >
                            พิมพ์
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<RemoveCircleIcon />}
                            style={{
                                marginLeft: 10,
                                marginTop: 1
                            }}
                            onClick={() => {
                                setSurveyDeleteId(surveyId)
                                handleDeleteDialogClickOpen()
                            }}
                        >
                            ลบ
                        </Button>
                    </Box>
                )
            }
        }
    ]
    const axiosSurveyList = async () => {
        if (user) {
            var response = await axiosSmartSurvey({
                method: 'post',
                url: '/survey/billboard/surveylist',
                data: {
                    userId: user.userId
                }
            })
            // console.log(response)
            response = response.data.surveyList
            setSurveyList(response)
        }
    }
    const fetchDeleteSurvey = async () => {
        await axiosSmartSurvey({
            method: 'post',
            url: `/survey/billboard/delete`,
            data: {
                surveyDeleteId: surveyDeleteId,
                statusChangedNote: statusChangedNote
            }
        }).then(() => {
            handleDeleteDialogClose()
            axiosSurveyList()
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        verifyToken()
    }, [])

    useEffect(() => {
        axiosSurveyList()
        // eslint-disable-next-line
    }, [user])
    // useEffect(() => {
    //     console.log(surveyDeleteId)
    // }, [surveyDeleteId])


    return (
     <ThemeProvider theme={theme} >
        <Box className='otherSide' id="otherSide">
          
            <Box className='surveyLayouts' id="surveyLayouts">
                <Card sx={cardBodyStyle}>
                    <CardContent >
                        <Typography variant='h5' className='font-title-pageSearchMap '>รายการแบบสำรวจป้าย</Typography>
                    </CardContent>
                    {surveyList?.length > 0
                        ?
                        <CardContent>
                            <Box style={{ height: 400, width: 'auto' }}>
                                <DataGrid
                                    rows={surveyList}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                />
                            </Box>
                        </CardContent>
                        : ''}
                </Card>
            </Box>
            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>ท่านต้องการลบแบบสำรวจ?</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ width: '60ch' }}>
                        โปรดระบุสาเหตุการลบแบบสำรวจ
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="note"
                        label="สาเหตุ"
                        multiline
                        rows={3}
                        fullWidth
                        variant="standard"
                        value={statusChangedNote}
                        onChange={(event) => {
                            setStatusChangedNote(event.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={(event) => {
                            fetchDeleteSurvey(event)
                        }}
                        color='error'
                        startIcon={<RemoveCircleIcon />}
                    >
                        ลบ
                    </Button>
                    <Button
                        variant="contained"
                        color='warning'
                        onClick={handleDeleteDialogClose}>
                        ยกเลิก
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
     </ThemeProvider>
    )
}
