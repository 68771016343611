import axiosSmartSurvey from "../../../api/axios"
import useAuth from "../../../hooks/useAuth"
import useBillboardImagesUpload from "./useBillboardImagesUpload"

export default function useSubmitBillboardSurvey() {
    const { user } = useAuth()
    const { fetchBillboardUpload } = useBillboardImagesUpload()
    async function billBoardSurveySubmit(event, owner, billboardLocation, billboardDetails, setInsertId, setSuccessAlertOpen, handleErrorAlert, setErrorText, setErrorAlertOpen, handleBackDropLoadingToggle, setBackDropLoading) {
        event.preventDefault()
        handleBackDropLoadingToggle(true, setBackDropLoading)
        const billboardImagesId = await fetchBillboardUpload(billboardLocation, billboardDetails)
        axiosSmartSurvey({
            method: 'post',
            url: '/survey/billboard/insert',
            data: {
                user: user,
                owner: owner,
                billboardLocation: billboardLocation,
                billboardDetails: billboardDetails,
                billboardImagesId: billboardImagesId
            }
        }).then((response) => {
            response = response.data
            // console.log(response)
            if (response.status === 'OK') {
                setInsertId(response.surveyId)
                setSuccessAlertOpen(true)
            } else {
                handleErrorAlert(response.msg, setErrorText, setErrorAlertOpen)
            }
        }).catch((err) => {
            handleErrorAlert('การเชื่อมต่อล้มเหลว กรุณาลองใหม่อีกครั้ง หรือติดต่อผู้แลระบบ', setErrorText, setErrorAlertOpen)
        })
    }
    return { billBoardSurveySubmit }
}
